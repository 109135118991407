import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useLoader } from '@surinderlohat/react-hooks';
import { MsalProvider, useMsal, useIsAuthenticated } from '@azure/msal-react';
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from '@azure/msal-browser';
import process from 'process';
import { useHistory } from 'react-router-dom';
import { Modal } from 'antd';
import { OverlayLoader } from '../components';

type Props = {
  msalConfig: any;
  children: React.ReactElement;
};

const scopes = [ 'openid', 'profile', 'offline_access', process.env.REACT_APP_B2C_API_SCOPE ];

const MsalLocalContext = React.createContext({ accessToken: '', forceRefreshToken: () => null });

const MsalContextProvider: FC<Props> = ({ msalConfig, children }) => {
  const loader = useLoader(false);
  const stateParsingLoader = useLoader(true);

  const [ accessToken, setAccessToken ] = useState('');
  const history = useHistory();

  // check if last session available
  const userFlowAuthority = sessionStorage.getItem('userFlowAuthority') || msalConfig.auth.authority;
  const msalInstance = new PublicClientApplication({
    ...msalConfig,
    auth: {
      ...msalConfig.auth,
      authority: userFlowAuthority,
      knownAuthorities: [ userFlowAuthority ],
    },
  });

  useEffect(() => {
    msalInstance.handleRedirectPromise().then(handlePayload).catch(handleError);
  }, []);
  
  const handlePayload = (payload) => {
    stateParsingLoader.hideLoader();
    if (payload?.account) {
      msalInstance.setActiveAccount(payload.account);
      getAccessToken();
      if (payload.account.idTokenClaims.extension_UserTypeId === 1) {
        if (!payload.account.idTokenClaims.extension_IsPhoneNumberVerified) {
          history.replace('/auth/verify-phone');
          return;
        }
      }
  
      if (payload.account.idTokenClaims.extension_IsSignUpComplete) {
        const hasExtendJobs = localStorage.getItem('redirect-url');
        if (hasExtendJobs) {
          history.replace(hasExtendJobs);
          localStorage.removeItem('redirect-url');
          return;
        }
        history.replace('/a/matches');
        return;
      }
      history.replace('/complete-signup');
    }
  };
  const handleError = (error) => {
    console.log('login-error', error);
  };

  useEffect(() => {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length) {
      msalInstance.setActiveAccount(accounts[0]);
      stateParsingLoader.hideLoader();
      getAccessToken();
    }

    msalInstance.addEventCallback( (event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_FAILURE) {
        stateParsingLoader.hideLoader();
      }
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        // if user reset his password
        if ((event.payload as any).idTokenClaims?.isForgotPassword) {
          sessionStorage.clear();
          localStorage.clear();
          Modal.confirm({
            title: 'Password Updated!',
            content: 'Now you can login using your new password!',
            okText: 'OK',
            cancelText: '',
          });
          return;
        }
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
        stateParsingLoader.hideLoader();
        getAccessToken();
      }
    });
  }, []);

  // Setup Access token in context for later use
  const getAccessToken = async (forceRefresh?: boolean) => {
    const accounts = msalInstance.getAllAccounts();
    if (!accounts.length) {
      return;
    }

    try {
      const request = {
        account: msalInstance.getActiveAccount(),
        scopes,
        forceRefresh,
      };
      // Retrieve an access token from azure b2c
      loader.showLoader();
      const response = await msalInstance.acquireTokenSilent(request);
      if (response.accessToken) {
        setAccessToken(response.accessToken);
        loader.hideLoader();
      }
    } catch (error) {
      loader.hideLoader();
    }
  };

  const contextValue = useMemo(() => {
    return {
      accessToken,
      forceRefreshToken: () => getAccessToken(true),
    };
  }, [ accessToken, getAccessToken ]);
 
  return (
    <MsalLocalContext.Provider value={contextValue}>
      <MsalProvider instance={msalInstance}>
        <OverlayLoader
          isLoading={loader.isLoading || stateParsingLoader.isLoading}
          showChildren={!(loader.isLoading || stateParsingLoader.isLoading)}
        >
          {children}
        </OverlayLoader>
      </MsalProvider>
    </MsalLocalContext.Provider>
  );
};

// Local App context
const useAccessToken = () => useContext(MsalLocalContext).accessToken;

// Dynamic Policy Url for user Auth Flow
const userFlowUrl = isCandidate =>
  `${process.env.REACT_APP_B2C_USER_FLOW_URL}/${
    isCandidate ? process.env.REACT_APP_B2C_CANDIDATE_FLOW : process.env.REACT_APP_B2C_EMPLOYER_FLOW
  }`;

// B2C Client instance
const useB2CClient = () => {
  const { instance, inProgress } = useMsal();
  const msalLocalContext = useContext(MsalLocalContext);
  const isAuthorized = useIsAuthenticated();
  return {
    inProgress,
    // getUserProfile: () => instance.getActiveAccount()?.idTokenClaims || {},
    isAuthorized: isAuthorized && Boolean(msalLocalContext?.accessToken),
    // isSignupCompleted: () =>
    //   instance.getActiveAccount()?.idTokenClaims?.extension_IsSignUpComplete ||
    //   Utilities.isEqual(sessionStorage.getItem('is-xazty'), 'true') ||
    //   false,
    // isPhoneNumberVerified: () => instance.getActiveAccount()?.idTokenClaims?.extension_IsPhoneNumberVerified,
    // setIsSignupCompleted: () => sessionStorage.setItem('is-xazty', 'true'),
    client: instance,
    logout: async () => {
      sessionStorage.clear();
      localStorage.clear();
      await instance.logout({ postLogoutRedirectUri: 'https://jobcommander.com' });
    },
    // Login or Signup with Candidate Or Employer user Account
    redirectForLoginSignup: (
      isCandidate?: boolean,
      params?: { redirectUri?: string; promoCode?: string; prompt?: 'none' | 'login'; loginHint?: string }
    ) => {
      sessionStorage.clear();
      const authority = userFlowUrl(isCandidate);
      sessionStorage.setItem('userFlowAuthority', authority);
      instance.loginRedirect({
        ...params,
        scopes,
        authority,
        redirectUri: params?.redirectUri || window.origin,
        state: `UserType=${isCandidate ? 'Candidate' : 'Employer'}|PromoCode=${params?.promoCode}`,
      });
    },
  };
};

export { useB2CClient, useAccessToken, MsalContextProvider };
