import { SelectOptionModel, CoreModel, ISelectOption } from '@job-commander/shared';
import { APIIndustry } from 'src/interfaces';

export class IndustryModel extends CoreModel implements ISelectOption {
  children: SelectOptionModel[] = [];

  constructor(data?: IndustryModel) {
    super(data);
    Object.assign(this, data);
  }

  /**
   * MAP DATA FROM API to UI MODEL
   */
  public static deserialize(data: APIIndustry) {
    if (!data) {
      return new IndustryModel();
    }

    return new IndustryModel({
      ...(data as any),
      children: data?.industryJobs?.map(x => new SelectOptionModel(x)) || [],
    });
  }

  /**
   * MAP LIST DATA FROM API to UI MODEL
   */
  public static deserializeList(data: APIIndustry[]) {
    if (!data || !Array.isArray(data)) {
      return [];
    }
    return data.map(x => IndustryModel.deserialize(x));
  }

  public get value(): string {
    return this.id.toString();
  }

  public get label(): string {
    return this.name;
  }
}
