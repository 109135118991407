import { Button, Modal } from 'antd';
import { blue, green } from '@ant-design/colors';
import React, { FC } from 'react';
import { useDialog } from '@surinderlohat/react-dialog';
import JobDropDown from '../JobDropDown';
import { useAppStores } from 'src/store';
import ValidateBuyQuestions from '../ValidateBuyQuestions';

type Props = {
  isSuperMatch?: boolean;
  allowRedirect?: boolean; // allow redirect to answer questions
  size?: 'small' | 'middle' | 'large'; // PropTypes.oneOf(['small', 'middle', 'large']),
  buttonTitle: string;
  onBuySuccess?: () => void;
};

const BuyQuestionsOrSuperMatchButton: FC<Props> = ({
  buttonTitle,
  isSuperMatch,
  size,
  allowRedirect,
  onBuySuccess,
}) => {
  const dialog = useDialog();
  const { userStore } = useAppStores();

  // confirm from user before buy
  const confirmBuy = () => {
    Modal.confirm({
      title: 'Confirm',
      content: isSuperMatch
        ? 'Are you sure you like to get more Super Match?'
        : 'Are you sure you like to answer more JobCommander Compatibility questions?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => onConfirm(),
      onCancel: () => dialog.closeDialog(),
    });
  };

  const onConfirm = () => {
    if (!isSuperMatch && userStore.isEmployer) {
      showSelectJobDialog();
      return;
    }
    buyMoreSuperMatchesOrQuestions();
  };

  // Buy more super matched or questions
  const buyMoreSuperMatchesOrQuestions = (employerJobId?: number) => {
    dialog.openDialog(
      <ValidateBuyQuestions
        onReloadResults={onBuySuccess}
        allowRedirect={allowRedirect}
        isSuperMatch={isSuperMatch}
        onCancel={() => dialog.closeDialog()}
        onBuySuccess={onBuySuccess}
        onSuccess={() => {
          onBuySuccess();
          dialog.closeDialog();
        }}
        employerJobId={employerJobId}
        onError={() => {}}
      />
    );
  };

  // If Employer User going to buy Questions then ask first to select the JOB
  const showSelectJobDialog = () => {
    dialog.openDialog(
      <JobDropDown
        onOkClick={buyMoreSuperMatchesOrQuestions}
        isSuperMatch={false}
        onClose={() => dialog.closeDialog()}
      />
    );
  };

  return (
    <Button
      type="primary"
      size={size}
      color={isSuperMatch ? blue.primary : green.primary}
      className="joy-question-button"
      onClick={confirmBuy}
    >
      {buttonTitle}
    </Button>
  );
};

BuyQuestionsOrSuperMatchButton.defaultProps = {
  onBuySuccess: () => {},
};

export { BuyQuestionsOrSuperMatchButton };
