import { EDITOR_TYPES } from '@job-commander/shared';
import { ExtracurricularModel, CollegeModel, HighSchoolModel, WorkExperienceModel, SkillModel } from 'src/models';
import { JobModel } from 'src/models/Job.model';

// Mapping between Models and the Editor Type
export const editorModelMapping = {
  [EDITOR_TYPES.HIGH_SCHOOL]: HighSchoolModel,
  [EDITOR_TYPES.COLLEGE]: CollegeModel,
  [EDITOR_TYPES.EXPERIENCE]: WorkExperienceModel,
  [EDITOR_TYPES.EXTRACURRICULAR]: ExtracurricularModel,
  [EDITOR_TYPES.SKILLS]: SkillModel,
  [EDITOR_TYPES.JOB]: JobModel,
};
