import { useDialog } from '@surinderlohat/react-dialog';
import React, { FC, useMemo } from 'react';
import { ViewJobDetails } from '../../../../../components';
import { IEditorItem } from '../../../../../interfaces';
import { Checkbox, Radio, Typography } from 'antd';
const { Title, Paragraph } = Typography;

import './ExtendJobDetails.less';
import { Utilities } from '@job-commander/shared';

type Props = {
  jobType: string;
  onJobTypesChange: (jobType: string) => void;
  jobs: IEditorItem[];
  onJobSelect: (selectedItem: IEditorItem[]) => void;
};

const ExtendJobDetails: FC<Props> = ({ jobs, jobType, onJobTypesChange, onJobSelect }) => {
  const dialog = useDialog();

  // filtered jobs before and after first hire
  const filterJobs = useMemo(
    () =>
      jobType === 'beforeFirstHire'
        ? jobs.filter(job => job.data?.isCreatedBeforeFirstHiring)
        : jobs.filter(job => !job.data?.isCreatedBeforeFirstHiring),
    [ jobType, jobs ]
  );

  // When user edit any item
  const onItemClick = editingItem => {
    dialog.openDialog(
      <ViewJobDetails hideCompany={true} job={editingItem.data} onClose={() => dialog.closeDialog()} />
    );
  };

  const onJobSelectChange = (selectedItem: IEditorItem) => {
    const updates = Utilities.updateArrayItemV3(jobs, { ...selectedItem, isSelected: !selectedItem.isSelected }, 'id');
    onJobSelect(updates);
  };

  return (
    <>
      <div className="job-types-container">
        <Radio.Group size="small" value={jobType} onChange={e => onJobTypesChange(e.target.value)}>
          <Radio.Button value="afterFirstHire">Created After First Hire</Radio.Button>
          <Radio.Button value="beforeFirstHire">Created Before First Hire</Radio.Button>
        </Radio.Group>
      </div>
      <div className="list-editor-container">
        {!filterJobs.length && 'No Job Available to extend'}
        {filterJobs.map((job, index) => (
          <div key={job?.id} className="jobs-ex-item" onClick={e => onItemClick(job)}>
            <Title level={5}>{job.title}</Title>
            {job.expirationLine?.map((line, index) => (
              <Paragraph key={job?.id}>{line}</Paragraph>
            ))}
            <Checkbox
              checked={job.isSelected}
              onClick={e => {
                e.stopPropagation();
                onJobSelectChange(job);
              }}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default ExtendJobDetails;
