import { EDITOR_TYPES } from '../../../enums';
export const jobScaffold = {
  jobs: [
    {
      heading:'Add Job Description',
      // title: 'Add Job Description',
      lines: [ 'Click here to add details about an open job.' ],
      editorType: EDITOR_TYPES.JOB,
      required: false,
      isNew: true,
      // ...jobMock,
    },
  ],
};
