import { RuleObject } from 'antd/lib/form';
import { regex } from './Regex';

export const zipCodeValidator = (): RuleObject => ({
  validator: (_, value) => {
    if (value?.length === 5 || value.length === 9) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Zip Code may only be 5 or 9 digits in length.'));
  },
});

export const phoneNoValidator = () => ({
  validator: (_, value) => {
    value = value.replace(/\D/gi, '');
    if (value?.length === 10) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Phone Number must contain 10 digits'));
  },
});

export const slamValidator = () => ({
  validator: (_, value) => {
    const _regex = new RegExp(regex.slam);
    if(!value){
      return Promise.resolve();
    }
    if (!_regex.test(value)) {
      return Promise.reject(new Error('Two digits after decimal are allowed'));
    }
    return Promise.resolve();
  },
});

export const stateValidator = () => ({
  validator: (_, value) => {
    const _value = value?.trim();
    const _regex = new RegExp(regex.alphabetsWithoutSpaces);
    if (!_regex.test(value)) {
      return Promise.reject(new Error('State (FL, GA, CT, etc.")'));
    }
    if (_value.length === 2) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('State (FL, GA, CT, etc.")'));
  },
});

export const maxChar = (value: number): RuleObject => {
  return { max: value, message: `Max ${value} characters allowed` };
};

export const regexValidation = (regexExp: RegExp, message: string): RuleObject => ({
  validator: (_, value) => {
    const _regex = new RegExp(regexExp);
    if (_regex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(message);
  },
});

export const salaryValidator = () => ({
  validator: (_, value) => {
    const _value = value.toString();
    const _regex = new RegExp(regex.numberOnly);
    if (_value.length > 15) {
      return Promise.reject(new Error('15 digits are allowed'));
    }
    if (!_regex.test(value)) {
      return Promise.reject(new Error('Only numbers are allowed'));
    }

    return Promise.resolve();
  },
});
export const alphabetsWithSpaceAndHyphen = (): RuleObject =>
  regexValidation(regex.alphaWithHyphenAndSpace, 'Only alphabets with space and hyphen are allowed ');

export const alphabetsWithoutSpaces = (): RuleObject =>
  regexValidation(regex.alphabetsWithoutSpaces, 'Only alphabets without spaces allowed');

export const alphabetsWithSpaces = (): RuleObject =>
  regexValidation(regex.alphabetsWithSpaces, 'Only alphabets with spaces are allowed');

export const withoutSpecialCharacter = (): RuleObject =>
  regexValidation(regex.noSpecialCharacter, 'Special characters are not allowed');

export const onlyNumber = (): RuleObject => regexValidation(regex.numberOnly, 'Only numbers are allowed');
