import React, { FC } from 'react';
import { Badge } from 'antd';
import { IProducts } from '../../interfaces';
import './Plan.less';

interface Props {
  plan: IProducts;
  recommended: boolean;
  selected: boolean;
  color: string;
  addPrice: number;
  disabled: boolean;
  onClick: Function;
  allowFreeJobPost: boolean;
}

const PlanComponent: FC<Props> = ({ plan, recommended, selected, color, disabled, onClick, allowFreeJobPost }) => {
  const getContent = () => (
    <div className={`plan-container ${color}`} onClick={() => !disabled && onClick()}>
      <div className="title-bar">{plan.displayName}</div>
      <div className="plan-content">
        <div className="section-header">Number of Job Descriptions</div>
        <div className="section">{plan.maximumHire > 50 ? '50+' : `${plan.minimumHire} - ${plan.maximumHire}`}</div>
        <div className="section-header">Questions per Job</div>
        <div className="section">20</div>

        <div className="section-header">{plan.description}</div>
        <div className="section price">
          <span style={{ textDecoration: allowFreeJobPost ? 'line-through' : 'none' }}>${plan.price}</span>
          {allowFreeJobPost && <span style={{ color: 'red', marginLeft: '5px' }}>$0</span>}
        </div>
      </div>
    </div>
  );

  return (
    <div className={`plan-selector ${selected === true ? 'selected' : ''} ${disabled === true ? 'disabled' : ''}`}>
      {recommended === true ? <Badge.Ribbon text="Best">{getContent()}</Badge.Ribbon> : getContent()}
    </div>
  );
};

PlanComponent.defaultProps = {
  recommended: false,
  selected: false,
  disabled: false,
  addPrice: 0,
  onClick: () => {},
};

export { PlanComponent };
