import React, { FC, useEffect, useRef, useState } from 'react';
import { InfoLabel, OverlayLoader, ViewPermission } from '@job-commander/shared';
import { Cascader, Checkbox, Form, FormInstance, Radio, Select } from 'antd';
import { JobPreferencesModel } from 'src/models/JobPreferences.model';
import { useAppStores } from 'src/store';
import { CandidateSlamDetails } from './CandidateSlam';
import './CandidateTellUsMore.less';

type Props = {
  jobPreferences?: JobPreferencesModel;
  onValuesChange?: Function;
  formRef: React.RefObject<any>;
  isLoading?: boolean;
  onCancel?: Function;
  onOk?: Function;
};

const CandidateMoreDetails: FC<Props> = ({ jobPreferences, formRef, onValuesChange }) => {
  const [ hasSlam, setHasSlam ] = useState(false);
  const [ form ] = Form.useForm();
  const { optionsStore } = useAppStores();

  const slamFormRef = useRef<FormInstance>();

  useEffect(() => {
    // Mock form validation function
    (formRef as any).current = {
      validateFields: async () => {
        await slamFormRef.current?.validateFields();
        await form.validateFields();
      },
      getFieldsValue: () => ({
        ...form.getFieldsValue(),
        slam: slamFormRef.current?.getFieldsValue(),
      }),
    };
  }, [ slamFormRef.current ]);

  useEffect(() => {
    setHasSlam(jobPreferences.isSlamSelected);
  }, []);

  useEffect(() => {
    optionsStore.loadEducationOptions();
    optionsStore.loadJobOptions();
  }, []);

  // When any value change in fields
  const onFormValueChange = () => {
    onValuesChange({ ...form.getFieldsValue(), slam: slamFormRef.current?.getFieldsValue() });
  };

  return (
    <OverlayLoader isLoading={optionsStore.isLoading}>
      <Form
        id="tell-us-more-form"
        name="tellUsMoreForm"
        size="middle"
        layout="vertical"
        className="editor-form"
        form={form}
        initialValues={jobPreferences}
        onValuesChange={updatedValue => {
          setHasSlam(form.getFieldValue('isSlamSelected'));
          if (form.getFieldValue('loanServicer') != 20) {
            form.setFieldsValue({ loanServicerOther: '' });
          }
          onFormValueChange();
        }}
      >
        <Form.Item name="benefits" label="Preferred Benefits (Select as many as you want)" hasFeedback>
          <Checkbox.Group options={optionsStore?.options?.benefits} />
        </Form.Item>

        <Form.Item>
          <Form.Item
            name="willingToTravel"
            label="Are you willing to travel?"
            rules={[{ required: true }]}
            hasFeedback
            required
            style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0' }}
          >
            <Select options={optionsStore?.options?.willingToTravels} placeholder="Select..." labelInValue />
          </Form.Item>

          <Form.Item
            name="isWillingToRelocate"
            label="Are you willing to relocate?"
            hasFeedback
            required
            rules={[{ required: true }]}
            style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 0 0 16px' }}
          >
            <Radio.Group>
              <Radio value={false}>No</Radio>
              <Radio value={true}>Yes</Radio>
            </Radio.Group>
          </Form.Item>
        </Form.Item>

        <Form.Item>
          <Form.Item
            name="jobVenue"
            label="Your preferred job venue?"
            hasFeedback
            required
            rules={[{ required: true }]}
            style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0' }}
          >
            <Select options={optionsStore?.options?.jobVenues} placeholder="Select..." labelInValue />
          </Form.Item>

          <Form.Item
            name="workWeek"
            label="What is your ideal work week?"
            hasFeedback
            required
            rules={[{ required: true }]}
            style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 0 0 16px' }}
          >
            <Select options={optionsStore?.options?.workWeeks} placeholder="Select..." labelInValue />
          </Form.Item>
        </Form.Item>

        <Form.Item
          name="industry"
          // eslint-disable-next-line max-len
          label="What type of Industry & Job Type do you prefer to work in? (Please type-in job or industry or use drop-down option)"
          required
          hasFeedback
          rules={[{ required: true }]}
        >
          <Cascader
            placeholder="Industry & Job Type"
            options={optionsStore?.options?.industries}
            displayRender={(labels, selectedOptions) => labels.join(' / ')}
            showSearch={{
              filter: (inputValue, path) =>
                path.some(option => {
                  const label = String(option.label).toLowerCase();
                  return label.includes(inputValue.toLowerCase());
                }),
            }}
          />
        </Form.Item>

        <Form.Item
          name="isSlamSelected"
          label={
            <InfoLabel
              label={'Are you interested in participating in our SLAM Program?'}
              tooltip={`Student Loan Debt is one the most stressful issues in America today.
             Our Student Loan Assistance Management Program is an educational platform that can help subscribers reduce their payment,
             eliminate their student loan debt, or simply educate them on how to properly manage their debt.
             Our methods have been proven to help our subscribers reduce or eliminate their student loan debt at a much faster rate than the average debt holder.
             Enrollment in the SLAM program is free and can offer advantages such as lower interest rates and/or lower payments.
             Upon completion of your JobCommander profile, you will receive an email with more information on the SLAM program.
             If you are interested, click “Yes” and answer the following questions below:`}
            />
          }
          rules={[{ required: true }]}
          required
          hasFeedback
        >
          <Radio.Group>
            <Radio value={false}>No</Radio>
            <Radio value={true}>Yes</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
      <ViewPermission isVisible={hasSlam}>
        <CandidateSlamDetails
          options={optionsStore?.options}
          value={form.getFieldValue('slam')}
          formRef={slamFormRef}
          onSlamChange={updatedSlam => {
            form.setFieldsValue({ slam: updatedSlam });
            onFormValueChange();
          }}
        />
      </ViewPermission>
    </OverlayLoader>
  );
};

CandidateMoreDetails.defaultProps = {
  onValuesChange: () => null,
  onCancel: () => null,
  onOk: () => null,
};

export { CandidateMoreDetails };
