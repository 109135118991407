import { IEditorItem } from '../../../../interfaces';
import { BuyExtendJobPackage } from '../../../../components';
import { useDialog } from '@surinderlohat/react-dialog';
import { useLoader } from '@surinderlohat/react-hooks';
import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import ExtendJobDetails from './Steps/ExtendJobDetails';
import ExtendJobPayment from './Steps/ExtendJobPayment';
import '../AddNewJobs/AddNewJobs.less';
import { OverlayLoader, StickyLayoutButtons, useAlert } from '@job-commander/shared';
import { useDataService } from 'src/api';
import { useAppStores } from 'src/store';
import { JobModel } from 'src/models/Job.model';

const steps = [
  { title: 'Select Jobs', description: 'Select Jobs to Extend' },
  { title: 'Plans', description: 'Pick a Pricing Plan' },
];

// Employer User will add new Jobs from add job description
const ExtendJobs: FC = () => {
  const { productsStore } = useAppStores();
  const [ currentStep, setCurrentStep ] = useState(0);
  const [ isPaymentDone, setIsPaymentDone ] = useState(false);
  const [ planLength, setPlanLength ] = useState(productsStore?.products?.extensionSubscriptionPackage[0]?.id);
  const [ employeeJobs, setEmployeeJobs ] = useState<IEditorItem[]>([]);
  const [ jobType, setJobType ] = useState('beforeFirstHire');
  const loader = useLoader(true);
  const history = useHistory();
  const dialog = useDialog();
  const alert = useAlert();
  const dataService = useDataService();

  useEffect(() => {
    loadEmployerJobs();
    productsStore.loadProducts();
  }, []);

  const options = productsStore?.products?.extensionSubscriptionPackage.map(plan => ({
    value: plan.id,
    label: `${plan.displayName} (${plan.price}/Job)`,
    days: plan.subscriptionDays,
  }));

  const getJobItems = (array, removable?: boolean) => {
    return array?.map((item: JobModel) => ({
      id: item.id,
      title: item.title,
      key: `job-${new Date().getTime()}`,
      expirationLine: [ `Expires: ${moment(item.expirationDate).format('l')}` ],
      required: false,
      removable: removable || false,
      data: item,
    }));
  };

  const selectedPlan = productsStore.products.extensionSubscriptionPackage?.find(plan => plan.id === planLength);

  const isJobSelected = React.useMemo(() => employeeJobs.some(x => x.isSelected), [ employeeJobs ]);

  const isBeforeFirstHire = jobType === 'beforeFirstHire';

  const onJobTypesChange = (jobType: string) => {
    setJobType(jobType);
    // unselect all jobs on uncheck of
    const unselectAllJobs = employeeJobs.map(item => {
      return { ...item, isSelected: false };
    });
    setEmployeeJobs(unselectAllJobs);
  };

  // load current logged in user profile
  const loadEmployerJobs = async () => {
    loader.showLoader();
    const result = await dataService.getExpiredJobs();
    loader.hideLoader();
    const jobs = getJobItems(result.data, true)
    setEmployeeJobs(jobs);
  };

  // ask user to buy package
  const askUserToPurchasePackage = () => {
    // already paid
    if (isPaymentDone) {
      dialog.closeDialog();
      setTimeout(() => history.replace('/a/my-jobs'), 500);
      return;
    }
    // total Number of hires
    const totalNumberOfHires = employeeJobs.filter(x => x.isSelected).length;
    const emplJobIds = employeeJobs.filter(x => x.isSelected).map(i => i.id);
    dialog.openDialog(
      <BuyExtendJobPackage
        emplJobIds={emplJobIds}
        totalNumberOfJobs={totalNumberOfHires}
        selectedPlan={selectedPlan}
        allowForFree={isBeforeFirstHire}
        onBuySuccess={async () => {
          setIsPaymentDone(true);
          dialog.closeDialog();
          setTimeout(() => history.replace('/a/my-jobs'), 500);
        }}
        onCancel={() => dialog.closeDialog()}
      />
    );
  };

  const onNextButtonClick = () => {
    if (currentStep === 0) {
      setCurrentStep(currentStep + 1);
      return;
    }
    if (currentStep === 1 && !selectedPlan) {
      alert.error('Please Select plan');
      return;
    }
    // save Questions and Ask For Payment
    askUserToPurchasePackage();
  };

  const onBackButtonClick = () => {
    if (currentStep <= 0) {
      return;
    }
    setCurrentStep(currentStep - 1);
  };
  return (
    <OverlayLoader isLoading={loader.isLoading || !productsStore.isProductLoaded}>
      <div id="add-job-wrapper">
        <StickyLayoutButtons
          title="Extend Jobs"
          nextButtonTitle={currentStep === 0 ? 'Next' : 'Submit'}
          steps={steps}
          currentStep={currentStep}
          onNextButtonClick={onNextButtonClick}
          onBackButtonClick={onBackButtonClick}
          disableNext={(step: number): boolean => (step === 0 ? !isJobSelected : false)}
          isLoading={loader.isLoading}
        >
          <ExtendJobDetails
            jobs={employeeJobs}
            onJobSelect={updates => setEmployeeJobs(updates)}
            jobType={jobType}
            onJobTypesChange={onJobTypesChange}
          />
          <ExtendJobPayment
            isPaymentDone={isPaymentDone}
            numberOfJobsSelected={employeeJobs.filter(x => x.isSelected).length}
            planLength={options[0]?.value}
            isLoading={productsStore.isLoadingProducts}
            plans={options}
            selectedPlan={selectedPlan}
            onChangePlanType={planLength => setPlanLength(planLength)}
            allowFreeJobExtend={isBeforeFirstHire}
          />
        </StickyLayoutButtons>
      </div>
    </OverlayLoader>
  );
};

export default ExtendJobs;
