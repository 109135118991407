import { useLoader } from '@surinderlohat/react-hooks';
import { useState } from 'react';
import { useDataService } from 'src/api';
import { IProductsResponse } from 'src/interfaces';

export interface IProductsStore {
  products: IProductsResponse;
  isLoadingProducts: boolean;
  isProductLoaded: boolean;
  loadProducts: () => Promise<void>;
}

// User State Model
export function useProductsStore(): IProductsStore {
  const [ isProductLoaded, setIProductLoaded ] = useState(false);

  const [ products, setProducts ] = useState<IProductsResponse>({
    superMatch: [],
    questionPackage: [],
    subscriptionPackage: [],
    extensionSubscriptionPackage:[],
  });

  const loader = useLoader();
  const apiService = useDataService();

  // Load Options Related to The Education
  const loadProducts = async (): Promise<void> => {
    if (products.superMatch.length) {
      return;
    }
    loader.showLoader();
    const result = await apiService.getProducts();
    loader.hideLoader();
    setProducts(result.data);
    setIProductLoaded(true);
  };

  return {
    isProductLoaded,
    isLoadingProducts: loader.isLoading,
    products,
    loadProducts,
  };
}
