import React, { CSSProperties, FC, ReactElement } from 'react';
import { Select } from 'antd';
const { Option } = Select;

interface Props {
  options: any[];
  style?: CSSProperties;
  placeholder?: string;
  defaultValue?: string;
  value?: any; // it can be object string or any time
  onChange?: (selectedOption: any) => void;
  getOptionLabel?: (option) => string | ReactElement;
  getOptionValue?: (option) => string;
}

const AntdSelect: FC<Props> = ({ defaultValue, getOptionLabel, getOptionValue, options, onChange, ...selectProps }) => {
  // handel option label Settings
  const optionLabel = option => {
    const isCallable = typeof getOptionLabel === 'function';
    return isCallable ? getOptionLabel(option) : option;
  };

  // handel option label Settings
  const optionValue = option => {
    const isCallable = typeof getOptionValue === 'function';
    return isCallable ? getOptionValue(option) : option;
  };

  return (
    <Select onChange={onChange} {...selectProps}>
      {options.map((option) => (
        <Option key={option} value={optionValue(option)}>
          {optionLabel(option)}
        </Option>
      ))}
    </Select>
  );
};

export default AntdSelect;
