/* eslint-disable max-len */
import React, { FC, useEffect, useState } from 'react';
import { Form, Select, Input, InputNumber, Typography, Modal } from 'antd';
import { dropDownOptions, maxChar, OverlayLoader, UpsertDialog, useAlert, ViewPermission } from '@job-commander/shared';
import { useDataService } from 'src/api';
import { useLoader } from '@surinderlohat/react-hooks';
import { useDialog } from '@surinderlohat/react-dialog';
import { StripeCard } from 'src/components/BuyModal/StripeCard/StripeCard';
import { usePaymentService } from 'src/api/PaymentService';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

type Props = {
  job?: any;
  formRef?: any;
  onDeleteSuccess?: () => void;
  updatedDataLoad : any;
  setEditMode : any;
};

enum websiteInfo {
  SAME_WEBSITE = 1,
  OTHER_WEBSITE = 2,
}

const RemoveJobQuestions: FC<Props> = ({ job, formRef, onDeleteSuccess, updatedDataLoad, setEditMode }) => {
  const dataService = useDataService();
  const loader = useLoader(true);
  const [ from ] = Form.useForm();
  const [ candidateOptions, setCandidateOptions ] = useState([]);
  const [ whereCandidateHired, setWhereCandidateHired ] = useState(null);
  const [ jobDeletedReason, setJobDeletionReason ] = useState([]);
  const [ reasonOptions, setReasonOptions ] = useState([]);
  const [ currentQuestion, setCurrentQuestion ] = useState('');
  const [ selectedCandidate, setSelectedCandidate ] = useState(null);
  const dialog = useDialog();
  const [ payOption, setPayOption ] = useState('');
  const [ hiringMode, setHiringMode ] = useState(false);
  const [ salaryOffered, setSalaryOffered ] = useState(false);
  const [ isPaymentModalVisible ,setPaymentModalVisible ] = useState(false);
  const [ isCardValid, setIsCardValid ] = useState(true);
  const paymentService = usePaymentService();
  const alert = useAlert();
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    getCandidateNames();
    loadRemoveJobQuestions();
  }, []);

  const otherOption = { value: -1, label: 'Other' };
  const getCandidateNames = async () => {
    loader.showLoader();
    const result = await dataService.getCandidateNames(job.id);
    loader.hideLoader();
    if (result.hasError) {
      return;
    }
    setCandidateOptions([ ...result.data, otherOption ]);
  };

  // load current logged in user profile
  const loadRemoveJobQuestions = async () => {
    loader.showLoader();
    const response = await dataService.removeJobQuestions();
    if (response.hasError) {
      return;
    }
    const jobDeletionReason = response.map(reason => ({
      value: reason.id,
      label: reason.name,
    }));
    setJobDeletionReason(jobDeletionReason);
    const reasonOptions = response[0].options.map(reason => ({
      value: reason.id,
      label: reason.name,
    }));
    setReasonOptions(reasonOptions);
    loader.hideLoader();
  };
  
  const saveChanges = async () => {
    alert.success('Thank You!');
    setEditMode(false);
    window.location.reload();
  };

  const onValueChanges = values => {
    if (values.jobDeletedReason === 2) {
      from.setFieldsValue({ foundCandidateReason: [] });
      setWhereCandidateHired(null);
      return;
    }
    if (values.foundCandidateReason === 1) {
      setHiringMode(false)
      from.setFieldsValue({ website: null });
      return;
    }
    if (values.foundCandidateReason === 2) {
      setHiringMode(false)
      from.setFieldsValue({ salaryOffered: null, candidateId: null, candidateNameOther: '' });
      setWhereCandidateHired(null);
    }
    if (values.foundCandidateReason === 3) {
      setHiringMode(true);
    }
  };

  const onReasonChange = (reason) => {
    if (reason?.value === 1) {
      setCurrentQuestion('otherQues');
      setSalaryOffered(true)
    } else if (reason?.value === 2) {
      setCurrentQuestion('mainQues2');
      setSalaryOffered(false)
    } else if (reason?.value === 4) {
      setCurrentQuestion('mainQues3');
      setSalaryOffered(false)
    }
  }

  const handleOnBuy = async (id) => {
    loader.showLoader();
    const request = {
      jobId: id ,
    };
    const checkoutResult = await paymentService.oneTimeExtendJob(request);

    if (checkoutResult.hasError) {
      alert.error(checkoutResult.statusText);
      loader.hideLoader();
      return;
    }

    const payload = await stripe.confirmCardPayment(checkoutResult.data?.paymentIntent.clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    if (payload.error) {
      alert.error(payload.error.message);
      loader.hideLoader();
      return;
    }

    const result = await paymentService.confirmPayment(checkoutResult.data?.transactionId);

    if (result.hasError) {
      loader.hideLoader();
      return;
    }
    loader.hideLoader();
    dialog.closeDialog();
    saveChanges();
  };

  const confirmBuy = async () => {
    await formRef.current?.validateFields();
    Modal.confirm({
      title: 'WHERE ARE YOU GOING?!',
      content: 'Because we hate to see you leave, we are offering you a one-time 30- day job extension for $49 for this job posting. Would you like to take us up on this offer?',
      okText: 'Yes Please',
      cancelText: 'No Thank You',
      onOk: () =>  setPaymentModalVisible(true),
      onCancel: () => submitResponse() ,
    })
  };

  const submitResponse = async () => {
    if (!job.removable) {
      return;
    }
    await formRef.current?.validateFields();
    const values = formRef.current?.getFieldsValue();

    const request = {
      employerJobId: job.id,
      jobDeletionReasonId: values?.jobDeletedReason?.value,
      jobDeletionReasonOptionId: values?.foundCandidateReason,
      candidateId: values?.candidateId === -1 ? null : values?.candidateId,
      salaryOffered: values?.salaryOffered,
      PayOffered: values?.payOffered,
      website: values?.website,
      candidateNameOther: values?.candidateId === -1 ? values?.candidateNameOther : null,
      AnswerForNotExtendPosition: currentQuestion === 'mainQues2' ? values?.why_extend : null,
      AnotherReason: currentQuestion === 'mainQues3' ? values?.reason : null,
      OutsideHiring: currentQuestion === 'otherQues' ? values?.hiringMode : null,
      PersonHiringDate: whereCandidateHired === websiteInfo.SAME_WEBSITE ? values?.hiringDate : null,
    };

    loader.showLoader();
    const response = await dataService.deleteJobFromEmail(request);
    loader.hideLoader();
    if (response.hasError) {
      return;
    }
    onDeleteSuccess();
    Modal.success({ content: 'This job description has not been extended and will be removed from our Candidate Pool’s view.' });
    dialog.closeDialog();
    saveChanges();
  }

  const onCardValueChange = e => {
    if (e.error != null || e.complete !== true) {
      setIsCardValid(false);
      return;
    }
    setIsCardValid(true);
  };
  

  return (
    <>
      <UpsertDialog
        title={`Job Description Survey Questions for ${job?.title}`}
        width={650}
        onCancel={() => dialog.closeDialog()}
        disabled={() =>
          (whereCandidateHired === websiteInfo.SAME_WEBSITE && !candidateOptions.length && currentQuestion === 'otherQues') || loader.isLoading
        }
        onOk={job?.isOneTimeAvailable ? submitResponse: confirmBuy}
        isLoading = {!candidateOptions.length}
      >
        <Form ref={formRef} form={from} onValuesChange={onValueChanges}>
          <Form.Item
            name="jobDeletedReason"
            label="Why are you removing this job?"
            rules={[{ required: true }]}
            hasFeedback
          >
            <Select
              options={jobDeletedReason}
              placeholder="Select..."
              onChange={reason => onReasonChange(reason)}
              labelInValue
            />
          </Form.Item>

          <ViewPermission isVisible={currentQuestion === 'otherQues'}>
            <Form.Item
              name="foundCandidateReason"
              label="Where did you find the candidate?"
              rules={[{ required: true }]}
              hasFeedback
            >
              <Select options={reasonOptions} placeholder="Select..." onChange={value => setWhereCandidateHired(value)} />
            </Form.Item>
          </ViewPermission>
          <ViewPermission isVisible={whereCandidateHired === websiteInfo.SAME_WEBSITE && candidateOptions.length >= 1 && currentQuestion === 'otherQues'}>
            <Form.Item label="Who have you hired?" name="candidateId" rules={[{ required: true }]}>
              <Select
                options={candidateOptions}
                placeholder="Select Candidate From List"
                onChange={value => {
                  setSelectedCandidate(value);
                }}
              />
            </Form.Item>
          </ViewPermission>
          <ViewPermission isVisible={whereCandidateHired === websiteInfo.SAME_WEBSITE && selectedCandidate === -1 && currentQuestion === 'otherQues'}>
            <Form.Item
              name="candidateNameOther"
              label="What was the name of the candidate?"
              required
              hasFeedback
              rules={[{ required: true }, maxChar(100) ]}
            >
              <Input type="text" placeholder="Full Name" />
            </Form.Item>
          </ViewPermission>

          <ViewPermission isVisible={hiringMode && currentQuestion === 'otherQues'}>
            <Form.Item label="What was the mode of hiring?" required name="hiringMode" rules={[{ required: true }]}>
              <Select
                options={dropDownOptions?.HiringOption}
                placeholder="Select Mode Of Hiring"
              />
            </Form.Item>
          </ViewPermission>

          <ViewPermission isVisible={whereCandidateHired === websiteInfo.SAME_WEBSITE && currentQuestion === 'otherQues'}>
            <Form.Item label="What Mode was the pay offered?" required name="payOffered" rules={[{ required: true }]}>
              <Select
                options={dropDownOptions?.PayOption}
                placeholder="Select pay offered"
                onChange={value => {
                  value === 'Hourly' ? setPayOption('Hourly') : value === 'Salary' ? setPayOption('Salary') : setPayOption('no-pay')
                }}
              />
            </Form.Item>
          </ViewPermission>
          <ViewPermission isVisible={salaryOffered && whereCandidateHired === websiteInfo.SAME_WEBSITE && (payOption === 'Hourly' || payOption === 'Salary')}>
            <Form.Item name="salaryOffered" required label={`What was the ${payOption} you offered?`} rules={[{ required: true }]}>
              <InputNumber
                min={0}
                style={{ width: '100%' }}
                placeholder="Salary Offered"
                step={500}
                formatter={value => `$ ${value}`.replace(/\/\\B\(\?=\(\\d\{3\}\)\+\(\?!\\d\)\)\/g/, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '') as any}
              />
            </Form.Item>
          </ViewPermission>
          <ViewPermission isVisible={whereCandidateHired === websiteInfo.SAME_WEBSITE && currentQuestion === 'otherQues'}>
            <Form.Item label="What is this person’s hiring date?" required name="hiringDate" rules={[{ required: true }]}>
              <input type='date' />
            </Form.Item>
          </ViewPermission>
          <ViewPermission isVisible={currentQuestion === 'mainQues2'}>
            <Form.Item
              name="why_extend"
              label="Please let us know why you do not want to extend this position"
              rules={[{ required: true }]}
              required
              hasFeedback
            >
              <Input type="text" placeholder="write here..." />
            </Form.Item>
          </ViewPermission>

          <ViewPermission isVisible={currentQuestion === 'mainQues3'}>
            <Form.Item
              name="reason"
              label="Please tell us the reason for not extending"
              rules={[{ required: true }]}
              required
              hasFeedback
            >
              <Input type="text" placeholder="write here..." />
            </Form.Item>
          </ViewPermission>
          <ViewPermission isVisible={whereCandidateHired === websiteInfo.OTHER_WEBSITE && currentQuestion === 'otherQues'}>
            <Form.Item
              name="website"
              label="What website or other source did you use to find your hire"
              rules={[{ required: true }]}
              required
              hasFeedback
            >
              <Input type="text" placeholder="https://www.company.com" />
            </Form.Item>
          </ViewPermission>
          <ViewPermission
            isVisible={whereCandidateHired === websiteInfo.SAME_WEBSITE && !candidateOptions.length && currentQuestion === 'otherQues'}
          >
            <Typography.Title level={5}>
              It looks like you have not hired any candidate on Job Commander.
            </Typography.Title>
          </ViewPermission>
          <p style={{ fontSize: '16px', marginTop : '20px' }}>
            <strong>Note : </strong>
          We will never share this information with the Candidate or non-authorized user. This data is kept internal and simply so we can make our platform and service better for our users!
          </p>
        </Form>
      </UpsertDialog>
      <Modal
        visible={isPaymentModalVisible}
        title={`Job Extension for ${job?.title}`}
        destroyOnClose={true}
        maskClosable={true}
        onOk={ () => handleOnBuy(job?.id)}
        onCancel={() => { setPaymentModalVisible(false); dialog.closeDialog() }}
        okButtonProps={{ loading: loader.isLoading }}
        cancelButtonProps={{ disabled: loader.isLoading }}
        okText="Buy"
      >
        <Form id="buy-form" layout="horizontal" >
          <Form.Item
            label="Total Payment : "
          >
            {'$49'} 
          </Form.Item>
          <Form.Item name="card">
            <StripeCard onChange={onCardValueChange} isCardValid={isCardValid} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export { RemoveJobQuestions };
