import { CoreModel } from '@job-commander/shared';
import {  IHiredCandidateNames } from 'src/interfaces/API-Candidate.interface';

export class HiredCandidateNamesModel extends CoreModel {
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  phone: string = '';

  constructor(data?: HiredCandidateNamesModel) {
    super(data);
    Object.assign(this, data);
  }

  public static deserialize(data: IHiredCandidateNames) {
    if (!data) {
      return new HiredCandidateNamesModel();
    }

    return new HiredCandidateNamesModel({
      ...(data as any),
    });
  }

  public static deserializeList(data: IHiredCandidateNames[]) {
    if (!data || !Array.isArray(data)) {
      return [];
    }
    return data.map(x => HiredCandidateNamesModel.deserialize(x));
  }

  public get value(): number {
    return this.id;
  }

  public get label(): string {
    return `${this.firstName} ${this.lastName}`;
  }
}
