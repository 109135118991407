/* eslint-disable no-useless-escape */
export const regex = {
  stateCode: '',
  alphabetsWithoutSpaces: /^[a-zA-Z]*$/,
  alphabetsWithSpaces:/^[a-zA-Z ]*$/,
  // eslint-disable-next-line no-useless-escape
  noSpecialCharacter: /^[a-zA-Z0-9\s.\?\,\'\;\:\!\-]+$/,
  // eslint-disable-next-line no-useless-escape
  website: /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/gi,
  numberOnly: /^[0-9]+$/,
  alphaWithHyphenAndSpace: /^[a-zA-Z -]*$/,
  salaryFormatter :/\B(?=(\d{3})+(?!\d))/g,
  salaryParser :/\$\s?|(,*)/g,
  salary:/\$\ ?[+-]?[0-9]{1,3}(?:,?[0-9])*(?:\.[0-9]{1,2})?/,
  slam:/^100$|^100.00$|^\d{0,2}(\.\d{1,2})? *%?$/,
};
