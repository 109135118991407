import { Form, Select } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { IProducts } from 'src/interfaces';

type Props = {
  onQuantityChange: Function;
  quantity?: number;
  products: IProducts[];
};

const BuyQuestions: FC<Props> = ({ products, quantity, onQuantityChange }) => {
  const [ product, setProduct ] = useState<any>({});
  // set product
  useEffect(() => setProduct(products.find(_product => _product.questionsToBuy === quantity)), []);

  // price > candidate > qty > value
  const getPrice = () => (product ? `$${product.price}` : '');
  return (
    <Form.Item>
      <Form.Item
        name="quantity"
        label="Quantity"
        style={{ display: 'inline-flex', width: 'calc(50% - 8px)', margin: 0 }}
      >
        <Select
          optionLabelProp="label"
          onChange={e => {
            setProduct(products.find(_product => _product.questionsToBuy === parseInt(e)));
            onQuantityChange(parseInt(e));
          }}
        >
          {products.map((_product, idx) => (
            <Select.Option key={_product.id} value={_product.questionsToBuy}>
              {_product.productName}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="total"
        label="Total Price"
        style={{ display: 'inline-flex', width: 'calc(50% - 8px)', margin: '0 0 0 16px' }}
      >
        <div className="price-text">{getPrice()}</div>
      </Form.Item>
    </Form.Item>
  );
};

BuyQuestions.defaultProps = {
  products: [],
};

export { BuyQuestions };
