import { EDITOR_TYPES, SelectOptionModel, Utilities } from '@job-commander/shared';
import { ScaffoldModel } from './Scaffold.model';
import { IWorkExperience } from 'src/interfaces/API-Candidate.interface';
export class WorkExperienceModel extends ScaffoldModel {
  companyName: string;
  jobTitle: string;
  jobDescription: string;
  experienceInYear: SelectOptionModel;
  industry: (string | number)[];
  industryLabel: SelectOptionModel;
  industryJob: SelectOptionModel;

  constructor(data?: Partial<WorkExperienceModel>) {
    super(data);
    Object.assign(this, data);
    this.editorType = EDITOR_TYPES.EXPERIENCE;
    this.title = Utilities.trimCharacters(this.companyName, 29) || '';
    this.lines = [ Utilities.trimCharacters(this.jobTitle, 60) ];
  }

  public static deserialize(response: IWorkExperience) {
    return new WorkExperienceModel({
      ...response,
      experienceInYear: SelectOptionModel.deserialize(response?.experienceInYear),
      industry: response?.industry ? [ response?.industry?.id?.toString(), response?.industryJob.id ] : [],
      industryLabel: SelectOptionModel.deserialize(response?.industry),
      industryJob: SelectOptionModel.deserialize(response?.industry),
    });
  }

  public static deserializeList(data: IWorkExperience[]): WorkExperienceModel[] {
    if (!data || !Array.isArray(data)) {
      return [];
    }
    return data.map(x => WorkExperienceModel.deserialize(x));
  }

  public serialize(): IWorkExperience {
    return {
      id: this.id,
      companyName: this.companyName,
      jobTitle: this.jobTitle,
      jobDescription: this.jobDescription,
      experienceInYearId: this.experienceInYear?.value,
      industryId: parseInt(this.industry[0] as string), // Need This conversion
      industryJobId: this.industry[1],
    };
  }
}
