/* eslint-disable max-len */
export const generalMessage = {
  somethingWentWrong: 'Something Went Wrong!',
  apiError: 'Api Error!',
  contactUs:
    'Your request has been received. We will be in touch shortly. Thank you for your interest in JobCommander!',
  completeSignupError:
    'Oops! There was a problem registering your account. Please try again. If the problem persists, please contact the JobCommander support team.',
  codeSentMessage: 'Code Sent!, Code has been sent to your phone number!',
  linkSentMessage: 'Link Sent!, Password Reset link has been sent to your email!',
  accountNotRegistered:
    'Account Not Registered!, Account not registered with Job Commander please signup to get started!',
  closeModalConfirm:
    'If you close this window, all your work will be lost and you\'ll have to start over. Are you sure you want to continue?',
};
