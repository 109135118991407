import { EDITOR_TYPES, Utilities, CoreModel } from '@job-commander/shared';

export class ScaffoldModel extends CoreModel {
  heading?:string;
  title: string;
  lines: string[];
  editorType: EDITOR_TYPES = EDITOR_TYPES.HIGH_SCHOOL;
  required: boolean = false;
  removable: boolean = true;
  isNew: boolean = true;
  tempId?: string = Utilities.createId();
}
