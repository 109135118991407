import { EDITOR_TYPES, SelectOptionModel, Utilities } from '@job-commander/shared';
import { IExtracurricular } from 'src/interfaces/API-Candidate.interface';
import { ScaffoldModel } from './Scaffold.model';

export class ExtracurricularModel extends ScaffoldModel {
  organizationName: string;
  position: string;
  description: string;
  experienceInMonth: SelectOptionModel;

  constructor(data?: Partial<ExtracurricularModel>) {
    super(data);
    Object.assign(this, data);
    this.editorType = EDITOR_TYPES.EXTRACURRICULAR;
    this.title = Utilities.trimCharacters(this.organizationName, 29) || '';
    this.lines = [ Utilities.trimCharacters(this.position, 60) ];
  }

  public static deserialize(response: IExtracurricular) {
    return new ExtracurricularModel({
      ...response,
      experienceInMonth: SelectOptionModel.deserialize(response?.experienceInMonth),
    });
  }

  public static deserializeList(data: IExtracurricular[]): ExtracurricularModel[] {
    if (!data || !Array.isArray(data)) {
      return [];
    }
    return data.map(x => ExtracurricularModel.deserialize(x));
  }

  public serialize(): IExtracurricular {
    return {
      id: this.id,
      organizationName: this.organizationName,
      position: this.position,
      description: this.description,
      experienceInMonthId: this.experienceInMonth?.value,
    };
  }
}
