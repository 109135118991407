import React, { lazy, Suspense, FC } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { DialogProvider } from '@surinderlohat/react-dialog';
import { DebugComponent } from './components';
import './App.less';
import process from 'process';
import { MsalContextProvider, NotificationProvider } from '@job-commander/shared';
import { msalConfig } from './context/authConfig';
import { AppStoresProvider } from './store/AppStoresProvider';

import AddExtendJobs from './pages/Authenticated/MyJobsEmployer/AddExtendJobs';
import VerifyPhone from './pages/AuthorizationPage/VerifyPhone/VerifyPhone';
import { SessionProvider } from './components/Authorization/SessionProvider';

const AuthorizationPage = lazy(() => import(/* webpackPrefetch: true */ './pages/AuthorizationPage/AuthorizationPage'));
const TermsOfUsePage = lazy(() => import(/* webpackPrefetch: true */ './pages/TermsOfUsePage/TermsOfUsePage'));
const PrivacyPolicyPage = lazy(() => import(/* webpackPrefetch: true */ './pages/PrivacyPolicyPage/PrivacyPolicyPage'));
const AccessibilityPage = lazy(() => import(/* webpackPrefetch: true */ './pages/AccessibilityPage/AccessibilityPage'));
const LayoutPage = lazy(() => import(/* webpackPrefetch: true */ './pages/Authenticated/Layout/Layout'));
const CompleteSignUpPage = lazy(() =>
  import(/* webpackPrefetch: true */ './pages/CompleteSignUpPage/CompleteSignUpPage')
);

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const App: FC = () => {
  return (
    <Router forceRefresh={false}>
      <Suspense fallback={<div>Loading ...</div>}>
        <NotificationProvider>
          <MsalContextProvider msalConfig={msalConfig}>
            <AppStoresProvider>
              <Elements stripe={stripePromise}>
                <DialogProvider>
                  <SessionProvider>
                    <Switch>
                      {/* <Route exact path="/" component={LandingPage} /> */}
                      <Route path="/auth" component={AuthorizationPage} />
                      <Route path="/terms-of-use" exact component={TermsOfUsePage} />
                      <Route path="/privacy-policy" exact component={PrivacyPolicyPage} />
                      <Route path="/accessibility" exact component={AccessibilityPage} />
                      <Route path="/a" component={LayoutPage} />
                      <Route path="/my-jobs" component={AddExtendJobs} />
                      <Route path="/complete-signup" component={CompleteSignUpPage} />
                      <Route path="/verify-phone" component={VerifyPhone} />
                      <Redirect path="/*" to="/auth" />
                    </Switch>
                  </SessionProvider>
                </DialogProvider>
                {process.env.SHOW_DEBUG && <DebugComponent />}
              </Elements>
            </AppStoresProvider>
          </MsalContextProvider>
        </NotificationProvider>
      </Suspense>
    </Router>
  );
};

export default App;
