import React, { FC, useEffect, useState } from 'react';
import { useAlert, QuestionsDialog } from '@job-commander/shared';

import { useLoader } from '@surinderlohat/react-hooks';
import { useDataService } from 'src/api';
import { useAppStores } from 'src/store';
import { matchItemMessages } from 'src/components/MatchItem/MatchItemMessages';
import { Modal } from 'antd';
import { MatchesResultModel } from 'src/models/MatchesResult.model';

type Props = {
  employerJobId?: number;
  candidateId?: number;
  candidateName ?: string;
  employerJobTitle ?: string;
  onQuestionsResult: (isAnswersMatch?: boolean) => void;
  onDialogClose?: () => void;
  item : MatchesResultModel;
  isEmployer ?: boolean ;
  onItemUpdate?: (updatedItem: any) => void;
};

// eslint-disable-next-line max-len
const AnswerSuperMatchQuestions: FC<Props> = ({ onDialogClose, employerJobId, candidateId, item, isEmployer, onItemUpdate, ...props }) => {
  const [ questions, setQuestions ] = useState([]);
  const dataService = useDataService();
  const loader = useLoader();
  const alert = useAlert();
  const { userStore } = useAppStores();
  const CandidateSupermatchConfirm = `Congratulations! Your answers closely match what ${props?.employerJobTitle} is looking for. Now you can apply for this position. Please click Apply to start your application process with this employer. Please note that we will charge you a SuperMatch credit. We only use a SuperMatch credit when your SuperMatch results match.`
  const EmployerSupermatchConfirm = `Congratulations! Your answers closely match what ${props?.candidateName} is looking for. Now you can invite the candidate to apply. Please click Invite to Apply to start your application process with this candidate. Please note that we will charge you a SuperMatch credit. We only use a SuperMatch credit when your SuperMatch results match.`
  
  const loadQuestions = async () => {
    loader.showLoader();
    const result = await dataService.getSuperMatchQuestions({ employerJobId: employerJobId, candidateId: candidateId });
    loader.hideLoader();
    if (result.hasError) {
      setTimeout(() => onDialogClose(), 500);
      return;
    }
    setQuestions(result);
  };

  useEffect(() => {
    loadQuestions();
  }, []);

  // confirm user to apply for job
  const confirmApplyOrInvite = () => {
    // if user has already applied then they can't apply or invite again
    if (item.isAppliedOrInvited) {
      Modal.info(matchItemMessages.alreadyInvitedMessage(userStore.isCandidate));
      return;
    }
    console.log('comes here')
    Modal.confirm({
      title: 'Confirm',
      content: matchItemMessages.inviteConfirmMessage(isEmployer),
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {
        const jobId = item.employerJobId;
        const candidateId = item.candidateId;
        const result = await dataService.applyOrInvite({ candidateId: candidateId, employerJobId: jobId });
        if (result.error) {
          return;
        }
        alert.success('Success!', matchItemMessages.inviteSuccessMessage(isEmployer, item));
        // Update
        onItemUpdate({ ...item, isAppliedOrInvited: true });
      },
    });
  };


  //**Save user answers */
  const saveChanges = async answers => {
    loader.showLoader();
    const request = {
      employerJobId: employerJobId,
      candidateId: candidateId,
      answers: Object.keys(answers).map(key => ({ answerId: answers[key] })),
    };
    const result = await dataService.saveSuperMatchAnswers(request, userStore.isCandidate);
    loader.hideLoader();
    if (result.hasError) {
      return;
    }

    if (result?.data.isSuperMatchSucceed === false) {
      props.onQuestionsResult(result?.data.isSuperMatchSucceed);
      alert.error(matchItemMessages.failedSuperMatchQuestions(userStore.isCandidate));
      onDialogClose();
      return;
    }
    props.onQuestionsResult(result?.data.isSuperMatchSucceed);
   
    Modal.confirm({
      title: 'Confirm',
      content: userStore?.isCandidate ? CandidateSupermatchConfirm : EmployerSupermatchConfirm,
      okText: userStore?.isCandidate ? 'Apply' : 'Invite to Apply',
      cancelText: 'Cancel',
      onOk: () => confirmApplyOrInvite(),
    });

    onDialogClose();
  };

  return (
    <QuestionsDialog
      isLoading={loader.isLoading}
      questions={questions}
      onSubmitQuestionnaire={(answers, time) => saveChanges(answers)}
      onDialogClose={onDialogClose}
    />
  );
};

AnswerSuperMatchQuestions.defaultProps = {
  onDialogClose: () => null,
};

export { AnswerSuperMatchQuestions };
