export const Criteria = [
  { key: 'benefits', title: 'Benefits' },
  { key: 'degreePreference', title: 'Degree Preference' },
  { key: 'industryAndJobType', title: 'Industry & Job Type' },
  { key: 'educationLevel', title: 'Level of Education' },
  { key: 'gpa', title: 'GPA' },
  { key: 'jobVenue', title: 'Job Venue  ' },
  { key: 'relocation', title: 'Relocation' },
  { key: 'slamProgram', title: 'SLAM Program  ' },
  { key: 'supermatch', title: 'supermatch  ' },
  { key: 'workTravel', title: 'Work Travel' },
  { key: 'workWeek', title: 'Work Week' },
  { key: 'yearsExperience', title: 'Years Experience' },
];
