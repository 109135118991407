import React, { FC, useEffect, useState } from 'react';
import { Form, Input, InputNumber, Select, Checkbox, Slider, Cascader } from 'antd';
import {
  InfoLabel,
  ViewPermission,
  zipCodeValidator,
  stateValidator,
  EDUCATION_LEVEL,
  PAY_TYPE,
  maxChar,
  alphabetsWithSpaceAndHyphen,
  salaryValidator,
  slamValidator,
} from '@job-commander/shared';

import './EditJobDetails.less';

import { useAppStores } from 'src/store';
import { JobModel } from 'src/models/Job.model';
import { JobWithNamesModel } from 'src/models/JobWithNames.model';

const sliderMarks = {
  0: '0',
  100: '100',
};

type Props = {
  formRef?: any;
  item: JobModel;
  fromProfile?: boolean;
  jobsToCopyAnsers: (JobModel | JobWithNamesModel)[]; // jobs from model to copy answers
};
const { TextArea } = Input;
const EditJobDetails: FC<Props> = ({ item, formRef, fromProfile = false, jobsToCopyAnsers }) => {
  const { optionsStore } = useAppStores();
  const [ showExtra, setShowExtra ] = useState(false);
  const [ enableSlam, setEnableSlam ] = useState(item.isSlam);
  const [ selectedEducation, setSelectedEducation ] = useState(item.educationLevel?.value || null);
  const [ from ] = Form.useForm();

  useEffect(() => {
    optionsStore.loadEducationOptions();
    optionsStore.loadJobOptions();
  }, []);
  const itemData = item;

  const [ payType, setPayType ] = useState(item.payType?.value);

  const getJobOptions = () => [{ value: -1, label: 'Answer New Questions' }, ...jobsToCopyAnsers ];

  const renderIndustryLabel = labels => labels.join(' / ');

  // set if job is Paid if job is salary or hourly
  const isPaidJob = () => payType === PAY_TYPE.HOURLY || payType === PAY_TYPE.SALARY;

  // is candidate paid by hourly
  const isHourlyPaid = () => payType === PAY_TYPE.HOURLY;

  const onSelectPayType = payType => {
    from.setFieldsValue({ payType: payType });

    setPayType(payType.value);
  };

  const onValuesChange = values => {
    if (values.questionTemplate === -1) {
      setShowExtra(true);
    } else {
      setShowExtra(false);
    }

    if (values.isSlam) {
      setEnableSlam(true);
    } else if (values.isSlam != null) {
      setEnableSlam(false);
      from.setFieldsValue({ slamAmount: '' });
    }
    if (values.educationLevel) {
      from.setFieldsValue({ preferenceMajorIds: [] });
      from.setFieldsValue({ gpa: null });
    }
    if (values.payType) {
      from.setFieldsValue({ salary: null });
    }
  };

  // Get Degree Options based on the selected education type
  const getDegreeOptions = () => {
    if (selectedEducation === EDUCATION_LEVEL.DOCTORATE) return optionsStore.options?.doctorate;
    if (selectedEducation === EDUCATION_LEVEL.MASTER) return optionsStore.options?.master;
    return optionsStore.options?.degrees;
  };
  return (
    <Form
      id="job-form"
      name="jobForm"
      size="middle"
      layout="vertical"
      className="editor-form"
      ref={formRef}
      form={from}
      initialValues={itemData}
      onValuesChange={onValuesChange}
      disabled = {item?.isExpired || item?.isDeleted}
    >
      <Form.Item name="title" label="Job Title" rules={[{ required: true }, maxChar(100) ]} hasFeedback>
        <Input type="text" placeholder="Job Title" disabled={item?.isJobInFreeTrialPeriod}   
          title={item?.isJobInFreeTrialPeriod ? 
            // eslint-disable-next-line max-len
            'You are not permitted to edit the job title for a job posted in JobCommander Lite (our free platform). Upgrade to JobCommander Premium to edit this job title.' : ''}/>
      </Form.Item>

      <Form.Item name="description" label="Job Description" rules={[{ required: true }, maxChar(10000) ]} hasFeedback>
        <TextArea placeholder="Job Description and Duties" rows={4} disabled={item?.isJobInFreeTrialPeriod} 
          title={item?.isJobInFreeTrialPeriod ? 
            // eslint-disable-next-line max-len
            'You are not permitted to edit the job description for a job posted in JobCommander Lite (our free platform). Upgrade to JobCommander Premium to edit this job description.' : ''} />
      </Form.Item>

      <Form.Item label="Job Location">
        <Form.Item
          name="city"
          label="City"
          rules={[{ required: true }, maxChar(30), alphabetsWithSpaceAndHyphen() ]}
          hasFeedback
          style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0' }}
        >
          <Input type="text" placeholder="Job City" />
        </Form.Item>
        <Form.Item
          name="state"
          label="State"
          className="ant-from-allow-error"
          rules={[{ required: true }, stateValidator() ]}
          hasFeedback
          style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 0 0 16px' }}
        >
          <Input type="text" placeholder="Job State" />
        </Form.Item>
      </Form.Item>
      <Form.Item
        name="zipCode"
        label="Zip Code"
        className="ant-from-allow-error"
        rules={[{ required: true, message: 'Please enter zip code' }, zipCodeValidator() ]}
        hasFeedback
        style={{ width: 'calc(50% - 8px)' }}
      >
        <Input type="number" placeholder="Zip Code" />
      </Form.Item>

      <Form.Item
        name="industry"
        label="Industry & Job Type (Please type-in job or industry or use drop-down option)"
        rules={[{ required: true }]}
        hasFeedback
      >
        <Cascader
          placeholder="Industry & Job Type"
          options={optionsStore.options.industries}
          displayRender={renderIndustryLabel}
          showSearch={{
            filter: (inputValue, path) =>
              path.some(option => {
                const label = String(option.label).toLowerCase();
                return label.includes(inputValue.toLowerCase());
              }),
          }}
        />
      </Form.Item>

      <Form.Item>
        <Form.Item
          name="vacancy"
          label="Hires"
          rules={[{ required: true }]}
          hasFeedback
          style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0' }}
        >
          <InputNumber min={1} placeholder="Number of Hires for This Position" step={1} disabled={item.id != null} />
        </Form.Item>

        <Form.Item
          name="jobVenue"
          label="Venue Preference"
          rules={[{ required: true }]}
          hasFeedback
          style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 0 0 16px' }}
        >
          <Select options={optionsStore.options.jobVenues} placeholder="This job is ..." labelInValue />
        </Form.Item>
      </Form.Item>

      <Form.Item>
        <Form.Item
          name="experienceInYear"
          label="Experience Preference"
          rules={[{ required: true }]}
          hasFeedback
          style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0' }}
        >
          <Select
            options={optionsStore.options.yearsExperience}
            placeholder="Preferred Experience Length"
            labelInValue
          />
        </Form.Item>

        <Form.Item
          name="workWeek"
          label="Work Week Preference"
          rules={[{ required: true }]}
          hasFeedback
          style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 0 0 16px' }}
        >
          <Select options={optionsStore.options.workWeeks} placeholder="Work Week Required" labelInValue />
        </Form.Item>
      </Form.Item>

      <Form.Item>
        <Form.Item
          name="isRelocation"
          label="Relocation Preference"
          rules={[{ required: true }]}
          hasFeedback
          style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0' }}
        >
          <Select placeholder="Willingness to Relocate">
            <Select.Option value={false}>No</Select.Option>
            <Select.Option value={true}>Yes</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="willingToTravel"
          label="Travel Preference"
          rules={[{ required: true }]}
          hasFeedback
          style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 0 0 16px' }}
        >
          <Select options={optionsStore.options?.willingToTravels} placeholder="Willingness to Travel" labelInValue />
        </Form.Item>
      </Form.Item>

      <Form.Item>
        <Form.Item
          name="educationLevel"
          label="Education Preference"
          rules={[{ required: true }]}
          hasFeedback
          style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0' }}
        >
          <Select
            options={optionsStore?.options?.educationLevels}
            placeholder="Education Preferred"
            onSelect={education => setSelectedEducation(education.value)}
            labelInValue
          />
        </Form.Item>

        <ViewPermission isVisible={!isNoneOrDoctorate(selectedEducation)}>
          <Form.Item
            name="gpa"
            label="Minimum GPA Preference"
            rules={[{ required: true }]}
            hasFeedback
            style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 0 0 16px' }}
          >
            <Select options={optionsStore?.options?.gpAs} placeholder="Minimum GPA Preferred" labelInValue />
          </Form.Item>
        </ViewPermission>
      </Form.Item>

      <ViewPermission isVisible={!isNoneEducation(selectedEducation)}>
        <Form.Item name="preferenceMajorIds" label="Degree Preference" rules={[{ required: true }]} hasFeedback>
          <Select
            options={getDegreeOptions()}
            placeholder="Degree Preferred"
            filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            // initialValue={itemData.degree}
            showArrow={true}
            mode="multiple"
            allowClear
            showSearch
          />
        </Form.Item>
      </ViewPermission>

      <Form.Item name="benefits" label="Benefits You Would Provide" hasFeedback>
        <Checkbox.Group options={optionsStore?.options?.benefits} disabled={item?.isExpired || item?.isDeleted}/>
      </Form.Item>
      <Form.Item name="payType" label="What Type of Pay is this Job?" rules={[{ required: true }]} hasFeedback>
        <Select
          placeholder="Select Pay Type"
          options={optionsStore?.options.payTypes}
          onSelect={onSelectPayType}
          labelInValue
        />
      </Form.Item>
      <ViewPermission isVisible={isPaidJob()}>
        <Form.Item
          name="salary"
          label={`Salary ${isHourlyPaid() ? '(per Hour)' : ''} `}
          rules={[{ required: isPaidJob() }, salaryValidator() ]}
          hasFeedback
        >
          <InputNumber
            min={0}
            placeholder="Approximate Salary"
            step={500}
            formatter={value => `$ ${value}`.replace(/\/\\B\(\?=\(\\d\{3\}\)\+\(\?!\\d\)\)\/g/, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '') as any}
          />
        </Form.Item>
      </ViewPermission>

      <Form.Item
        name="minimumCompatibilityScore"
        rules={[{ required: true }]}
        label={
          <InfoLabel
            label="Minimum Compatibility Score"
            tooltip={`As the Employer, JobCommander believes you should have control of your hiring process. 
            JobCommander’s algorithm is always looking to find you the most qualified and best matching candidates to fill your job vacancies.
            The Minimum Compatibility Score is just another tool JobCommander is giving you to find the most qualified hire. 
            When you input your Minimum Compatibility Score, you are choosing the baseline score that a candidate could have to apply for your open position.
            Only Candidates that meet your certain Minimum Compatibility Score will be allowed to apply. 
            The goal is to allow the Employer to limit the number of Candidates that apply, while also providing you with a highly qualified list of Candidates. 
            This will save you countless hours, resources, and money filtering through unqualified Candidates. 
            You can change this Minimum Compatibility Score at any time, depending on your organization or company’s needs. `}
          />
        }
      >
        <Slider min={0} max={100} step={0.25} marks={sliderMarks} />
      </Form.Item>

      <Form.Item>
        <Form.Item
          name="isSlam"
          label={
            <InfoLabel
              label="SLAM"
              tooltip={`Our Student Loan Assistance Management Program is an educational platform that can help subscribers reduce their payment, 
              eliminate their student loan debt, or simply educate them on how to properly manage their debt. 
              Our methods have been proven to help our subscribers reduce or eliminate their student loan debt at a much faster rate than the average debt holder. 
              As the Employer, including the S.L.A.M. Program in your hiring benefits package can be an appealing option for attracting the most qualified and sought-after candidates.
              If you are interested in signing up and providing the S.L.A.M. 
              Program to your hires or even your entire company, click “Yes” that you are interested, 
              answer the following questions, and upon completion of your JobCommander profile,
              you will receive an email with more information on the SLAM program. There is no cost to learn more about our S.L.A.M. Program platform!`}
            />
          }
          rules={[{ required: true }]}
          hasFeedback
          style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0' }}
        >
          <Select placeholder="SLAM Participation?">
            <Select.Option value={false}>No</Select.Option>
            <Select.Option value={true}>Yes</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="slamAmount"
          label={
            <InfoLabel
              label="SLAM Percentage Match?"
              tooltip={
                // eslint-disable-next-line max-len
                'How much are you willing to contribute in percentage match for this job description? This is not a binding percentage amount.'
              }
            />
          }
          rules={[{ required: enableSlam }, slamValidator() ]}
          hasFeedback
          style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 0 0 16px' }}
        >
          <InputNumber
            min={0}
            max={100}
            placeholder="Percentage Match"
            step={1}
            disabled={!enableSlam}
            addonAfter="%"
            // formatter={value => `${value}%`}
            // parser={value => value.replace('%', '') as any}
          />
        </Form.Item>
      </Form.Item>

      {!fromProfile && (
        <Form.Item
          name="questionTemplate"
          rules={[{ required: true }]}
          hasFeedback
          extra={
            showExtra === true
              ? 'Note: You will be asked to answer the questionnaire once you save this job description'
              : ''
          }
        >
          <Select
            placeholder="Copy Questionnaire Answers From ..."
            disabled={Boolean(itemData?.questionTemplate)}
            options={getJobOptions()}
          />
        </Form.Item>
      )}
    </Form>
  );
};

export { EditJobDetails };
const isNoneOrDoctorate = value => [ 5, 6 ].includes(value);
// If education option selected as none
const isNoneEducation = value => [ 6 ].includes(value);
