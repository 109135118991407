import { CoreModel } from '@job-commander/shared';
import { IProducts } from 'src/interfaces';

export class ProductModel extends CoreModel {
  productName: string = '';
  displayName: string = '';
  price: number = null;
  description: string = '';
  questionsToBuy: number = null;
  minimumHire: number = null;
  maximumHire: number = null;
  subscriptionDays: number = null;
  jobExpirationDate: any = null;

  constructor(data?: Partial<ProductModel>) {
    super(data);
    Object.assign(this, data);
  }

  /**
   * MAP DATA FROM API to UI MODEL
   */
  public static deserialize(data: IProducts) {
    return new ProductModel({ ...data, questionsToBuy: data.questionsToAdd || data.questionsToBuy });
  }

  /**
   * MAP LIST DATA FROM API to UI MODEL
   */
  public static deserializeList(data: IProducts[]) {
    if (!data || !Array.isArray(data)) {
      return [];
    }
    return data.map(x => ProductModel.deserialize(x));
  }
}
