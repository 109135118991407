import { SelectOptionModel } from '@job-commander/shared';
import { IJobDeletionOptions } from 'src/interfaces';

export class JobDeletionOptions extends SelectOptionModel {
  options: SelectOptionModel[] = [];

  constructor(data?: JobDeletionOptions) {
    super(data);
    Object.assign(this, data);
  }

  /**
   * MAP DATA FROM API to UI MODEL
   */
  public static deserialize(data: IJobDeletionOptions) {
    if (!data) {
      return new JobDeletionOptions();
    }

    return new JobDeletionOptions({
      ...(data as any),
      options: data?.options?.map(x => new SelectOptionModel(x)) || [],
    });
  }

  /**
   * MAP LIST DATA FROM API to UI MODEL
   */
  public static deserializeList(data: IJobDeletionOptions[]) {
    if (!data || !Array.isArray(data)) {
      return [];
    }
    return data.map(x => JobDeletionOptions.deserialize(x));
  }
}
