import React, { FC } from 'react';
import {  ViewPermission, Utilities, PAY_TYPE } from '@job-commander/shared';
import { Button, Modal } from 'antd';
import { useDialog } from '@surinderlohat/react-dialog';

type Props = {
  job?: any;
  onClose?: Function;
  hideCompany?: boolean;
};

const ViewJobDetails: FC<Props> = ({ job, hideCompany, onClose }) => {
  const dialog = useDialog();
  const getSalary = payType => {
    switch (parseInt(payType)) {
      case PAY_TYPE.SALARY:
        return `$${job.salary} per year`;
      case PAY_TYPE.HOURLY:
        return `$${job.salary} per hour`;
      case PAY_TYPE.NON_PAYING:
        return 'This job is non-paying';
    }
  };

  const getBenefits = benefits => {
    const selected = benefits?.map(benefit => benefit.name).join(' , ');
    if (!selected) {
      return 'None';
    }
    return selected;
  };

  return (
    <Modal
      title="Job Details"
      visible={true}
      maskClosable={false}
      destroyOnClose={true}
      onCancel={() => dialog.closeDialog()}
      footer={
        <Button key="ok-button" type="primary" onClick={() => dialog.closeDialog()}>
          Ok
        </Button>
      }
    >
      <div>
        <p>
          <strong>Job Title: </strong>
          {job?.title || job?.jobTitle}
        </p>
        <ViewPermission isVisible={!hideCompany}>
          <p>
            <strong>Company Name: </strong>
            {job?.companyName}
          </p>
        </ViewPermission>
        <p>
          <strong>Job City: </strong>
          {job?.city}
        </p>
        <p>
          <strong>Job State: </strong>
          {job?.state}
        </p>
        <p>
          <strong>Approx. Salary: </strong>
          {getSalary(job?.payType?.id || job?.payTypeId)}
        </p>
        <p>
          <strong>Job Benefits: </strong>
          {getBenefits(job?.benefitsWithLabels || job?.benefits)}
        </p>
        <div>
          <strong>Job Description: </strong>
          <span
            dangerouslySetInnerHTML={{
              __html: Utilities.parseStringToHtml(job?.description),
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

ViewJobDetails.defaultProps = {
  job: {},
  onClose: () => null,
};

export { ViewJobDetails };
