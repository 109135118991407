import React, { useState, useEffect, FC } from 'react';
import { Button, Badge, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBug, faCopy } from '@fortawesome/free-solid-svg-icons';
import ReactJson from 'react-json-view';
import './Debug.less';
import { useNotification } from '@job-commander/shared';

const DebugComponent: FC = () => {
  const { exceptions, setExceptions } = useNotification();

  const [ modalVisible, setModalVisible ] = useState(false);
  const [ current, setCurrent ] = useState(null);
  const [ copied, setCopied ] = useState(false);

  useEffect(() => {
    window.onerror = (message, source, line, column, error) => {
      setExceptions(exceptions => [
        {
          key: Date.now(),
          from: 'javascript',
          data: { message, source, line, column, error },
        },
        ...exceptions,
      ]);
    };

    window.onunhandledrejection = event => {
      setExceptions(exceptions => [
        {
          key: Date.now(),
          from: 'unhandled rejection',
          data: {
            message: event.reason.message,
            stack: event.reason.stack,
          },
        },
        ...exceptions,
      ]);
    };

    return () => {
      window.onerror = null;
      window.onunhandledrejection = null;
    };
  }, []);

  const copyText = async () => {
    const result = await navigator.permissions.query({ name: 'clipboard-write' } as any);
    if (result.state === 'granted') {
      try {
        await navigator.clipboard.writeText(JSON.stringify(current.data, null, 2));
        setCopied(true);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const showModal = () => {
    if (exceptions.length > 0 && modalVisible !== true) {
      setCurrent(exceptions[0]);
      setExceptions(exceptions => exceptions.slice(1));
      setCopied(false);

      setModalVisible(true);
    }
  };

  const goNext = () => {
    if (exceptions.length === 0) {
      setModalVisible(false);
      return;
    }

    setCurrent(exceptions[0]);
    setExceptions(exceptions => exceptions.slice(1));
    setCopied(false);
  };

  const getModalFooter = () => [
    <Button key="close-button" onClick={() => setModalVisible(false)}>
      Close
    </Button>,
    <Button key="ok-button" type="primary" onClick={goNext}>
      {exceptions.length === 0 ? 'Close' : 'Next'}
    </Button>,
  ];

  return (
    <>
      <div className="debug-wrapper">
        <Badge count={exceptions.length} size="small" offset={[ -6, 3 ]} showZero>
          <Button
            type="primary"
            shape="circle"
            size="large"
            icon={<FontAwesomeIcon icon={faBug} />}
            className="debug-button"
            onClick={showModal}
          />
        </Badge>
      </div>

      <Modal
        // id="debug-modal"
        title={current == null ? '' : current.from}
        visible={modalVisible}
        width={600}
        footer={getModalFooter()}
        onCancel={() => setModalVisible(false)}
        destroyOnClose
      >
        <>
          {copied === false ? (
            <Button className="copy-button" type="ghost" icon={<FontAwesomeIcon icon={faCopy} />} onClick={copyText}>
              Copy to Clipboard
            </Button>
          ) : (
            <div className="copy-button">Exception Copied!</div>
          )}
          {current != null && (
            <ReactJson src={current.data} enableClipboard={false} quotesOnKeys={false} displayDataTypes={false} />
          )}
        </>
      </Modal>
    </>
  );
};
export { DebugComponent };
