import { CoreModel, SelectOptionModel } from '@job-commander/shared';
import { IChatByIdResponse } from 'src/interfaces';
import { Interview } from './Interview.model';

export class ChatByIdResponseModel extends CoreModel {
  chatSequenceNumber: number;
  chatId: number;
  options: SelectOptionModel[];
  displayMessage: string;
  isReplied: boolean;
  userToShowMessage: SelectOptionModel;
  actionType: SelectOptionModel;
  interview: Interview;
  modifiedOn:string;
  createdOn:string;
  note:string;

  constructor(data?: Partial<ChatByIdResponseModel>) {
    super(data);
    Object.assign(this, data);
  }

  public static deserialize(data: IChatByIdResponse) {
    return new ChatByIdResponseModel({
      ...data,
      actionType: SelectOptionModel.deserialize(data?.actionType),
      userToShowMessage: SelectOptionModel.deserialize(data?.userToShowMessage),
      interview: Interview.deserialize(data?.interview),
      options: data?.options ? SelectOptionModel.deserializeList(data?.options) : [],
    });
  }

  public static deserializeList(data: IChatByIdResponse[]): IChatByIdResponse[] {
    if (!data || !Array.isArray(data)) {
      return [];
    }
    return data.map(x => ChatByIdResponseModel.deserialize(x));
  }
}
