import { CoreModel, SelectOptionModel } from '@job-commander/shared';
import { ICandidateSLAM } from 'src/interfaces/API-Candidate.interface';

export class CandidateSLAMModel extends CoreModel {
  debt: SelectOptionModel;
  interestRate: SelectOptionModel;
  loanServicer: SelectOptionModel;
  creditScore: SelectOptionModel;
  loanServicerOther: string;

  constructor(data?: Partial<CandidateSLAMModel>) {
    super(data);
    this.debt = data?.debt; //|| new SelectOptionModel();
    this.interestRate = data?.interestRate; //|| new SelectOptionModel();
    this.loanServicer = data?.loanServicer; //|| new SelectOptionModel();
    this.creditScore = data?.creditScore; //|| new SelectOptionModel();
    this.loanServicerOther = data?.loanServicerOther;
  }

  public static deserialize(response: ICandidateSLAM): CandidateSLAMModel {
    return new CandidateSLAMModel({
      ...response,
      debt: SelectOptionModel.deserialize(response?.debt),
      interestRate: SelectOptionModel.deserialize(response?.interestRate),
      loanServicer: SelectOptionModel.deserialize(response?.loanServicer),
      creditScore: SelectOptionModel.deserialize(response?.creditScore),
    });
  }

  public serialize(): ICandidateSLAM {
    return {
      id: this.id,
      debtId: this.debt?.value,
      interestRateId: this.interestRate?.value,
      loanServicerId: this.loanServicer?.value,
      creditScoreId: this.creditScore?.value,
      loanServicerOther: this.loanServicer?.value === 1 ?  this.loanServicerOther : null ,
    };
  }
}
