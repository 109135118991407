import { useNotification } from '../context/NotificationContext';

// Parameter is the boolean, with default "false" value
export const useAlert = () => {
  const notification = useNotification();

  const successAlert = (data: { message: string; description: string }) => {
    success(data.message, data.description);
  };

  const errorAlert = (data: { message: string; description: string }) => {
    error(data.message, data.description);
  };

  const success = (message: string, description?: string) => {
    notification.open({
      message,
      description,
      className: 'success-notification',
    });
  };

  const error = (message: string, description?: string) => {
    notification.open({
      message: message || 'Something Went Wrong!',
      description,
      className: 'error-notification',
    });
  };
  return { success, error, errorAlert, successAlert };
};
