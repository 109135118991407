import { IProducts } from '../../interfaces';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useLoader } from '@surinderlohat/react-hooks';
import { Form } from 'antd';
import React, { FC, useState } from 'react';
import { UpsertDialog, useAlert } from '@job-commander/shared';
import './BuyModal.less';
import { StripeCard } from './StripeCard/StripeCard';
import { usePaymentService } from 'src/api/PaymentService';

type Props = {
  emplJobIds: number[];
  totalNumberOfJobs: number; // number of candidates to hire
  selectedPlan: IProducts;
  onBuySuccess: Function;
  onCancel: Function;
  allowForFree?: boolean;
};

const BuyExtendJobPackage: FC<Props> = ({
  onBuySuccess,
  totalNumberOfJobs,
  onCancel,
  selectedPlan,
  emplJobIds,
  allowForFree,
}) => {
  const [ isCardValid, setIsCardValid ] = useState(true);
  const loader = useLoader(false);
  const stripe = useStripe();
  const elements = useElements();
  const alert = useAlert();
  const paymentService = usePaymentService();
  const onCardValueChange = e => {
    if (e.error != null || e.complete !== true) {
      setIsCardValid(false);
      return;
    }
    setIsCardValid(true);
  };

  const handleOnBuy = async () => {
    loader.showLoader();
    if (!selectedPlan) {
      alert.error('Somethings went wrong please connect JobCommander Team');
      return;
    }

    const request = {
      jobIds: emplJobIds,
      productId: selectedPlan?.id,
    };
    const checkoutResult = await paymentService.extendJobPosting(request);

    if (checkoutResult.hasError) {
      alert.error(checkoutResult.statusText);
      loader.hideLoader();
      return;
    }
    if (checkoutResult?.data?.isFirstHiringDone && !allowForFree) {
      const payload = await stripe.confirmCardPayment(checkoutResult.data?.paymentIntent.clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });

      if (payload.error) {
        alert.error(payload.error.message);
        loader.hideLoader();
      }
    }

    const result = await paymentService.confirmPayment(checkoutResult.data?.transactionId);

    if (result.hasError) {
      loader.hideLoader();
      return;
    }
    loader.hideLoader();
    alert.success('Thank You!');
    onBuySuccess();
  };

  return (
    <UpsertDialog
      title="Buy Package"
      width={600}
      onCancel={onCancel as any}
      onOk={handleOnBuy}
      isLoading={loader.isLoading}
    >
      <div>
        <p>
          <strong>Price/Job: </strong>${selectedPlan?.price}
        </p>
        <p>
          <strong>Valid For: </strong>
          {selectedPlan?.subscriptionDays} Days
        </p>
        <p>
          <strong>Total Number Of Jobs : </strong>
          {totalNumberOfJobs}
        </p>
        <p>
          <strong>Total Payment : </strong>
          <span style={{ textDecoration: allowForFree ? 'line-through' : 'none' }}>
            ${selectedPlan?.price * totalNumberOfJobs}
          </span>
          {allowForFree && <span style={{ color: 'red', marginLeft: '5px' }}>$0</span>}

          {/* <strong>Total Payment : </strong> ${selectedPlan?.price * totalNumberOfJobs} */}
        </p>
      </div>

      <Form id="buy-form" layout="horizontal" initialValues={{ quantity: 1 }}>
        <Form.Item name="card">
          <StripeCard onChange={onCardValueChange} isCardValid={isCardValid} />
        </Form.Item>
      </Form>
      {allowForFree && (
        <p>
          <strong>Note : </strong>
          Although we are collecting your card information, note that we will not charge you for extending these jobs.
          <br />
          This is a ${selectedPlan?.price} description savings per job description.
        </p>
      )}
    </UpsertDialog>
  );
};

export { BuyExtendJobPackage };
