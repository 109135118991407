import { OverlayLoader, useB2CClient, useQueryParams } from '@job-commander/shared';
import { useLoader } from '@surinderlohat/react-hooks';
import React, { FC, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import AddNewJobs from './AddNewJobs/AddNewJobs';
import DoNotExtendJobs from './DoNotExtendJobs/DoNotExtendJobs';
import ExtendJobs from './ExtendJobs/ExtendJobs';
import JobFilledQuestions from './JobFilledQuestions/JobFilledQuestions';

type Props = {
  location: {
    pathname: string;
  };
};

const AddExtendJobs: FC<Props> = ({ location }) => {
  const loader = useLoader(true);
  const { path } = useRouteMatch();
  const history = useHistory();
  const b2cClient = useB2CClient();
  const { email } = useQueryParams();
  useEffect(() => {
    if (!b2cClient.isAuthorized) {
      localStorage.setItem('redirect-url', `${history.location.pathname}${history.location.search}`);
      setTimeout(() => b2cClient.redirectForLoginSignup(false, { loginHint: email }), 100);
      return;
    }
    loader.hideLoader();
  }, []);

  return (
    <OverlayLoader isLoading={loader.isLoading} showChildren={!loader.isLoading}>
      <Switch>
        <Route exact path={`${path}/add-new-jobs`} component={AddNewJobs} />
        <Route exact path={`${path}/extend-jobs`} component={ExtendJobs} />
        <Route exact path={`${path}/do-not-extend-jobs`} component={DoNotExtendJobs} />
        <Route exact path={`${path}/job-filled-questions`} component={JobFilledQuestions} />
        <Redirect path="/*" to={'/a/matches'} />
      </Switch>
    </OverlayLoader>
  );
};

export default AddExtendJobs;
