/* eslint-disable max-len */
import React, { FC } from 'react'
import './Step3.less';
import JC_Lite from 'src/assets/images/JC_Lite.png';
import JC_Premium from 'src/assets/images/JC-Premium.png';

interface Step3Props {
    handlePackageSelect?: (type: string) => void;
  }

const Step3: FC<Step3Props> = ({ handlePackageSelect }) => {

  return (
    <div className="container">
      <div className="box">
        <img src={JC_Lite} alt="JC Lite" /> 
        <div className='title'>Free</div>
        <p className='subTitle'>For Employers and Hiring Managers</p>
        <button className='buttonLite' onClick={() => { handlePackageSelect('free'), sessionStorage.setItem('isSelectedPremium', 'false')}}>Find Candidates For Free</button>
        <div className='titledesc'><b>JobCommander Lite</b> provides Employers with basic compatibility data and matching algorithm.</div>
        <div className="boxWithLine"></div>
        <div className="bullet-list">
          <ul>
            <li>Limited data features and compatibility matching</li>
            <li>Publish up to 2 job descriptions on your Profile</li>
            <li>Receive up to 2 candidate applications per job description</li>
            <li>Invite up to 2 candidates to apply for your job description</li>
          </ul>
        </div>
        <div className="boxWithLine"></div>
        <div className="bullet-list no-bullet">
          <span className='lastSubTitle'>WHAT’S INCLUDED</span>
          <ul>
            <li>Limited candidate compatibility assessments</li>
            <li>Basic Application Tracking Software All-in-one Messaging, Scheduling, and Interviewing dashboard</li>
            <li>Custom Favoriting and Candidate Profile Tracking</li>
            <li>Candidate Search Functionality</li>
          </ul>
        </div>
      </div>
      <div className="box">
        <img src={JC_Premium} alt="JC Premium" />
        <div className='title'>For Employers & Hiring Managers</div>
        <p className='subTitle'>Posting + monthly subscription fees</p>
        <button className='buttonPremium' onClick={() => { handlePackageSelect('premium'), sessionStorage.setItem('isSelectedPremium', 'true') }}>Find Unlimited Candidates</button>
        <div className='titledesc'>With <b>JobCommander Premium</b>, gain access to thousands of compatible candidates, data, and hiring insights.</div>
        <div className="boxWithLine"></div>
        <div className="bullet-list">
          <ul>
            <li>Premium data features and compatibility matching</li>
            <li>Post unlimited job descriptions based on your pricing plan</li>
            <li>Unlimited Candidate applications for your job postings</li>
            <li>Invite an Unlimited number of Candidates matching your criteria</li>
          </ul>
        </div>
        <div className="boxWithLine"></div>
        <div className="bullet-list no-bullet">
          <span className='lastSubTitle'>WHAT’S INCLUDED</span>
          <ul>
            <li>Premium Candidate Compatibility Assessments</li>
            <li>Robust Application Tracking Software All-in-one Messaging, Scheduling, and Interview dashboard</li>
            <li>Custom Favoriting and Candidate Profile Tracking</li>
            <li>Premium Search Functionality</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Step3;