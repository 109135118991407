import React, { FC, useEffect } from 'react';
import { Row, Col, Radio, Modal } from 'antd';
import { OverlayLoader } from '@job-commander/shared';
import './BuySubscription.less';
import { IProducts } from '../../../interfaces';
import { PlanComponent } from 'src/components/Plan/Plan';
import { useAppStores } from 'src/store';
import { JobModel } from 'src/models/Job.model';
import { ProductModel } from 'src/models';

const colorMap = [ 'blue', 'green', 'orange', 'purple', 'steel', 'light-blue' ];

const monthTerms = [
  { label: '30 day', value: 30 },
  { label: '60 day', value: 60 },
];

type Props = {
  selectedPlan?: ProductModel;
  isPaymentDone?: boolean;
  planLength?: number; // 30 or 60 days
  jobs: JobModel[];
  onChangePlan?: (selectedPlan: ProductModel) => void;
  onChangePlanType?: (planLength: number) => void;
  allowFreeJobPost?: boolean;
};

const BuySubscription: FC<Props> = ({
  selectedPlan,
  isPaymentDone,
  jobs,
  planLength,
  onChangePlan,
  onChangePlanType,
  allowFreeJobPost,
}) => {
  const { productsStore } = useAppStores();

  // Load Products
  useEffect(() => {
    productsStore.loadProducts();
  }, []);


  // Show Suggestion to the user
  const isRecommended = (plan: ProductModel): boolean => {
    if (jobs.length === 0 && plan.id === 1) {
      return true;
    }
    const { minimumHire, maximumHire } = plan;
    const numberOfJobDiscription = jobs.length;
    if (minimumHire <= numberOfJobDiscription && maximumHire >= numberOfJobDiscription) return true;
    return false;
  };

  // Check if user can select a plan
  const isPlanDisabled = (plan: IProducts): boolean => {
    // if user already paid then he can't change the package
    if (isPaymentDone) {
      return true;
    }
    const { maximumHire } = plan;
    const numberOfJobDiscription = jobs.length;
    return numberOfJobDiscription > maximumHire;
  };

  // when user try to change plan
  const _onChangePlan = (plan: ProductModel): void => {
    if (isRecommended(plan)) {
      onChangePlan(plan);
      return;
    }
    const recommendedPlan = productsStore.products.subscriptionPackage.find(x => isRecommended(x));
    Modal.confirm({
      icon: '',
      content: (
        <div>
          Based on the number of job descriptions you have inputted at this point, we believe the{' '}
          <strong>{recommendedPlan?.displayName}</strong> plan is right for you. 
          To purchase a different subscription, you will need to fill in more job descriptions!
        </div>
      ),
    });
  };

  return (
    <div id="buy-pack-container">
      {/* <div id="radios"> comment code to remove 30-60 days model
        <h4>Plan Activation Length</h4>
        <Radio.Group
          options={monthTerms}
          value={planLength}
          disabled={isPaymentDone}
          onChange={e => onChangePlanType(e.target.value)}
          optionType="button"
          buttonStyle="solid"
        />
      </div> */}

      <OverlayLoader isLoading={productsStore.isLoadingProducts} showChildren={productsStore.isProductLoaded}>
        <Row gutter={[ 16, 16 ]}>
          {productsStore.products?.subscriptionPackage
            ?.filter(x => x.subscriptionDays === planLength)
            .map((p, i) => (
              <Col span={6} key={`plan-${p.id}`} offset={i % 3 === 0 ? 3 : 0}>
                <PlanComponent
                  plan={p}
                  color={colorMap[i] || null}
                  addPrice={planLength}
                  allowFreeJobPost={allowFreeJobPost}
                  disabled={isPlanDisabled(p)}
                  recommended={isRecommended(p)}
                  selected={selectedPlan?.id === p?.id}
                  onClick={e => _onChangePlan(p)}
                />
              </Col>
            ))}
        </Row>
      </OverlayLoader>
    </div>
  );
};

export { BuySubscription };
