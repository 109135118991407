import React, { FC, useMemo, useState } from 'react';
import { Step } from 'react-joyride';
import JoyRideTutorial from './JoyRideTutorial';

interface IJoyRideContext {
  isRunning: boolean;
  stepIndex: number;
  setStepIndex: Function;
  startRide: (index) => void;
  stopRide: () => void;
  next: () => void;
  prev: () => void;
}

export const JoyRideContext = React.createContext<IJoyRideContext>({
  isRunning: false,
  stepIndex: 0,
  setStepIndex: (index: number) => '',
  startRide: (index: number) => '',
  stopRide: () => '',
  prev: () => '',
  next: () => '',
});

type Props = {
  onCompleteTutorial:()=>{}
  children: any;
  steps: Step[];
  isTutorialCompleted:boolean
  isTutorialVisible:boolean
};

const JoyRideProvider: FC<Props> = ({ children, steps,onCompleteTutorial ,isTutorialCompleted,isTutorialVisible }) => {
  const [ stepIndex, setStepIndex ] = useState(0);
  const [ isRunning, setIsRunning ] = useState(false);

  const contextValue: IJoyRideContext = useMemo(() => {
    return {
      isRunning,
      stepIndex,
      setStepIndex: index => {
        localStorage.setItem('step-index', index);
        setStepIndex(index);
      },
      startRide: index => {
        if (isTutorialCompleted || !isTutorialVisible) {
          return;
        }
        setStepIndex(index);
        setIsRunning(true);
      },
      stopRide: () => setIsRunning(false),
      prev: () => setStepIndex(stepIndex - 1),
      next: () => setStepIndex(stepIndex + 1),
    };
  }, [ isRunning, stepIndex, isTutorialCompleted, isTutorialVisible, setStepIndex, setIsRunning ]);
  return (
    <JoyRideContext.Provider value={contextValue}>
      {children}
      <JoyRideTutorial steps={steps} onCompleteTutorial={onCompleteTutorial}/>
    </JoyRideContext.Provider>
  );
};

export { JoyRideProvider };
