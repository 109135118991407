import moment from 'moment';
import { ITimeSlots } from 'src/interfaces';
export class TimeSlot {
  id: string;
  name: string;
  value: string;
  isExpired: boolean;

  constructor(data?: ITimeSlots) {
    Object.assign(this, data);
  }

  public static deserialize(data: ITimeSlots) {
    return new TimeSlot({
      ...data,
      isExpired: moment().isAfter(moment(data.value, 'YYYY-MM-DDTHH:mm:ss')),
    });
  }
  public static deserializeList(data: ITimeSlots[]) {
    if (!data || !Array.isArray(data)) {
      return [];
    }
    return data.map(x => TimeSlot.deserialize(x));
  }
}
