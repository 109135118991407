import { CollegeModel } from './College.model';
import { CoreModel } from '@job-commander/shared';
import { ExtracurricularModel } from './Extracurricular.model';
import { HighSchoolModel } from './HighSchool.model';
import { JobPreferencesModel } from './JobPreferences.model';
import { SkillModel } from './Skill.model';
import { WorkExperienceModel } from './WorkExperience.model';
import { ICandidateResponse, ICandidateRequest } from 'src/interfaces/API-Candidate.interface';

export class CandidateModel extends CoreModel {
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  phone: string = '';
  city: string = '';
  state: string = '';
  zipCode: string = '';
  promoCode: string = '';
  address: string = '';

  superMatchCredits: number = 0;
  timeInSeconds: number = null;
  highSchools: HighSchoolModel[] = [];
  colleges: CollegeModel[] = [];
  workExperiences: WorkExperienceModel[] = [];
  extracurriculars: ExtracurricularModel[] = [];
  skills: SkillModel[] = [];
  answersSelected?: { [key: string]: string } = {};
  jobPreferences: JobPreferencesModel;

  constructor(data?: Partial<CandidateModel>) {
    super(data);
    Object.assign(this, data);
    this.highSchools = data?.highSchools?.map(x => new HighSchoolModel(x)) || [];
    this.colleges = data?.colleges?.map(x => new CollegeModel(x)) || [];
    this.workExperiences = data?.workExperiences?.map(x => new WorkExperienceModel(x)) || [];
    this.extracurriculars = data?.extracurriculars?.map(x => new ExtracurricularModel(x)) || [];
    this.skills = data?.skills?.map(x => new SkillModel(x)) || [];
    this.jobPreferences = data?.jobPreferences || new JobPreferencesModel();
    this.answersSelected = data?.answersSelected || {};
  }

  public static deserialize(response: ICandidateResponse): CandidateModel {
    return new CandidateModel({
      ...response,
      answersSelected: {},
      phone: response?.phone.replace(/ /g, '').slice(-10),
      highSchools: HighSchoolModel.deserializeList(response?.highSchools),
      colleges: CollegeModel.deserializeList(response?.colleges),
      workExperiences: WorkExperienceModel.deserializeList(response?.workExperiences),
      extracurriculars: ExtracurricularModel.deserializeList(response?.extracurriculars),
      skills: SkillModel.deserializeList(response?.skills),
      jobPreferences: JobPreferencesModel.deserialize(response?.jobPreferences),
    });
  }

  /**
   * serialize
   */
  public serializeSignup?(): ICandidateRequest {
    return {
      id: this.id,
      colleges: this.colleges.map(x => x.serialize()),
      highSchools: this.highSchools.map(x => x.serialize()),
      workExperiences: this.workExperiences.map(x => x.serialize()),
      extracurriculars: this.extracurriculars.map(x => x.serialize()),
      skills: this.skills.map(x => x.serialize()),
      answersSelected: Object.keys(this.answersSelected).map(key => ({ answerId: this.answersSelected[key] })),
      timeInSeconds: this.timeInSeconds,
      jobPreferences: this.jobPreferences.serialize(),
    };
  }

  /**
   * serialize
   */
  public serialize?(): ICandidateRequest {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      city: this.city,
      state: this.state,
      zipCode: this.zipCode,
      address: this.address,
      isCompleteSignUp: false,
      ...this.serializeSignup(),
    };
  }
}
