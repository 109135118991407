/* eslint-disable max-len */
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import React, { FC } from 'react';
import './OopsMessage.less';
import { BuyQuestionsOrSuperMatchButton } from '../BuyModal';
import { MatchesResultModel } from 'src/models/MatchesResult.model';

type Props = {
  isEmployer: boolean;
  job: MatchesResultModel;
  handleCancel: Function;
};

const OopsMessage: FC<Props> = ({ isEmployer, job, handleCancel }) => {
  const getMessage = () => {
    const range = job.minimumCompatibilityScore - job.totalCompatibilityScore;
    if (range <= 5) {
      return (
        <>
          {isEmployer
            ? 'Oops! Unfortunately, this candidate does not meet your Minimum Compatibility Score set for this position. If you would like to invite this candidate to apply, either answer more JobCommander Compatibility Questions, lower your Minimum Compatibility Score for this position, or Apply a SuperMatch'
            : 'Oops! Unfortunately, it looks like you do not meet the Minimum Compatibility Score set by this Employer. You can either answer more JobCommander Compatibility Questions to potentially help boost your scores OR Apply Your SuperMatch!'}
          <div className="buttons-container-root">
            <div className="buttons-container">
              <BuyQuestionsOrSuperMatchButton isSuperMatch={false} buttonTitle="Buy Questions" />
              <BuyQuestionsOrSuperMatchButton isSuperMatch={true} buttonTitle="Buy SuperMatches" />
            </div>
          </div>
        </>
      );
    }
    return (
      <>
        {isEmployer
          ? 'Oops! Unfortunately, this candidate does not meet your Minimum Compatibility Score set for this position. If you would like to invite this candidate to apply, either answer more JobCommander Compatibility Questions or lower your Minimum Compatibility Score for this position'
          : 'Oops! Unfortunately, it looks like you do not meet the Minimum Compatibility Score set by this Employer. You can answer more JobCommander Compatibility Questions and potentially boost your scores!'}
        <div className="buttons-container-root">
          <div className="buttons-container">
            <BuyQuestionsOrSuperMatchButton isSuperMatch={false} buttonTitle="Buy Questions" />
          </div>
        </div>
      </>
    );
  };
  return (
    <Modal
      visible={true}
      width={480}
      maskClosable={true}
      closable={true}
      footer={null}
      onCancel={handleCancel as any}
      className="oops-message"
    >
      <p>{getMessage()}</p>
      <div className="buttons-container-root">
        <div className="buttons-container">
          {/* <ViewPermission isVisible={job.buttonBuyQuestions}>
            <BuyQuestionsOrSuperMatchButton isSuperMatch={false} buttonTitle="Buy Questions" />
          </ViewPermission> */}

          {/* <ViewPermission isVisible={job.buttonBuySupermatch}>
            <BuyQuestionsOrSuperMatchButton isSuperMatch={true} buttonTitle="Buy SuperMatches" />
          </ViewPermission> */}
        </div>
      </div>
    </Modal>
  );
};

OopsMessage.defaultProps = {
  handleCancel: PropTypes.func,
};

export { OopsMessage };
