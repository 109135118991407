import { EDITOR_TYPES } from '../../../enums';

export const scaffolds = {
  education: [
    {
      title: 'Add High School',
      lines: [ 'Click here to add details about your High School education.' ],
      editorType: EDITOR_TYPES.HIGH_SCHOOL,
      required: false,
      isNew: true,
    },
    {
      title: 'Add University',
      lines: [ 'Click here to add details about your College education.' ],
      editorType: EDITOR_TYPES.COLLEGE,
      required: false,
      isNew: true,
    },
  ],

  experience: [
    {
      title: 'Add Work Experience',
      lines: [ 'Click here to add details about your work experience.' ],
      editorType: EDITOR_TYPES.EXPERIENCE,
      required: false,
      isNew: true,
    },
  ],

  extracurricular: [
    {
      title: 'Add Activity or Hobby',
      lines: [ 'Click here to add details about your activity or hobby' ],
      editorType: EDITOR_TYPES.EXTRACURRICULAR,
      required: false,
      isNew: true,
    },
  ],

  skills: [
    {
      title: 'Add Skill, Certificate or Qualification',
      lines: [ 'Click here to add details about your skill, certificate, or qualification' ],
      editorType: EDITOR_TYPES.SKILLS,
      required: false,
      isNew: true,
    },
  ],
};
