export class CountdownModel {
  startDate: Date;
  isTimeExpired: boolean;
  endDate: Date;
  hasError: boolean;
    
  constructor(p?: any) {
    if (!p) {
      return;
    }
    
    try {
      this.startDate = new Date(p.startDate);
      this.isTimeExpired = p.isTimeExpired;
      this.endDate = new Date(p.endDate);
      this.hasError = false; 
    } catch (error) {
      console.error('Error parsing CountdownModel:', error);
      this.hasError = true; 
    }
  }
    
  /**
       * MAP DATA FROM API to UI MODEL
       */
  public static deserialize(data: any) {
    return new CountdownModel({
      ...data,
      startDate: new Date(data.startDate),
      endDate: new Date(data.endDate),
    } as CountdownModel);
  }
}