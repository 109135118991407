import React, { FC, useEffect } from 'react';
import { useLoader } from '@surinderlohat/react-hooks';
import { useHistory } from 'react-router-dom';
import { OverlayLoader, useB2CClient } from '@job-commander/shared';
import { useAppStores } from 'src/store';
import { useDataService } from 'src/api';

type Props = {
  children: any;
  isLoginSignup?: boolean;
};

const SessionProvider: FC<Props> = ({ children }) => {
  const loader = useLoader(true);
  const history = useHistory();
  const b2cClient = useB2CClient();
  const dataService = useDataService();
  const { userStore } = useAppStores();

  useEffect(() => {
    if (!b2cClient.isAuthorized) {
      loader.hideLoader();
      return;
    }
    // fetch user profile and set int session
    dataService.getSessionData().then(session => {
      // If session not available then redirect to landing page
      if (session.hasError) {
        loader.hideLoader();
        history.replace('/');
        return;
      }
      // Setup user Profile Data
      userStore.updateUser(session.data);
      loader.hideLoader();
      userStore.getUnreadMsgCount();
    });
  }, []);

  return (
    <OverlayLoader isLoading={loader.isLoading} showChildren={!loader.isLoading}>
      {children}
    </OverlayLoader>
  );
};

export { SessionProvider };
