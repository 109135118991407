import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

/* istanbul ignore next */
export default class HttpClient {
  private httpClient: AxiosInstance;

  constructor(params?: AxiosRequestConfig) {
    this.httpClient = axios.create({ ...params });
    this.httpClient.interceptors.response.use(this.successHandler.bind(this), this.errorHandler.bind(this));
  }

  /**
   * withToken
   */
  public withToken(accessToken: string): this {
    this.httpClient.interceptors.request.use(
      config => {
        config.headers.Authorization = `bearer ${accessToken}`;
        return config;
      },
      error => Promise.reject(error)
    );
    return this;
  }

  public get(url: string, options?: any): Promise<any> {
    return this.httpClient.get(url, options);
  }

  public put<T = any>(url: string, body: Body, options?: any): Promise<T> {
    return this.httpClient.put(url, body, options);
  }

  public post<T = any>(url: string, body: any, options?: any): Promise<T> {
    return this.httpClient.post(url, body, options);
  }

  public delete<T = any>(url: string, body?: Body): Promise<T> {
    return this.httpClient.delete(url, { data: body });
  }

  private successHandler(response: AxiosResponse) {
    return response;
  }

  private async errorHandler(error: AxiosError): Promise<any> {
    if (!error.isAxiosError) {
      return error;
    }

    /* Parse Error from APi and return a Success Response with Error Messages.
    So that we Don't have to use try catch everywhere */
    const { data } = error.response;
    const message = data?.message;
    const errors = data?.errors || '';
    let errorMessage = Array.isArray(message) ? message.join(',') : message;
    if (Object(data).hasOwnProperty('errors')) {
      if (errors) {
        errorMessage = Object.keys(errors).length
          ? Object.keys(errors)
            .map(key => `${key}: ${errors[key]}`)
            .join(', ')
          : '';
      }
    }

    return Promise.resolve({
      hasError: true,
      error: true, // deprecated
      title: data.title,
      statusCode: data.statusCode,
      statusText: errorMessage,
      data,
    });
  }
}
