/* eslint-disable max-len */
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { faCheck, faCheckDouble,  faStar as faStarSolid, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Divider, Form, Modal, Row, Space, Tooltip, Typography } from 'antd';
import { OopsMessage, ColorProgress, ViewJobDetails, AnswerPsyQuestions, ValidateBuyQuestions } from '../../components';
import { useDialog } from '@surinderlohat/react-dialog';
import { useAlert } from '@job-commander/shared';
import { matchItemMessages } from './MatchItemMessages';
import React, { FC, useState } from 'react';
import { AnswerSuperMatchQuestions } from '../BuyModal/AnswerSuperMatchQuestions/AnswerSuperMatchQuestions';
import './MatchItem.less';
import { useAppStores } from 'src/store';
import { MatchesResultModel } from 'src/models/MatchesResult.model';
import { useLoader } from '@surinderlohat/react-hooks';
import { useDataService } from 'src/api';
import { Criteria } from 'src/constants';
import { Link } from 'react-router-dom';
import { StripeCard } from '../BuyModal/StripeCard/StripeCard';
import { JobWithNamesModel } from 'src/models/JobWithNames.model';
import { usePaymentService } from 'src/api/PaymentService';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

const { Title } = Typography;
const ignoreSkillKeys = [ 'EmplJobId', 'candidateId', 'benefits', 'supermatch', 'slamProgram' ];
type Props = {
  item: MatchesResultModel;
  showFavorite?: boolean;
  isEmployer?: boolean;
  superMatchCredits?: number;
  selectedJob?:  JobWithNamesModel;
  // When user applied super matches
  onItemUpdate?: (updatedItem: any) => void;
  // reload results
  onReloadResults?: () => void;
};

const MatchItem: FC<Props> = ({ item, showFavorite, isEmployer, onItemUpdate, onReloadResults, selectedJob }) => {
  const { userStore } = useAppStores();
  const alert = useAlert();
  const dialog = useDialog();
  const [ isFavorite, setIsFavorite ] = useState(item.isFavourite);
  const [ loadingFavorite, setLoadingFavorite ] = useState(false);
  const loader = useLoader(false);
  const dataService = useDataService();
  const [ isPaymentModalVisible ,setPaymentModalVisible ] = useState(false);
  const [ isCardValid, setIsCardValid ] = useState(true);
  const stripe = useStripe();
  const elements = useElements();
  const paymentService = usePaymentService();

  const onCardValueChange = e => {
    if (e.error != null || e.complete !== true) {
      setIsCardValid(false);
      return;
    }
    setIsCardValid(true);
  };

  const saveChanges = async () => {
    alert.success('Thank You!');
    onReloadResults();
  };

  const handleOnBuy = async (id) => {
    loader.showLoader();
    const request = { jobId: id };
    const checkoutResult = await paymentService.upgradePremiumPlan(request);

    if (checkoutResult.hasError) {
      alert.error(checkoutResult.statusText);
      loader.hideLoader();
      return;
    }

    const payload = await stripe.confirmCardPayment(checkoutResult.data?.paymentIntent.clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    if (payload.error) {
      alert.error(payload.error.message);
      loader.hideLoader();
      return;
    }

    const result = await paymentService.confirmPayment(checkoutResult.data?.transactionId);

    if (result.hasError) {
      loader.hideLoader();
      return;
    }
    loader.hideLoader();
    dialog.closeDialog();
    saveChanges();
  };

  const getSkillColumns = skills => {
    const keys = Object.keys(skills).filter(k => !ignoreSkillKeys.includes(k));
    return keys?.map(key => {
      const title = Criteria.find(k => k.key === key)?.title;
      return (
        <Col key={key} span={8}>
          <Space direction="horizontal">
            <FontAwesomeIcon icon={skills[key] === true ? faCheck : faXmark} />
            {title}
          </Space>
        </Col>
      );
    });
  };

  const toggleFavorite = async () => {
    const favorite = !isFavorite;
    loader.showLoader();
    setLoadingFavorite(true);
    const response = await dataService.toggleFavourite({
      employerJobId: item.employerJobId,
      candidateId: item.candidateId,
      isFavourite: favorite,
    });
    setLoadingFavorite(false);
    loader.hideLoader();
    if (response.hasError) {
      return;
    }
    onItemUpdate({ ...item, isFavourite: response });
    setIsFavorite(response);
  };

  // confirm user to apply for job
  const confirmApplyOrInvite = () => {
    //check if user have free invite available or not 
    if ( isEmployer && !item?.isFreeInviteAvailable ) {
      Modal.confirm({
        title: 'Confirm',
        content: 'If you would like to invite more Candidates to apply, please upgrade your service to JobCommander Premium and select the pricing plan that best suits your company’s needs. Note that there is no limit to the number of candidates you can invite to apply nor how many candidates can apply.',
        okText: 'Upgrade to premium',
        cancelText: 'Cancel',
        onOk: () =>  setPaymentModalVisible(true),
        onCancel: () => dialog.closeDialog(),
      },
      );
      return;
    }

    // if user has already applied then they can't apply or invite again
    if (item.isAppliedOrInvited) {
      Modal.info(matchItemMessages.alreadyInvitedMessage(userStore.isCandidate));
      return;
    }

    // if user need more score to apply then display oops message
    if (item.totalCompatibilityScore < item.minimumCompatibilityScore) {
      dialog.openDialog(<OopsMessage isEmployer={isEmployer} job={item} handleCancel={() => dialog.closeDialog()} />);
      return;
    }
    Modal.confirm({
      title: 'Confirm',
      content: matchItemMessages.inviteConfirmMessage(isEmployer),
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {
        const jobId = item.employerJobId;
        const candidateId = item.candidateId;
        const result = await dataService.applyOrInvite({ candidateId: candidateId, employerJobId: jobId });
        if (result.error) {
          return;
        }
        alert.success('Success!', matchItemMessages.inviteSuccessMessage(isEmployer, item));
        // Update
        onItemUpdate({ ...item, isAppliedOrInvited: true });
        onReloadResults();
      },
    });
  };

  // Apply super match process
  const confirmApplySuperMatch = async () => {
    if (item.totalCompatibilityScore >= item.minimumCompatibilityScore) {
      alert.success(matchItemMessages.alreadyEligibleForAppyOrInvite(userStore.isCandidate));
      return;
    }

    // if user can apply super match or not for this job
    if (item.isSupermatchApplied) {
      alert.error(matchItemMessages.alreadyAppliedSuperMatchMessage(userStore.isCandidate));
      return;
    }
    // if user has no super match credit then ask them to buy
    if (!userStore.superMatchCredits) {
      Modal.confirm({
        title: 'Confirm',
        content: matchItemMessages.userNotHavingSuperMatchMessage,
        okText: 'Yes',
        cancelText: 'No',
        onOk: () => buyMoreSuperMatchesOrQuestions(true),
      });
      return;
    }

    Modal.confirm({
      title: 'Confirm',
      content: matchItemMessages.userHavingSuperMatchMessage(isEmployer),
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => answer5MoreQuestions(),
    });
  };

  const superMatchTooltip = () => {
    if (item.isAppliedOrInvited) {
      return matchItemMessages.alreadyInvitedMessage(userStore.isCandidate).content;
    }
    if (item.isSupermatchApplied) {
      return matchItemMessages.alreadyAppliedSuperMatchMessage(userStore.isCandidate);
    }
    if (item.totalCompatibilityScore >= item.minimumCompatibilityScore) {
      return matchItemMessages.alreadyEligibleForAppyOrInvite(userStore.isCandidate);
    }
    if (item.minimumCompatibilityScore - item.totalCompatibilityScore > 5) {
      return matchItemMessages.notEligibleForSuperMatch(userStore.isCandidate);
    }
    return userStore?.superMatchCredits === 0 ? 'Buy SuperMatch Credits' : 'Apply SuperMatch';
  };

  const onSuccessSuperMatch = async () => {
    await userStore.syncCredits();
    dialog.closeDialog();
  };
  // Buy more super matched or questions
  const buyMoreSuperMatchesOrQuestions = isSuperMatch => {
    dialog.openDialog(
      <ValidateBuyQuestions
        isSuperMatch={isSuperMatch}
        onCancel={() => dialog.closeDialog()}
        onReloadResults={() => onReloadResults()}
        onSuccess={() => (isSuperMatch ? dialog.closeDialog() : answerBuyMoreQuestions())}
      />
    );
  };

  const answerBuyMoreQuestions = () => {
    dialog.openDialog(
      <AnswerPsyQuestions
        allowDirectSave={true}
        onDialogClose={isSuccess => {
          if (isSuccess) {
            onReloadResults();
            dialog.closeDialog();
          }
          dialog.closeDialog();
        }}
      />
    );
  };

  const answer5MoreQuestions = () => {
    dialog.openDialog(
      <AnswerSuperMatchQuestions
        employerJobId={item.employerJobId}
        candidateId={item.candidateId}
        employerJobTitle= {item?.title ? item?.title : item?.description}
        candidateName ={`${item.firstName} ${item.lastName}`}
        item = {item}
        isEmployer = {isEmployer}
        onItemUpdate = {onItemUpdate}
        onQuestionsResult={async isPass => {
          // Update
          onItemUpdate({ ...item, ['isSupermatchApplied']: true });
          // isAnswersMatches then ask for apply or invite
          // isPass && confirmApplyOrInvite();
          onSuccessSuperMatch();
          isPass && onReloadResults()
        }}
        onDialogClose={() => dialog.closeDialog()}
      />
    );
  };

  const applyOrInviteTooltip = () => {
    if (item.isAppliedOrInvited) {
      return matchItemMessages.alreadyInvitedMessage(userStore.isCandidate).content;
    }
    return isEmployer ? 'Invite to Apply' : 'Apply';
  };

  const titleDetails = () => {
    if (isEmployer) {
      return (
        <>
          <div>
            <Title level={4}>
              <Link to={{ pathname: '/a/view-profile', state: { candidateId: item.candidateId } }}>
                {item.firstName} {item.lastName}
              </Link>
            </Title>
          </div>
          <div className="subtitle">{item.title}</div>
        </>
      );
    }

    return (
      <>
        <div>
          <Title
            level={4}
            onClick={() => dialog.openDialog(<ViewJobDetails job={item} onClose={() => dialog.closeDialog()} />)}
          >
            {item.title}
          </Title>
        </div>
        <div className="subtitle">
          <Link to={{ pathname: '/a/view-profile', state: { employerId: item.employerId } }}>
            {item.companyName}
          </Link>
        </div>
      </>
    );
  };

  return (
    <div className={`job-match-item ${isEmployer ? 'candidate' : 'job'}`}>
      <div className="score-container">
        <ColorProgress percent={item.totalCompatibilityScore} />
      </div>

      <div className="details-container">
        <Space size="small" direction="vertical">
          <div className="title-container">
            <div className="title-details">{titleDetails()}</div>
            <Button.Group>
              <Button
                className={`joy-favorite-button favorite-button ${isFavorite ? 'checked' : ''}`}
                icon={<FontAwesomeIcon icon={isFavorite ? faStarSolid : faStar} />}
                loading={loadingFavorite}
                onClick={toggleFavorite}
              />
              <Tooltip placement="top" title={superMatchTooltip()}>
                <Button
                  className="joy-super-button"
                  icon={<FontAwesomeIcon icon={faCheckDouble} />}
                  onClick={confirmApplySuperMatch}
                  disabled={
                    Boolean(item.totalCompatibilityScore >= item.minimumCompatibilityScore) ||
                    item.isSupermatchApplied ||
                    item.minimumCompatibilityScore - item.totalCompatibilityScore > 5 || item.isAppliedOrInvited
                  }
                />
              </Tooltip>
              <Tooltip placement="top" title={applyOrInviteTooltip()}>
                <Button className="joy-apply-button" onClick={confirmApplyOrInvite} 
                  disabled={item.isAppliedOrInvited} 
                  style={{ backgroundColor: item.totalCompatibilityScore < item.minimumCompatibilityScore ? '#f5f5f5' : '' }}>
                  {isEmployer ? 'Invite to Apply' : 'Apply'}
                </Button>
              </Tooltip>
            </Button.Group>
          </div>
          <Divider>Skills Matched</Divider>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>{getSkillColumns(item.criteria)}</Row>
        </Space>
      </div>
      <Modal
        visible={isPaymentModalVisible}
        title={`Job Activation for ${selectedJob?.title}`}
        destroyOnClose={true}
        maskClosable={true}
        onOk={ () => handleOnBuy(selectedJob?.id)}
        onCancel={() => { setPaymentModalVisible(false); dialog.closeDialog() }}
        okButtonProps={{ loading: loader.isLoading }}
        cancelButtonProps={{ disabled: loader.isLoading }}
        okText="Buy"
      >
        <Form id="buy-form" layout="horizontal" >
          <Form.Item
            label="Total Payment : "
          >
            {'$199'} 
          </Form.Item>
          <Form.Item name="card">
            <StripeCard onChange={onCardValueChange} isCardValid={isCardValid} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export { MatchItem };
