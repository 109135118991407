import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLoader } from '@surinderlohat/react-hooks';
import { Form, Input, Modal, Typography } from 'antd';
import { useUserContext } from '../../../context';
import React, { FC } from 'react';
const { Title } = Typography;
import { generalMessage } from 'src/components/Messages';
import '../ProfileIcon.less';
import { useAlert } from '@job-commander/shared';

type Props = {
  handleClose?: () => void;
};

const UpdateEmail: FC<Props> = props => {
  const { httpClient } = useUserContext();
  const loader = useLoader(false);
  const [ form ] = Form.useForm();
  const alert = useAlert();

  const updateEmail = async () => {
    loader.showLoader();
    try {
      await form.validateFields();
      const formValues = { ...form.getFieldsValue() };
      const result = await httpClient.updateEmail('candidate', formValues);
      loader.hideLoader();
      if (result.hasError) {
        alert.error(generalMessage.somethingWentWrong, result.statusText);
        return;
      }
      // on success
      alert.success('Email Updated!', result.successMessage);
      props.handleClose();
    } catch (e) {
      loader.hideLoader();
    }
  };

  return (
    <Modal
      visible={true}
      width={300}
      destroyOnClose={true}
      maskClosable={true}
      closable={true}
      onCancel={props.handleClose}
      okText={'Submit'}
      onOk={updateEmail}
    >
      <Form id="change-email-form" name="loginForm" size="large" form={form}>
        <Title level={5}>Update Email</Title>
        <Form.Item
          name="email"
          rules={[{ required: true, type: 'email', message: 'The input is not valid E-mail!' }]}
          hasFeedback
        >
          <Input type="email" placeholder="Email Address" prefix={<FontAwesomeIcon icon={faEnvelope} />} />
        </Form.Item>
        <Form.Item name="firstName" rules={[{ required: true }]} hasFeedback>
          <Input type="text" placeholder="First Name" />
        </Form.Item>
        <Form.Item name="lastName" rules={[{ required: true }]} hasFeedback>
          <Input type="text" placeholder="Last Name" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

UpdateEmail.defaultProps = {
  handleClose: () => null,
};

export { UpdateEmail };
