import { CoreModel, SelectOptionModel } from '@job-commander/shared';
import { IResult } from 'src/interfaces';
import { CriteriaModel } from './Criteria.model';

export class MatchesResultModel extends CoreModel {
  candidateId: number;
  employerId: number;
  employerJobId: number;
  expirationDate: string;
  firstName: string;
  lastName: string;
  totalCompatibilityScore: number;
  minimumCompatibilityScore: number;
  isAppliedOrInvited: boolean;
  isSupermatchApplied: boolean;
  isQuestionApplied: boolean;
  title: string;
  companyName: string;
  cityState: string;
  city: string;
  state: string;
  description: string;
  payTypeId: number;
  isFavourite: boolean;
  salary: number;
  interviewScheduled: boolean;
  inviteOrAppyDate: string;
  benefits: SelectOptionModel[];
  criteria: CriteriaModel;
  isFreeInviteAvailable?: boolean;
  constructor(data?: Partial<MatchesResultModel>) {
    super(data);
    Object.assign(this, data);
  }

  public static deserialize(response: IResult): MatchesResultModel {
    return new MatchesResultModel({
      ...response,
      benefits: SelectOptionModel.deserializeList(response?.benefits),
      criteria: response.criteria || new CriteriaModel(),
    });
  }

  public static deserializeList(data: IResult[]): MatchesResultModel[] {
    if (!data || !Array.isArray(data)) {
      return [];
    }
    return data.map(x => MatchesResultModel.deserialize(x));
  }
}
