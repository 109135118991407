import { IJobWithName } from 'src/interfaces';
import { ScaffoldModel } from './Scaffold.model';

export class JobWithNamesModel extends ScaffoldModel {
  minimumCompatibilityScore: number;
  scoringState: string;
  isAllQuestionAnswered: boolean;
  answersSelected?: { [key: string]: string };
  copyAnswersFromJobId?: number;

  constructor(data?: Partial<JobWithNamesModel>) {
    super(data);
    Object.assign(this, data);
  }

  public static deserialize(response: IJobWithName): JobWithNamesModel {
    return new JobWithNamesModel({
      ...response,
      id: response.id,
      answersSelected: {},
    });
  }

  public get label(): string {
    return this.title;
  }

  public get value(): string {
    return this.tempId;
  }

  public static deserializeList(data: IJobWithName[]): JobWithNamesModel[] {
    if (!data || !Array.isArray(data)) {
      return [];
    }
    return data.map(x => JobWithNamesModel.deserialize(x));
  }
}
