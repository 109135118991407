import React, { FC, useEffect, useRef } from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS, Step } from 'react-joyride';
import { useHistory } from 'react-router-dom';
import { JOY_STEPS } from './Steps.enum';
import { useJoyRide } from './useJoyRide';
import { styles } from './styles';
import { ViewPermission } from '@job-commander/shared';

interface Props {
  steps: Step[];
  onCompleteTutorial: () => {};
}

export const JoyRideTutorial: FC<Props> = ({ steps, onCompleteTutorial }) => {
  const joyRide = useJoyRide();
  const history = useHistory();
  const pageRef = useRef(false);
  useEffect(() => {
    if (pageRef.current) {
      return;
    }
    const _index = parseInt(localStorage.getItem('step-index')) || 0;
    joyRide.startRide(_index);
    pageRef.current = true;
  }, []);

  const handleJoyrideCallback = async data => {
    const { action, index, status, type } = data;
    const nextIndex = index + (action === ACTIONS.PREV ? -1 : 1);
    if ([ EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND ].includes(type)) {
      // When user on first page
      if (nextIndex === 0) {
        joyRide.stopRide();
        // Note: Need this timeout to back navigation do not remove
        setTimeout(() => joyRide.startRide(0), 100);
        return;
      }

      if (action === ACTIONS.PREV) {
        setTimeout(() => joyRide.prev(), 100);
        return;
      }
      if (action === ACTIONS.NEXT) {
        setTimeout(() => joyRide.next(), 100);
        return;
      }
      return;
    }

    if ([ STATUS.FINISHED, STATUS.SKIPPED ].includes(status)) {
      onCompleteTutorial();
      // Need to set our running state to false, so we can restart if we click start again.
      joyRide.stopRide();
      return;
    }
    if (index === JOY_STEPS.COMPATIBILITY) {
      history.push('/a/compatibility');
      return;
    }
    if (action === ACTIONS.PREV && index === 8) {
      history.push('/a/matches');
    }
  };

  return (
    <ViewPermission isVisible={pageRef.current}>
      <Joyride
        run={joyRide.isRunning}
        stepIndex={joyRide.stepIndex}
        steps={steps}
        hideCloseButton={true}
        styles={styles}
        continuous
        showProgress
        locale={{ close: 'Next' }}
        callback={handleJoyrideCallback}
        disableOverlayClose={true}
      />
    </ViewPermission>
  );
};

export default JoyRideTutorial;
