import { IEducationOptions } from 'src/interfaces';
import { SelectOptionModel } from '@job-commander/shared';
import { IndustryModel } from './Industry.model';

export class OptionsModel {
  educationLevels: SelectOptionModel[] = [];
  degrees: SelectOptionModel[] = [];
  doctorate: SelectOptionModel[] = [];
  master: SelectOptionModel[] = [];
  gpAs: SelectOptionModel[] = [];
  // Job Related options
  yearsExperience: SelectOptionModel[];
  industries: IndustryModel[];
  numberOfEmployees: SelectOptionModel[];
  experienceInMonths: SelectOptionModel[] = [];
  experienceLevels: SelectOptionModel[] = [];
  benefits: SelectOptionModel[] = [];
  willingToTravels: SelectOptionModel[] = [];
  jobVenues: SelectOptionModel[] = [];
  payTypes: SelectOptionModel[] = [];
  workWeeks: SelectOptionModel[] = [];
  debts: SelectOptionModel[] = [];
  interestRates: SelectOptionModel[] = [];
  loanServicers: SelectOptionModel[] = [];
  creditScores: SelectOptionModel[] = [];

  constructor(p?: any) {
    if (!p) {
      return;
    }
  
    this.educationLevels = this.mapOptions(p.educationLevels);
    this.degrees = this.mapOptions(p.degrees);
    this.doctorate = this.mapOptions(p.doctorate);
    this.master = this.mapOptions(p.master);
    this.gpAs = this.mapOptions(p.gpAs);
  
    // Job Related Options
    this.yearsExperience = this.mapOptions(p.yearsExperience);
    this.industries = p.industries?.map(x => new IndustryModel(x)) || [];
    this.numberOfEmployees = this.mapOptions(p.numberOfEmployees);
    this.experienceInMonths = this.mapOptions(p.experienceInMonths);
    this.experienceLevels = this.mapOptions(p.experienceLevels);
    this.benefits = this.mapOptions(p.benefits);
    this.payTypes = this.mapOptions(p.payTypes);
    this.jobVenues = this.mapOptions(p.jobVenues);
    this.willingToTravels = this.mapOptions(p.willingToTravels);
    this.workWeeks = this.mapOptions(p.workWeeks);
    this.debts = this.mapOptions(p.debts);
    this.interestRates = this.mapOptions(p.interestRates);
    this.loanServicers = this.mapOptions(p.loanServicers);
    this.creditScores = this.mapOptions(p.creditScores);
  }
  
  private mapOptions(options: SelectOptionModel[] | undefined): SelectOptionModel[] {
    return options?.map(x => new SelectOptionModel(x)) || [];
  }
  

  /**
   * MAP DATA FROM API to UI MODEL
   */
  public static deserialize(data: IEducationOptions) {
    return new OptionsModel({
      ...data,
      industries: IndustryModel.deserializeList(data?.industries),
    } as OptionsModel);
  }
}
