import React, { FC, useEffect, useState } from 'react';
import { Modal, Select } from 'antd';
import { JobWithNamesModel } from 'src/models/JobWithNames.model';
import { useDataService } from 'src/api';
import { useLoader } from '@surinderlohat/react-hooks';
import { OverlayLoader } from '@job-commander/shared';

type Props = {
  questionType?: string;
  isSuperMatch?: boolean;
  onOkClick: (emplJobId: number) => void;
  onClose: () => void;
};

const JobDropDown: FC<Props> = props => {
  const [ employerJobs, setEmployerJobs ] = useState<JobWithNamesModel[]>([]);
  const [ selectedJob, setSelectedJob ] = useState<JobWithNamesModel>();
  const dataService = useDataService();
  const jobsLoader = useLoader(false);

  useEffect(() => {
    loadEmployerJobs();
  }, []);

  // Loading user employee profile
  const loadEmployerJobs = async () => {
    jobsLoader.showLoader();
    const result = await dataService.getJobsWithNames();
    jobsLoader.hideLoader();
    if (result.hasError) {
      return;
    }
    setEmployerJobs(result.data);
  };

  const onJobSelect = (_selectdJob: JobWithNamesModel) => {
    const job = employerJobs.find(job => job.value === _selectdJob.value);
    setSelectedJob(job);
  };
  return (
    <Modal
      visible={true}
      title="Select Job for buying questions"
      onOk={() => props.onOkClick(selectedJob?.id)}
      onCancel={props.onClose}
      okButtonProps={{ disabled: !selectedJob }}
    >
      <OverlayLoader isLoading={jobsLoader.isLoading}>
        <Select
          placeholder="Select Job Listing"
          onChange={onJobSelect}
          options={employerJobs}
          labelInValue
          size="large"
          style={{ display: 'block', margin: '0 auto' }}
        />
      </OverlayLoader>
    </Modal>
  );
};

export default JobDropDown;
