import { SelectOptionModel } from '@job-commander/shared';
import { IInterview } from 'src/interfaces';
import { TimeSlot } from './TimeSlot.model';

export class Interview {
  id: number;
  interviewType: SelectOptionModel;
  timeslots: TimeSlot[];
  chatMessageId: number;
  timeZone: SelectOptionModel;

  constructor(data?: Interview) {
    Object.assign(this, data);
  }

  public static deserialize(data: IInterview) {
    return new Interview({
      ...data,
      timeZone: SelectOptionModel.deserialize(data?.timeZone),
      timeslots: TimeSlot.deserializeList(data?.timeslots),
    });
  }
}
