export class CoreModel {
  id: number;
  name?: string;
  createdOn?: string;
  disabled?: boolean = false;
  isDeleted?: boolean = false;

  constructor(data?: Partial<CoreModel>) {
    Object.assign(this, data);
  }
}
