import { CoreModel } from '@job-commander/shared';
import { IEmployer, IOption } from 'src/interfaces';
import { DetailModel } from './Detail.model';
import { JobModel } from './Job.model';
import { ProductModel } from './Product.model';

export class EmployerModel extends CoreModel {
  address: string;
  city: string;
  companyName: string;
  hiringManager: string;
  state: string;
  phone: string;
  zipCode: string;
  isInterview: boolean;
  isReportcard: boolean;
  detail: DetailModel;
  jobs: JobModel[] = [];
  superMatchCredits?: number = 0;
  selectedPlan: ProductModel;
  isFirstHiringDone: boolean;
  totalFreeJobs?: number;
  isPremiumEmployer?: boolean;
  employerJobs?: IOption[];
  isPaymentFailedJobs?: boolean;
  productID ?: number;
  totalCardDeclineDues?: number;
  constructor(data?: Partial<EmployerModel>) {
    super(data);
    Object.assign(this, data);
    this.detail = data?.detail || new DetailModel();
    this.jobs = data?.jobs.map(x => new JobModel(x)) || [];
  }

  public static deserialize(response: IEmployer): EmployerModel {
    return new EmployerModel({
      ...response,
      detail: DetailModel.deserialize(response?.detail),
      jobs: JobModel.deserializeList(response?.jobs),
    });
  }

  public serialize?(): IEmployer {
    return {
      id: this.id,
      address: this.address || '',
      city: this.city || '',
      companyName: this.companyName || '',
      hiringManager: this.hiringManager || '',
      state: this.state || '',
      phone: this.phone.replace(/\D/gi, '') || '',
      zipCode: this.zipCode || '',
      detail: this.detail.serialize(),
    };
  }
  public serializeCompleteSignup?(): IEmployer {
    return {
      id: this.id,
      isReportcard: this.isReportcard,
      isInterview: this.isInterview,
      detail: this.detail.serialize(),
      jobs: [],
    };
  }
}
