import { CardElement } from '@stripe/react-stripe-js';
import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';

const cardStyle = {
  style: {
    base: {
      color: 'rgba(0, 0, 0, 0.85)',
      fontFamily:
        // eslint-disable-next-line max-len
        '-apple-system, BlinkMacSystemFont, \'Segoe UI\', Roboto, \'Helvetica Neue\', Arial, \'Noto Sans\', sans-serif, \'Apple Color Emoji\', \'Segoe UI Emoji\', \'Segoe UI Symbol\', \'Noto Color Emoji\'',
      fontSmoothing: 'antialiased',
      fontSize: '14px',
      '::placeholder': {
        color: '#BFBFBF',
      },
    },
    invalid: {
      color: '#a71d13',
      iconColor: '#a71d13',
    },
  },
};

type Props = {
  onChange: (event: StripeCardElementChangeEvent) => any;
  isCardValid: boolean;
};

const StripeCard: FC<Props> = ({ onChange, isCardValid }) => {
  const [ focused, setFocused ] = useState(false);
  const cardClass = classNames({ 'card-element-wrapper': true, focused: focused, error: !isCardValid });
  return (
    <div className={cardClass}>
      <CardElement
        id="card-element"
        options={{ ...cardStyle, hidePostalCode: true }}
        onChange={onChange}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
    </div>
  );
};
export { StripeCard };
