import { IProducts } from '../../../../../interfaces';
import { Radio } from 'antd';
import React, { FC } from 'react';
import './ExtendJobPayment.less';
import { OverlayLoader, ViewPermission } from '@job-commander/shared';

type Props = {
  isPaymentDone: boolean;
  planLength: number;
  selectedPlan: IProducts;
  onChangePlanType: (planLength: number) => void;
  numberOfJobsSelected: number;
  allowFreeJobExtend: boolean;
  plans: any;
  isLoading?: any;
};

const ExtendJobPayment: FC<Props> = ({
  isPaymentDone,
  numberOfJobsSelected,
  planLength,
  selectedPlan,
  onChangePlanType,
  allowFreeJobExtend,
  plans,
  isLoading,
}) => {
  const price = numberOfJobsSelected * selectedPlan?.price;

  return (
    <OverlayLoader isLoading={isLoading}>
      <div id="extend-pack-container">
        <div id="radios">
          <ViewPermission isVisible={Boolean(selectedPlan)}>
            <h4>${selectedPlan?.price} Per Job</h4>
          </ViewPermission>

          <Radio.Group
            options={plans}
            disabled={isPaymentDone}
            onChange={e => {
              onChangePlanType(e.target.value);
            }}
            optionType="button"
          />
        </div>
        <ViewPermission isVisible={Boolean(selectedPlan)}>
          {allowFreeJobExtend ? (
            <>
              Thank you for extending your job description! Because these jobs are posted prior to finding your first
              hire, we are extending your job description for free. This is a &nbsp;${price}&nbsp; description savings
              per job description. Thank you for working with JobCommander!
            </>
          ) : (
            <>
              You have selected <strong>{numberOfJobsSelected}</strong> jobs and needs to pay
              <strong>&nbsp;${price}&nbsp;</strong>
              after payment your selected jobs will be extended for <strong>
                {selectedPlan?.subscriptionDays}
              </strong>{' '}
              days
            </>
          )}
        </ViewPermission>
      </div>
    </OverlayLoader>
  );
};

export default ExtendJobPayment;
