import React, { useRef, FC } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { Modal, Form, Input } from 'antd';
import MaskedInput from 'antd-mask-input';
import './ContactUsModal.less';
import { maxChar, phoneNoValidator, useAlert } from '@job-commander/shared';
import { generalMessage } from '../Messages';
import { useLoader } from '@surinderlohat/react-hooks';
import { useDataService } from 'src/api';
import { IContactUs } from 'src/interfaces/API-ContactUs.interface';
const { TextArea } = Input;

type Props = {
  visible: boolean;
  onOk: Function;
  onCancel: Function;
};

const ContactUsModal: FC<Props> = ({ visible, onOk, onCancel }) => {
  const dataService = useDataService();

  const alert = useAlert();

  const formRef = useRef<any>();
  const loader = useLoader();

  const handleOk = async () => {
    await formRef.current?.validateFields();
    const values = formRef.current?.getFieldsValue();
    const request: IContactUs = {
      name: values?.name,
      email: values?.email,
      phone: values?.phoneNo.replace(/\D/gi, ''),
      message: values?.message,
    };
    loader.showLoader();
    const response = await dataService.contactUs(request);
    loader.hideLoader();
    if (response.hasError) {
      return;
    }

    alert.success('Thank You!', generalMessage.contactUs);
    onOk();
  };

  return (
    <Modal
      visible={visible}
      width={480}
      className="contact-us-modal"
      onOk={handleOk}
      onCancel={onCancel as any}
      destroyOnClose={true}
      title="Contact Us"
      okButtonProps={{ loading: loader.isLoading, disabled: loader.isLoading }}
      cancelButtonProps={{ disabled: loader.isLoading }}
    >
      <Form id="contact-form" name="contactForm" size="large" ref={formRef}>
        <Form.Item name="name" required hasFeedback rules={[{ required: true }, maxChar(70) ]}>
          <Input type="text" placeholder="Full Name" prefix={<FontAwesomeIcon icon={faUser} />} />
        </Form.Item>

        <Form.Item
          name="email"
          required
          hasFeedback
          rules={[{ required: true, type: 'email', message: 'The input is not valid E-mail!' }, maxChar(50) ]}
        >
          <Input type="email" placeholder="Email Address" prefix={<FontAwesomeIcon icon={faEnvelope} />} />
        </Form.Item>

        <Form.Item name="phoneNo" required hasFeedback rules={[{ required: true }, phoneNoValidator() ]}>
          <MaskedInput placeholder="Phone Number" mask="(000) 000-0000" prefix={<FontAwesomeIcon icon={faPhone} />} />
        </Form.Item>

        <Form.Item name="message" required hasFeedback rules={[{ required: true }, maxChar(1000) ]}>
          <TextArea placeholder="Message" rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

ContactUsModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export { ContactUsModal };
