import { Button, Carousel, Modal, Radio, Typography } from 'antd';
import moment from 'moment';
import React, { FC, useEffect, useRef, useState } from 'react';
import { OverlayLoader, QuestionModel } from '@job-commander/shared';
import './QuestionsDialog.less';
const { Title } = Typography;

type Props = {
  isLoading?: boolean;
  questions: QuestionModel[];
  onDialogClose?: Function;
  onSubmitQuestionnaire?: (answers: { [key: string]: number }, timeInSeconds: number) => void;
  isLocal?: boolean;
};

const QuestionsDialog: FC<Props> = ({ questions, onDialogClose, isLoading, isLocal, ...props }) => {
  const [ currentQuestion, setCurrentQuestion ] = useState(0);
  const [ answers, setAnswers ] = useState({});
  const [ startTime, setStartTime ] = useState<moment.Moment>();
  const sliderRef = useRef<any>();
  // set start time
  useEffect(() => setStartTime(moment()), []);

  const onNextQuestion = current => {
    setCurrentQuestion(current);
  };

  const onModalCancel = () => {
    Modal.confirm({
      title: 'Confirm',
      content:
        // eslint-disable-next-line max-len
        'If you close this window, all your work will be lost and you\'ll have to start over. Are you sure you want to continue?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => onDialogClose(),
    });
  };

  const onNextClick = currentQ => {
    // Auto Submit Questions on Local Machine
    if (isLocal) {
      const autoAnswers = questions.reduce((total, currentQuestion) => {
        return {
          ...total,
          [currentQuestion.id]: currentQuestion?.answers[0].id,
        };
      }, {});
      props.onSubmitQuestionnaire(autoAnswers, 3000);
      return;
    }

    if (currentQ === questions.length - 1) {
      const timeInSeconds = moment.duration(moment().diff(startTime)).asSeconds();
      props.onSubmitQuestionnaire(answers, timeInSeconds);
      return;
    }
    sliderRef?.current.next();
  };

  //**Save user answers */

  // set answer for selected question
  const setQuestionAnswer = (question, value) => {
    setAnswers(answers => ({ ...answers, [question.id]: value }));
  };

  const questionAnswered = question => (question == null ? false : answers[question.id] != null);

  return (
    <Modal
      title={`Question ${currentQuestion + 1} of ${questions.length}`}
      visible={true}
      maskClosable={false}
      onCancel={onModalCancel}
      footer={
        <Button
          key="submit"
          type="primary"
          onClick={() => onNextClick(currentQuestion)}
          disabled={!questionAnswered(questions[currentQuestion])}
        >
          {currentQuestion === questions.length - 1 ? 'Submit' : 'Next'}
        </Button>
      }
    >
      <OverlayLoader isLoading={isLoading}>
        <Carousel dots={false} ref={sliderRef} afterChange={onNextQuestion} swipe={false}>
          {questions.map((question, index) => (
            <div
              className={`question-container ${currentQuestion === index ? 'visible' : ''}`}
              key={question?.id}
            >
              <Title level={5}>{question.question}</Title>
              <Radio.Group value={answers[question.id]} onChange={e => setQuestionAnswer(question, e.target.value)}>
                {question.answers.map(answer => (
                  <Radio key={`answer-${answer.id}`} value={answer.id}>
                    {answer.answer}
                  </Radio>
                ))}
              </Radio.Group>
            </div>
          ))}
        </Carousel>
      </OverlayLoader>
    </Modal>
  );
};

QuestionsDialog.defaultProps = {
  onDialogClose: () => null,
  onSubmitQuestionnaire: (answers, timeInSeconds) => null,
};

export default QuestionsDialog;
