import { Utilities } from '@job-commander/shared';
import { useLoader } from '@surinderlohat/react-hooks';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useDataService } from 'src/api';
import { EmployerModel } from 'src/models/Employer.model';
import { JobModel } from 'src/models/Job.model';
import { ScaffoldModel } from 'src/models/Scaffold.model';
import { CandidateModel, ProductModel, UserModel } from '../models';
import process from 'process';

export interface IUserStore {
  user: UserModel;
  setUser: Function;
  employerJobs: JobModel[];
  setEmployerJobs: Function;
  isCandidate: boolean;
  isEmployer: boolean;
  isScoringCompleted: boolean;
  isDisplayCountdown: boolean;
  upsertData: (item: ScaffoldModel) => void;
  removeItem: (item: ScaffoldModel) => void;
  clearEmployerJobs: () => void;
  upsertEmployerJob: (item: ScaffoldModel) => void;
  removeEmployerJob: (item: ScaffoldModel) => void;
  setIsScoringStateCompleted: (scoringCompleted: boolean) => void;
  setIsDisplayCountdown: (isDisplayCountdown: boolean) => void;
  syncProfile: Function;
  syncCredits: Function;
  selectedPlan: ProductModel;
  setSelectedPlan: Function;
  profile: CandidateModel | EmployerModel;
  superMatchCredits: number;
  unreadMessageCount: number;
  getUnreadMsgCount: Function;
  setIsSignupCompleted: Function;
  setIsPhoneNumberVerified: Function;
  updateUser: Function;
  setIsTutorialCompleted: Function;
  isMatchesVisible: boolean;
  releaseDate: moment.Moment;
}

// User State Model
export function useUserStore(): IUserStore {

  const [ isDisplayCountdown, setIsDisplayCountdown ] = useState<boolean>(false);

  const loadCountdownDates = async (): Promise<any> => {
    const result = await dataService.countdownDates();
    !result.hasError && setIsDisplayCountdown(result?.isTimeExpired);    
  };

  useEffect(() => {
    // Call the function when the component mounts (page is reloaded)
    loadCountdownDates();
  }, []); 

  const [ selectedPlan, setSelectedPlan ] = useState<ProductModel>(null);
  const [ employerJobs, setEmployerJobs ] = useState([]);
  const [ user, setUser ] = useState(new UserModel());
  const [ isScoringCompleted, setIsScoringStateCompleted ] = useState(false);
  const dataService = useDataService();
  const [ profile, setProfile ] = useState<CandidateModel | EmployerModel>(null);
  const [ superMatchCredits, setSuperMatchCredits ] = useState<number>();
  const [ unreadMessageCount, setUnreadMessageCount ] = useState(null);
  const loader = useLoader();

  // CST To UST conversion of May 1st, 2023 at 9:30am EST
  const releaseDate = moment.utc('2023-05-01T13:30:00.000Z');
  
  const isProdEnv = JSON.parse(process.env.REACT_APP_IS_PROD_ENV || 'false');
  
  const isMatchesVisible = !isProdEnv || moment.utc().isAfter(releaseDate);

  // Add new  In List
  const upsertEmployerJob = (item: ScaffoldModel) => {
    setEmployerJobs(_jobs => Utilities.updateArrayItemV3(_jobs, item, 'tempId', true));
  };

  const removeEmployerJob = (item: ScaffoldModel) => {
    setEmployerJobs(_jobs => _jobs.filter(x => x.tempId !== item.tempId));
  };

  // Add new item In List
  const upsertData = (item: ScaffoldModel) => {
    setUser((prevUser) => {
      const updateUser = { ...prevUser };
      if (prevUser.isCandidate) {
        updateUser.candidateProfile = new CandidateModel({ ...prevUser.candidateProfile });
        updateUser.candidateProfile[item.editorType] = Utilities.updateArrayItemV3(
          prevUser.candidateProfile[item.editorType],
          item,
          'tempId',
          true
        );
      } else if (prevUser.isEmployer) {
        updateUser.employerProfile = new EmployerModel({ ...prevUser.employerProfile });
        updateUser.employerProfile[item.editorType] = Utilities.updateArrayItemV3(
          prevUser.employerProfile[item.editorType],
          item,
          'tempId',
          true
        );
      }
      return new UserModel(updateUser);
    });
  };

  const removeItem = (item: ScaffoldModel) => {
    setUser((prevUser) => {
      const updateUser = { ...prevUser };
      if (prevUser.isCandidate) {
        updateUser.candidateProfile = new CandidateModel({ ...prevUser.candidateProfile });
        updateUser.candidateProfile[item.editorType] = prevUser.candidateProfile[item.editorType]
          .filter((x) => x.tempId !== item.tempId);
      } else if (prevUser.isEmployer) {
        updateUser.employerProfile = new EmployerModel({ ...prevUser.employerProfile });
        updateUser.employerProfile[item.editorType] = prevUser.employerProfile[item.editorType]
          .filter((x) => x.tempId !== item.tempId);
      }
      return new UserModel(updateUser);
    });
  };

  // upsert Jobs

  // Sync user Profile
  const syncProfile = async (isCandidate: boolean) => {
    const response = await dataService.getProfile(isCandidate);
    if (!response.hasError) setProfile(response.data);
  };
  
  // Sync user Credits
  const syncCredits = async () => {
    const response = await dataService.getSuperMatchCredits();
    if (!response.hasError) {
      setSuperMatchCredits(response.data?.superMatchCredits);
      return response.data?.superMatchCredits;
    }
  };

  const getUnreadMsgCount = async () => {
    loader.showLoader();
    const result = await dataService.getUnseenMessageCount();
    loader.hideLoader();
    if (result.hasError) return;
    setUnreadMessageCount(result.data || 0);
  };

  const updateUserSession = (user: UserModel) =>
    sessionStorage.setItem('user-session', JSON.stringify(user.serialize()));

  const updateUserFlag = (flagName, value) => {
    setUser((_user) => {
      const updated = new UserModel({ ..._user, [flagName]: value });
      updateUserSession(updated);
      return updated;
    });
  };

  return {
    user,
    setUser,
    employerJobs,
    setEmployerJobs,
    upsertData,
    removeItem,
    upsertEmployerJob,
    removeEmployerJob,
    clearEmployerJobs: () => setEmployerJobs([]),
    syncProfile,
    syncCredits,
    isScoringCompleted,
    setIsScoringStateCompleted,
    isDisplayCountdown,
    setIsDisplayCountdown,
    selectedPlan,
    setSelectedPlan,
    isCandidate: user.isCandidate,
    isEmployer: user.isEmployer,
    profile,
    superMatchCredits,
    unreadMessageCount,
    getUnreadMsgCount,
    updateUser: _user => {
      updateUserSession(_user);
      setUser(_user);
    },
    setIsSignupCompleted: () => updateUserFlag('isSignupCompleted', true),
    setIsPhoneNumberVerified: () => updateUserFlag('isPhoneNumberVerified', true),
    setIsTutorialCompleted: () =>  updateUserFlag('isTutorialCompleted', true),
    isMatchesVisible,
    releaseDate,
  };
}