import { CoreModel, ISelectOption } from '@job-commander/shared';
import { APICountry } from 'src/interfaces';

export class CountryModel extends CoreModel implements ISelectOption {
  code: string;
  phoneCode: string;

  constructor(data?: CountryModel) {
    super(data);
    Object.assign(this, data);
  }

  /**
   * MAP DATA FROM API to UI MODEL
   */
  public static deserialize(data: APICountry) {
    if (!data) {
      return new CountryModel();
    }

    return new CountryModel({
      ...(data as any),
    });
  }

  /**
   * MAP LIST DATA FROM API to UI MODEL
   */
  public static deserializeList(data: APICountry[]) {
    if (!data || !Array.isArray(data)) {
      return [];
    }
    return data.map(x => CountryModel.deserialize(x));
  }

  public get value(): string {
    return this.phoneCode;
  }

  public get label(): string {
    return `${this.name}(${this.phoneCode})`;
  }
}
