import React, { useState, FC, useEffect, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import Api from '../api/api';
import User from '../models/User';
import { useQueryParams, Utilities } from '@job-commander/shared';

interface IUserContext {
  isEmployer: boolean;
  isLoading: boolean;
  user: any;
  httpClient: Api;
  isCandidate: boolean;
  unreadCount: number;
  isChoicesLoaded: boolean;
  options: any;
  scoreState?: {
    isPsycQuestionsCompleted?: boolean;
  };
  setIsLoading: (isLoading: boolean) => void;
  setUser: (isLoading: boolean) => void;
  setScoreState: (score: any) => void;
  setUnreadCount: (msgCount: number) => void;
  setIsChoicesLoaded: (isLoading: boolean) => void;
  setOptions: (options: any) => void;
  syncCredits: () => void;
  isScoringCompleted: boolean;
  setIsScoringStateCompleted: (scoringCompleted: boolean) => void;
  promoCode: string;
  // isScoringCompleted: () => boolean;
}

const UserContext = React.createContext<IUserContext>({
  scoreState: null,
  isLoading: false,
  user: {},
  httpClient: null,
  isCandidate: false,
  isEmployer: false,
  unreadCount: 0,
  isChoicesLoaded: false,
  options: {},
  setIsLoading: (isLoading: boolean) => {},
  setUser: (user: any) => {},
  setScoreState: (user: any) => {},
  setUnreadCount: (msgCount: number) => {},
  setIsChoicesLoaded: (isLoading: boolean) => {},
  setOptions: (options: any) => {},
  syncCredits: () => {},
  setIsScoringStateCompleted: (scoringCompleted: boolean) => {},
  isScoringCompleted: false,
  promoCode: '',
});

const UserProvider: FC<any> = ({ children, initialUser }) => {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ user, setUser ] = useState(initialUser);
  const [ scoreState, setScoreState ] = useState({});
  const [ unreadCount, setUnreadCount ] = useState(0);
  const [ isScoringCompleted, setIsScoringStateCompleted ] = useState(false);
  const [ isChoicesLoaded, setIsChoicesLoaded ] = useState(false);
  const [ promoCode, setPromoCode ] = useState('');
  const [ options, setOptions ] = useState({
    jobVenue: [],
    yearsExperience: [],
    WorkWeek: [],
    YesNo: [],
    travelTime: [],
    degreeLevel: [],
    Gpa: [],
    doctorate: [],
    degree: [],
    master: [],
    Benefits: [],
    payType: [],
    industry: [],
    creditScore: [],
    loanServicerName: [],
    studentLoan: [],
    interest: [],
    employeeCount: [],
    foundCandidate: [],
    jobDeletedReason: [],
  });
  const client = new Api(user);

  // query params from Search
  const queryParams: any = useQueryParams();

  const isCandidate = user.type === 'candidate';
  
  const isEmployer = user.type === 'employer';

  // Sync user profile whenever buy something
  const syncCredits = async () => {
    // after purchase refresh the user credit details
    const userProfile: any = await client.getProfile();

    if (userProfile.hasError) {
      console.error('Error While Getting Updated Profile!');
      return;
    }
    // Set Updated user profile
    user.syncCredits({
      ...userProfile.emplIdentity,
      ...userProfile.Identity,
      jobScoringState: userProfile.jobScoringState,
    });
    setIsScoringStateCompleted(Utilities.isEqual(user.jobScoringState, 'Complete'));
  };

  useEffect(() => {
    setPromoCode(queryParams?.promoCode);
  }, []);

  // client will removed in refactor deprecated use httpClient
  const contextValue = useMemo(() => ({
    user,
    isCandidate,
    isEmployer,
    setUser,
    client,
    httpClient: client,
    unreadCount,
    scoreState,
    isLoading,
    setIsLoading,
    setScoreState,
    setUnreadCount,
    isChoicesLoaded,
    setIsScoringStateCompleted,
    setIsChoicesLoaded,
    options,
    setOptions,
    syncCredits,
    isScoringCompleted,
    promoCode,
  }), [ user, isCandidate, isEmployer, setUser, client, 
    unreadCount, scoreState, isLoading, setIsLoading, setScoreState, setUnreadCount, 
    isChoicesLoaded, setIsScoringStateCompleted, setIsChoicesLoaded, options, setOptions, syncCredits, 
    isScoringCompleted, promoCode ]);

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};

UserProvider.propTypes = {
  initialUser: PropTypes.object,
};

UserProvider.defaultProps = {
  initialUser: User.load(),
};

const useUserContext = () => useContext(UserContext);

export { UserProvider, useUserContext };
