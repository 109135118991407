import { useLocation } from 'react-router-dom';

export const useQueryParams: any = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryParams: Record<string, string> = {};

  searchParams.forEach((value, key) => {
    queryParams[key] = value;
  });

  return queryParams;
};
