import React,{ FC } from 'react';
import { Space, Collapse } from 'antd';
import { faqList } from '@job-commander/shared';

const { Panel } = Collapse;

const FAQ:FC = () => (
  <Space direction="vertical">
    <Collapse collapsible="header">
      {faqList.map((faq) => (
        <Panel header={faq.title} key={faq?.title}>
          {faq.lines.map((line) => (
            <p key={line}>{line}</p>
          ))}
        </Panel>
      ))}
    </Collapse>
  </Space>
);

export { FAQ };
