import React, { useEffect, useState, FC } from 'react';
import { useAlert, QuestionsDialog } from '@job-commander/shared';
import { useLoader } from '@surinderlohat/react-hooks';
import { useDataService } from 'src/api';
import { useAppStores } from 'src/store';
import { IAnswers } from 'src/interfaces';
import { useHistory } from 'react-router-dom';

type Props = {
  employerJobId?: number;
  isFreeQuestions?: boolean;
  onDialogClose: (isSaveSuccess?: boolean) => void;
  onSubmitQuestionnaire?: (answers: any, timeInSeconds: number) => void;
  allowDirectSave?: boolean;
  redirectToMatchesPage?: boolean;
};

const AnswerPsyQuestions: FC<Props> = ({
  isFreeQuestions = false,
  allowDirectSave = false,
  employerJobId,
  ...props
}) => {
  const [ questions, setQuestions ] = useState([]);
  const { userStore } = useAppStores();
  const loader = useLoader();
  const alert = useAlert();
  const dataService = useDataService();
  const history = useHistory();
  
  useEffect(() => {
    loadQuestions();
  }, []);

  const loadQuestions = async (): Promise<any> => {
    loader.showLoader();
    const result = isFreeQuestions
      ? await dataService.getQuestions()
      : await dataService.getPaidQuestions(employerJobId, userStore.isCandidate);
    loader.hideLoader();
    if (result.hasError) {
      return;
    }
    setQuestions(result);
  };

  // allow direct Save Of Questions
  const onSubmitQuestionnaire = async (answers: { [key: string]: number }, timeInSeconds: number) => {
    // If not Saving Questions Directly
    if (!allowDirectSave) {
      props.onSubmitQuestionnaire(answers, timeInSeconds);
      return;
    }

    loader.showLoader();
    const requestEmp: IAnswers = {
      answers: Object.keys(answers).map(key => ({ answerId: answers[key] })),
      employerJobId,
      timeInSeconds,
    };

    const result = await dataService.saveQuestionsAnswers(requestEmp, userStore.isCandidate);

    loader.hideLoader();
    if (result.hasError) {
      return;
    }
    if (props.redirectToMatchesPage) {
      setTimeout(() => history.replace('/a/matches'), 500);
    }
    alert.success('Thank You! Your answers saved successfully');
    props.onDialogClose(true);
  };

  return (
    <QuestionsDialog
      isLoading={loader.isLoading}
      questions={questions}
      onDialogClose={props.onDialogClose}
      onSubmitQuestionnaire={onSubmitQuestionnaire}
    />
  );
};

AnswerPsyQuestions.defaultProps = {
  onDialogClose: () => null,
};

export default AnswerPsyQuestions;
