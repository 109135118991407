import React, { FC } from 'react';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import './InfoLabel.less';

type Props = {
  label: string;
  tooltip: string;
};

const InfoLabel: FC<Props> = ({ label, tooltip }) => {
  return (
    <div className="tooltip-root">
      <>{label}</>
      <Tooltip title={tooltip || ''}>
        <InfoCircleOutlined />
      </Tooltip>
    </div>
  );
};

InfoLabel.defaultProps = {
  label: '',
  tooltip: '',
};

export { InfoLabel };
