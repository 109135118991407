import React, { FC, useEffect } from 'react';
import { useLoader } from '@surinderlohat/react-hooks';
import { useHistory } from 'react-router-dom';
import { OverlayLoader, useB2CClient } from '@job-commander/shared';
import { useAppStores } from 'src/store';

type Props = {
  children: any;
  isLoginSignup?: boolean;
};

const Authorization: FC<Props> = ({ children, isLoginSignup }) => {
  const loader = useLoader(true);
  const history = useHistory();
  const b2cClient = useB2CClient();
  const { userStore } = useAppStores();

  useEffect(() => {
    if (!b2cClient.isAuthorized) {
      // If login Signup page then allow loading current page else redirect to landing page
      if (isLoginSignup) {
        loader.hideLoader();
        return;
      }
      // will redirect to landing page if user not authorized
      history.replace('/');
      return;
    }
    // If signup not completed then ask to complete Signup
    if (!userStore.user.isSignupCompleted) {
      history.replace('/complete-signup');
      return;
    }

    // If signup not completed then ask to complete Signup
    if (!userStore.user.isPhoneNumberVerified) {
      history.replace('/verify-phone');
      return;
    }
    loader.hideLoader();
  }, []);

  return <OverlayLoader showChildren={!loader.isLoading}>{children}</OverlayLoader>;
};

export { Authorization };
