import { faEnvelope, faEye, faEyeSlash, faLock, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Input, Modal, Typography } from 'antd';
import MaskedInput from 'antd-mask-input';
import { useUserContext } from '../../../context';
import React, { FC } from 'react';
import { useLoader } from '@surinderlohat/react-hooks';
const { Title } = Typography;
import '../ProfileIcon.less';
import { generalMessage } from 'src/components/Messages';
import { useAlert } from '@job-commander/shared';

type Props = {
  handleClose: Function;
};

const ChangePassword: FC<Props> = props => {
  const loader = useLoader(false);
  const { user, httpClient, isCandidate } = useUserContext();
  const [ form ] = Form.useForm();
  const alert = useAlert();

  const setNewPassword = async () => {
    loader.showLoader();
    try {
      await form.validateFields();
      const formValues = { ...form.getFieldsValue(), pwConfirm: undefined };
      const result = await httpClient.updatePassword(user.type, formValues);
      loader.hideLoader();
      if (result.hasError) {
        alert.error(generalMessage.somethingWentWrong, result.statusText);
        return;
      }
      // on success
      alert.success('Password Updated!', result.successMessage);
      props.handleClose();
    } catch (e) {
      loader.hideLoader();
    }
  };

  const renderPasswordIcon = (visible) => {
    return visible ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />;
  };

  return (
    <Modal
      visible={true}
      width={300}
      destroyOnClose={true}
      maskClosable={true}
      closable={true}
      onCancel={props.handleClose as any}
      okText={'Submit'}
      onOk={setNewPassword as any}
    >
      <Form id="change-password-form" name="loginForm" size="large" form={form}>
        <Title level={5}>Change Password</Title>
        <Form.Item name={user.type === 'candidate' ? 'phoneNo' : 'email'} rules={[{ required: true }]} hasFeedback>
          {isCandidate ? (
            <MaskedInput
              placeholder="Phone Number"
              mask="(000) 000-0000"
              autoComplete="off"
              prefix={<FontAwesomeIcon icon={faPhone} />}
            />
          ) : (
            <Input type="email" placeholder="Email Address" prefix={<FontAwesomeIcon icon={faEnvelope} />} />
          )}
        </Form.Item>
        <Form.Item name="currentPw" rules={[{ required: true, min: 7 }]} hasFeedback>
          <Input.Password
            placeholder="Current Password"
            autoComplete="off"
            prefix={<FontAwesomeIcon icon={faLock} />}
            iconRender={renderPasswordIcon}
          />
        </Form.Item>

        <Form.Item name="newPw" rules={[{ required: true, min: 7 }]} hasFeedback>
          <Input.Password
            placeholder="New Password"
            autoComplete="off"
            prefix={<FontAwesomeIcon icon={faLock} />}
            iconRender={renderPasswordIcon}
          />
        </Form.Item>

        <Form.Item
          name="pwConfirm"
          rules={[
            { required: true, min: 7 },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPw') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Passwords do not match!'));
              },
            }),
          ]}
          hasFeedback
          dependencies={[ 'newPw' ]}
        >
          <Input.Password
            placeholder="Confirm Password"
            autoComplete="off"
            prefix={<FontAwesomeIcon icon={faLock} />}
            iconRender={renderPasswordIcon}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export { ChangePassword };
