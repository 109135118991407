import { Form, InputNumber } from 'antd';
import React, { FC } from 'react';

type Props = {
  product: any;
  quantity: number;
  onQuantityChange: Function;
};

const BuySuperMatch: FC<Props> = ({ product, quantity, onQuantityChange }) => {
  const getPrice = () => `$${(product.price * quantity).toFixed(2)}`;

  return (
    <>
      <Form.Item>
        <Form.Item
          name="quantity"
          label="Quantity"
          style={{ display: 'inline-flex', width: 'calc(50% - 8px)', margin: 0 }}
        >
          <InputNumber
            min={1}
            max={product.maxQuantity > -1 ? product.maxQuantity : 10000}
            style={{ width: '100%' }}
            onChange={e => onQuantityChange(e)}
          />
        </Form.Item>

        <Form.Item
          name="total"
          label="Total Price"
          style={{ display: 'inline-flex', width: 'calc(50% - 8px)', margin: '0 0 0 16px' }}
        >
          <div className="price-text">{getPrice()}</div>
        </Form.Item>
      </Form.Item>
    </>
  );
};

export { BuySuperMatch };
