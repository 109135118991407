import React, { FC, useContext, useMemo } from 'react';
import { IAppStore, useAppStore } from './AppStore';
import { IOptionsStore, useOptionsStore } from './OptionsStore';
import { IProductsStore, useProductsStore } from './ProductsStore';
import { IUserStore, useUserStore } from './UserStore';

interface IUserContext {
  userStore: IUserStore;
  optionsStore: IOptionsStore;
  appStore: IAppStore;
  productsStore: IProductsStore;
}

const AppStoreContext = React.createContext<IUserContext>({
  userStore: null,
  optionsStore: null,
  appStore: null,
  productsStore: null,
});

const AppStoresProvider: FC<any> = ({ children }) => {
  const userStore = useUserStore();
  const optionsStore = useOptionsStore();
  const appStore = useAppStore();
  const productsStore = useProductsStore();

  const contextValue = useMemo(() => {
    return {
      optionsStore,
      userStore,
      appStore,
      productsStore,
    };
  }, [ optionsStore, userStore, appStore, productsStore ]);

  return (
    <AppStoreContext.Provider value={contextValue}>
      {children}
    </AppStoreContext.Provider>
  );
};

const useAppStores = () => useContext(AppStoreContext);

export { AppStoresProvider, useAppStores };
