import { Progress } from 'antd';
import React, { FC } from 'react';

const getProgressColors = value => {
  if (value >= 50) {
    return {
      '0%': '#294a93',
      '100%': '#13a73d',
    };
  }

  return {
    '0%': '#fb7617',
    '100%': '#fbe817',
  };
};

type Props = {
  percent: number;
};

const ColorProgress: FC<Props> = ({ percent }) => {
  return <Progress type="circle" strokeColor={getProgressColors(percent || 0)} percent={Math.round(percent || 0)} />;
};

export { ColorProgress };
