export const apiUrls = {
  login: '/api/Auth/Login',
  signupCandidate: '/api/Auth/CandidateSignup',
  employerSignup: '/api/Auth/EmployerSignup',

  // Master Data New v2 Urls
  educationOptions: '/api/MasterData/EducationOptions',
  jobOptions: '/api/MasterData/JobOptions',
  colleges: '/api/MasterData/Colleges',
  industries: '/api/MasterData/Industries',
  getQuestions: '/api/MasterData/Questions',
  getCountries: '/api/MasterData/Country',
  getInterviewOptions:'/api/MasterData/InterviewType',
  getTimezoneOptions:'/api/MasterData/Timezone',

  // Employer
  employerProfile: '/api/Employer/Profile',
  getProducts: '/api/MasterData/Products',
  employerJobs: '/api/Employer/Jobs',
  expiredJobs: '/api/Employer/ExpiredJobs',
  deleteJob: '/api/Employer/EmployerJob',
  deleteJobFromEmail: '/api/Employer/DoNotExtendJob',
  jobNames: '/api/Employer/Job/Status',
  adjustCompatibility: '/api/Employer/CompatibilityScore',
  removeJobQuestions: '/api/MasterData/JobDeletionReason',
  employerQuestionCount: '/api/Employer/QuestionCount',
  employerSaveAnswers: '/api/Employer/Answers/Paid',
  extendJobPosting: '/api/Payment/CheckOut/ExtendJob',
  oneTimeExtendJob: '/api/Payment/CheckOut/OneTimeExtendJob',
  upgradePremiumPlan: '/api/Payment/CheckOut/UpgradePremiumPlan',
  activeJobPosting: '/api/Payment/CheckOut/ActivateJob',
  employerDeActivate: '/api/Employer/DeActivate',
  employerDelete: '/api/Employer/Account',
  checkEmployerAccountDelete: '/api/Employer/CheckEmployerAccountDelete',
  archivedJobsPayment: 'api/payment/CheckOut/ArchivedJobsPayment',
  pendingDueJobs: 'api/employer/ArchivedJobs',
  cardUpdate: 'api/Payment/CheckOut/UpdateCard',
  cardUpdateWithPayment: 'api/Payment/CheckOut/CardUpdateWithPayment',

  // Candidate
  completeProfile: '/api/Candidate/Profile',
  candidateQuestionCount: '/api/Candidate/QuestionCount',
  candidateSaveAnswers: '/api/Candidate/Answers/Paid',
  candidateDeActivate: '/api/Candidate/DeActivate',
  candidateDelete: '/api/Candidate/Account',
  sendCode: '/api/Candidate/Profile/SendPhoneOtp',
  verifyCode: '/api/Candidate/Profile/VerifyPhoneOtp',
  candidateNames: '/api/Candidate',

  // Payments
  paymentCheckout: '/api/Payment/Checkout',
  paymentCheckoutJobs: '/api/Payment/Checkout/Jobs',
  paymentConfirm: '/api/Payment/Confirm',

  //Common
  toggleFavourite: '/api/Dashboard/Favourite',
  favourites: '/api/Dashboard/Favourites',
  applyOrInvite: '/api/DashBoard/InviteOrApply',
  candidateMatches: '/api/Dashboard/Candidate/Matches',
  candidateSearchJobs: '/api/Dashboard/Candidate/Search/Job',
  employerMatches: '/api/Dashboard/Employer/Matches',
  employerSearchCandidates: '/api/Dashboard/Employer/Search/Job',
  candidateViewEmployerJobs: '/api/Dashboard/Candidate/Matches',
  paidQuestions: '/api/MasterData/Questions/Paid',
  superMatchQuestions: '/api/DashBoard/Questions',
  saveSuperMatchAnswersCandidate: '/api/DashBoard/Candidate/SuperMatch',
  saveSuperMatchAnswersEmployer: '/api/DashBoard/Employer/SuperMatch',
  getQuestionCount: '/api/DashBoard/Question/Count',
  superMatchCredits: '/api/DashBoard/superMatch/Credits',
  userClaims: '/api/DashBoard/UserClaims',
  setTutorialDone :'/api/DashBoard/Tutorial',
  contactUs :'/api/DashBoard/ContactUs',
  countdownDates : '/api/MasterData/BannerDates',
  //Messaging
  getChats: '/api/Chat',
  sendChatReply: '/api/Chat/Reply',
  sendScheduleReply: '/api/Chat/Interview',
  sendAdditionalInfo: '/api/Chat/Interview/AdditionalInformation',
  editNote: '/api/Chat/Note',
  sendReason: '/api/Chat/Reason',
  unSeenMessage: 'api/Chat/UnseenMessages',
  trackLastSeen: 'api/Chat/MarkAsSeen',
};
