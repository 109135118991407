export default class User {
  firstName = '';
  lastName = '';
  type = '';
  loggedIn = false;
  signUpDone = false;
  verified = false;
  signUp = {};
  accessToken = null;
  refreshToken = null;
  superMatchCredits = 0;
  jobScoringState = '';

  // Sync user Credits ()
  syncCredits(data) {
    this.superMatchCredits = parseInt(data.supermatchCredits || '0');
    this.jobScoringState = data.jobScoringState;
    this.save();
    return this;
  }

  copyFrom(data) {
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.type = data.type;
    this.accessToken = data.results.accessToken;
    this.refreshToken = data.results.refreshToken;
    this.loggedIn = true;
    this.superMatchCredits = parseInt(data.supermatchCredits || '0');
    if (this.type === 'employer') {
      this.signUpDone = data.numJobsLoaded != null && data.numJobsLoaded > 0;
    } else {
      this.signUpDone = data.compatUpdated != null && data.compatUpdated !== 'No';
    }

    this.save();
    return this;
  }

  save() {
    localStorage.setItem('refreshToken', this.refreshToken);
    localStorage.setItem('accessToken', this.accessToken);
    localStorage.setItem('userState', JSON.stringify(this));
  }

  clear() {
    this.accessToken = null;
    this.refreshToken = null;
    this.loggedIn = false;

    localStorage.setItem('refreshToken', null);
    localStorage.setItem('accessToken', null);
    localStorage.setItem('userState', null);
  }

  static load = () => {
    const user = new User();

    let storedState: any = localStorage.getItem('userState');
    if (storedState != null && storedState != 'null') {
      storedState = JSON.parse(storedState) || {};

      user.firstName = storedState.firstName;
      user.lastName = storedState.lastName;
      user.type = storedState.type || 'candidate';
      user.loggedIn = storedState.loggedIn;
      user.signUpDone = storedState.signUpDone;
      user.verified = storedState.verified;
      user.signUp = storedState.signUp;
      user.accessToken = storedState.accessToken;
      user.refreshToken = storedState.refreshToken;
      user.superMatchCredits = storedState.superMatchCredits || 0;
      user.jobScoringState = storedState.jobScoringState;
    }

    return user;
  };
}
