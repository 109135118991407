import { useState } from 'react';

export interface IAppStore {
  title: string;
  icon: string;
  setTitle: Function;
  setIcon: Function;
}

// User State Model
export function useAppStore(): IAppStore {
  const [ title, setTitle ] = useState(null);
  const [ icon, setIcon ] = useState(null);
  return { title, setTitle, icon, setIcon };
}
