import { CoreModel } from '@job-commander/shared';
import { IChats } from 'src/interfaces';

export class ChatModel extends CoreModel {
  job: {
    id: 0;
    title: string;
    employerId: number;
    employer: {
      id: number;
      address: string;
      email: string;
      city: string;
      state: string;
      companyName: string;
      hiringManager: string;
      isThirdPartyRecruiter: boolean;
      phone: string;
      isFirstHiringDone: boolean;
    };
  };
  candidate: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  };
  isChatActive: boolean;
  isCandidateHired: true;
  lastMessageOn: string;
  unseenMessagesCount: number;
  isAnyParticipantDeleted: boolean;

  constructor(data?: Partial<ChatModel>) {
    super(data);
    Object.assign(this, data);
  }

  public static deserialize(data: IChats) {
    if (!data) {
      return new ChatModel();
    }

    return new ChatModel({
      ...(data as any),
      // eslint-disable-next-line max-len
      chatRemovedMessage: data.isAnyParticipantDeleted ? 'This chat is not active' : '',
    });
  }

  public static deserializeList(data: IChats[]): IChats[] {
    if (!data || !Array.isArray(data)) {
      return [];
    }
    return data.map(x => ChatModel.deserialize(x));
  }
}
