import { EDITOR_TYPES, Utilities, SelectOptionModel } from '@job-commander/shared';
import { ISkill } from 'src/interfaces/API-Candidate.interface';
import { ScaffoldModel } from './Scaffold.model';

export class SkillModel extends ScaffoldModel {
  skill: string;
  description: string;
  experienceLevel: SelectOptionModel;
  experienceInYear: SelectOptionModel;

  constructor(data?: Partial<SkillModel>) {
    super(data);
    Object.assign(this, data);
    this.editorType = EDITOR_TYPES.SKILLS;
    this.title = Utilities.trimCharacters(this.skill, 29) || '';
    this.lines = [ this.experienceLevel?.label ];
  }

  public static deserialize(response: ISkill): SkillModel {
    return new SkillModel({
      ...response,
      experienceLevel: SelectOptionModel.deserialize(response?.experienceLevel),
      experienceInYear: SelectOptionModel.deserialize(response?.experienceInYear),
    });
  }

  public static deserializeList(data: ISkill[]): SkillModel[] {
    if (!data || !Array.isArray(data)) {
      return [];
    }
    return data.map(x => SkillModel.deserialize(x));
  }

  public serialize(): ISkill {
    return {
      id: this.id,
      skill: this.skill,
      description: this.description,
      experienceLevelId: this.experienceLevel?.value,
      experienceInYearId: this.experienceInYear?.value,
    };
  }
}
