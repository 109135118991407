import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import OverlayLoader from '../OverlayLoader/OverlayLoader';

interface Props {
  children: any;
  title: string;
  width?: number;
  isLoading?: boolean;
  disabled?: Function;
  onCancel: Function;
  onOk: Function;
  ignoreConfirmCancel?: boolean;
}

const UpsertDialog: FC<Props> = ({
  title,
  onCancel,
  onOk,
  width,
  disabled,
  isLoading,
  children,
  ignoreConfirmCancel,
}) => {
  const onCancelDialog = () => {
    if (ignoreConfirmCancel) {
      onCancel();
      return;
    }
    Modal.confirm({
      title: 'Confirm',
      content:
        // eslint-disable-next-line max-len
        'If you close this window, all your work will be lost and you\'ll have to start over. Are you sure you want to continue?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => onCancel(),
    });
  };
  return (
    <Modal
      title={title}
      visible={true}
      width={width}
      maskClosable={false}
      onCancel={onCancelDialog}
      onOk={onOk as any}
      okButtonProps={{ disabled: disabled() }}
    >
      <OverlayLoader isLoading={isLoading}>{children}</OverlayLoader>
    </Modal>
  );
};

UpsertDialog.propTypes = {
  width: PropTypes.number,
  disabled: PropTypes.func,
  ignoreConfirmCancel: PropTypes.bool,
  isLoading: PropTypes.bool,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
};

UpsertDialog.defaultProps = {
  disabled: () => false,
  ignoreConfirmCancel: false,
  onCancel: () => null,
  onOk: () => null,
};

export default UpsertDialog;
