import { CoreModel } from '../models';
import { IAPIAnswers } from '../interfaces';

export class AnswerModel extends CoreModel {
  answer: string;
  answerOption: string;
  questionId: number;

  constructor(data?: Partial<AnswerModel>) {
    super(data);
    Object.assign(this, data);
  }

  /**
   * MAP DATA FROM API to UI MODEL
   */
  public static deserialize(data: IAPIAnswers): AnswerModel {
    return new AnswerModel(data);
  }

  public static deserializeList(data: IAPIAnswers[]): AnswerModel[] {
    if (!data || !Array.isArray(data)) {
      console.warn('Parsing item is not an array AnswerModel', data);
      return;
    }
    return data.map(x => this.deserialize(x));
  }
}
