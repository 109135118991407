/* eslint-disable max-len */
import { useDialog } from '@surinderlohat/react-dialog';
import React, { FC, useRef, useEffect, useState } from 'react';
import { AnswerPsyQuestions, EditJobDetails } from '../../../../../components';
import { jobScaffold, ListEditor, OverlayLoader, UpsertDialog } from '@job-commander/shared';
import { useAppStores } from 'src/store';
import { JobModel } from 'src/models/Job.model';
import { editorModelMapping } from 'src/mapping/EditorModel.mapping';
import { useLoader } from '@surinderlohat/react-hooks';
import { useDataService } from 'src/api';
import { JobWithNamesModel } from 'src/models/JobWithNames.model';
import { Modal } from 'antd';

type Props = {
  formRef?: any;
  jobs: JobModel[];
  packageType?: string;
  totalFreeJobs?: number;
};

const AddJobDetails: FC<Props> = ({ jobs, packageType, totalFreeJobs }) => {
  const jobEditFormRef = useRef<any>();
  const dialog = useDialog();
  const loader = useLoader();
  const dataService = useDataService();
  const [ oldJobsWithAnswers, setOldJobsWithAnswers ] = useState<JobWithNamesModel[]>([]);
  const { userStore } = useAppStores();
  useEffect(() => {
    loadEmployerJobs();
  }, []);

  // load current logged in user profile
  const loadEmployerJobs = async () => {
    loader.showLoader();
    const response = await dataService.getJobsWithNames();
    if (response.hasError) {
      return;
    }
    loader.hideLoader();
    setOldJobsWithAnswers(response.data);
  };
  const upsertJobDetails = (editingItem, values) => {
    const Model = editorModelMapping[editingItem.editorType];
    userStore.upsertEmployerJob(
      new Model({ ...editingItem, ...values, heading: values.title, removable: true, scaffold: false })
    );
  };

  // When user edit any item
  const onItemClick = (editingItem: JobModel) => {
    if ( packageType === 'free' && totalFreeJobs + userStore.employerJobs.length >= 2 && sessionStorage.getItem('isSelectedPremium') != 'true') {
      Modal.confirm({
        title: 'Limited Job Posting in free package',
        content: 'Because you have inputted more than 2 job descriptions, you cannot proceed with the free version of JobCommander. Do you want to upgrade to JobCommander Premium? If not, you must delete job descriptions with a maximum of 2.',
        okText: 'Upgrade to Premium',
        cancelText: 'Cancel',
        onOk: () => {sessionStorage.setItem('isSelectedPremium', 'true')},
        onCancel: () => dialog.closeDialog() ,
      })
      return;
    }
    else{
      dialog.openDialog(
        <UpsertDialog
          title={editingItem.title}
          onCancel={() => dialog.closeDialog()}
          onOk={async () => {
            try {
              await jobEditFormRef.current?.validateFields();
              const values = jobEditFormRef.current?.getFieldsValue();
              if (editingItem?.answersSelected) {
                upsertJobDetails(editingItem, values);
              }
              dialog.closeDialog();
              // if User asking for new Questions -1 Means User wants to answer new questions
              if (values.questionTemplate === -1 && !editingItem?.answersSelected) {
                openQuestion(editingItem, values);
                return;
              }
              // iF User Select copy answers
              const copyAnswersFrom = [ ...oldJobsWithAnswers, ...userStore.employerJobs ].find(
                j => j.tempId === values.questionTemplate
              );
              // Copy Answers From Another Job
              if (copyAnswersFrom.answersSelected && Object.keys(copyAnswersFrom.answersSelected).length) {
                values.answersSelected = { ...copyAnswersFrom.answersSelected };
                upsertJobDetails(editingItem, values);
                return;
              }
              // If selected job also coping from another Job
              values.copyAnswersFromJobId = copyAnswersFrom.copyAnswersFromJobId || copyAnswersFrom.id;
              upsertJobDetails(editingItem, values);
            } catch (error) {
              console.log(error);
            }
          }}
        >
          <EditJobDetails
            item={editingItem}
            formRef={jobEditFormRef}
            jobsToCopyAnsers={[ ...oldJobsWithAnswers, ...userStore.employerJobs ]}
          />
        </UpsertDialog>
      );
    }
  };

  const openQuestion = (editingItem, updatedJobValues) => {
    dialog.openDialog(
      <AnswerPsyQuestions
        isFreeQuestions={true}
        onDialogClose={() => dialog.closeDialog()}
        onSubmitQuestionnaire={answers => {
          if (answers) {
            userStore.upsertEmployerJob(new JobModel({ ...updatedJobValues, answersSelected: answers }));
            dialog.closeDialog();
          }
        }}
      />
    );
  };

  return (
    <OverlayLoader isLoading={loader.isLoading}>
      <ListEditor
        items={jobs}
        scaffolds={jobScaffold.jobs}
        onItemClick={onItemClick}
        onRemoveClick={item => userStore.removeEmployerJob(item)}
      />
    </OverlayLoader>
  );
};

export default AddJobDetails;
