import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Form, Modal } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { OverlayLoader, useAlert } from '@job-commander/shared';
import '../BuyModal.less';
import { useLoader } from '@surinderlohat/react-hooks';
import { useAppStores } from 'src/store';
import { usePaymentService } from 'src/api/PaymentService';
import { BuySuperMatch } from './BuySuperMatch/BuySuperMatch';
import { BuyQuestions } from './BuyQuestions/BuyQuestions';
import { StripeCard } from '../StripeCard/StripeCard';
import { IValidateBuyQuestionsProps } from '../ValidateBuyQuestions';

const BuyQuestionsDialog: FC<IValidateBuyQuestionsProps> = ({
  isSuperMatch,
  allowRedirect,
  onCancel,
  onSuccess,
  onError,
  employerJobId,
  pendingQuestionCount,
}) => {
  const loader = useLoader(false);
  const [ isCardValid, setIsCardValid ] = useState(true);
  const [ quantity, setQuantity ] = useState<number>(isSuperMatch ? 1 : 10);
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();

  const { productsStore, userStore } = useAppStores();
  const paymentService = usePaymentService();

  // Load Products
  useEffect(() => {
    productsStore.loadProducts();
  }, []);

  // Products hook include products details
  const { products } = productsStore;
  const alert = useAlert();

  const onCardValueChange = e => {
    if (e.error != null || e.complete !== true) {
      setIsCardValid(false);
      return;
    }
    setIsCardValid(true);
  };

  const handleOnBuy = async () => {
    loader.showLoader();

    // for super match prise is prise * qty and for questions it's based on user type and quantity
    const product = isSuperMatch
      ? products.superMatch[0]
      : products.questionPackage.find(_product => _product.questionsToBuy === quantity);

    const totalPayment = isSuperMatch ? product.price * quantity : product.price;
    const totalQty = isSuperMatch ? quantity : 1;

    const checkoutResult = await paymentService.paymentCheckout({
      productId: product.id,
      quantity: totalQty,
      jobId: employerJobId,
    });
    if (checkoutResult.hasError) {
      onError(checkoutResult);
      loader.hideLoader();
      return;
    }

    const payload = await stripe.confirmCardPayment(checkoutResult.data?.paymentIntent.clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    if (payload.error) {
      alert.error(payload.error.message);
      onError(payload);
      loader.hideLoader();
      return;
    }

    const result = await paymentService.confirmPayment(checkoutResult.data?.transactionId);
    if (result.hasError) {
      onError(result);
      loader.hideLoader();
      return;
    }

    loader.hideLoader();
    alert.success('Thank You!', 'Payment Successful');
    if (isSuperMatch) {
      await userStore.syncCredits();
      onCancel();
    }
    if (!isSuperMatch && allowRedirect) {
      // To close the dialog when redirecting to the more questions page.
      onCancel();
      userStore.setIsScoringStateCompleted(false);
      if (userStore.isCandidate) {
        history.push('/a/more-questions');
      } else {
        history.push(`/a/more-questions/${employerJobId}`);
      }

      return;
    }

    onSuccess({
      totalQty,
      payload,
      productId: product.productName,
      fullPrice: totalPayment,
    });
  };

  return (
    <Modal
      visible={true}
      title={`Buy ${isSuperMatch ? 'Super Match' : 'More Questions'}`}
      destroyOnClose={true}
      maskClosable={true}
      closable={!loader.isLoading}
      onOk={handleOnBuy}
      onCancel={() => !loader.isLoading && onCancel()}
      okButtonProps={{ loading: loader.isLoading, disabled: !(quantity != null && isCardValid) }}
      cancelButtonProps={{ disabled: loader.isLoading }}
      okText="Buy"
    >
      <OverlayLoader
        isLoading={productsStore.isLoadingProducts || loader.isLoading}
        showChildren={productsStore.isProductLoaded}
      >
        <Form id="buy-form" layout="horizontal" initialValues={{ quantity }}>
          {isSuperMatch ? (
            <BuySuperMatch
              product={products.superMatch[0]}
              quantity={quantity}
              onQuantityChange={qty => setQuantity(qty)}
            />
          ) : (
            <BuyQuestions
              products={products?.questionPackage?.filter(p => p.questionsToBuy <= pendingQuestionCount)}
              quantity={quantity}
              onQuantityChange={qty => setQuantity(qty)}
            />
          )}
          <Form.Item name="card">
            <StripeCard onChange={onCardValueChange} isCardValid={isCardValid} />
          </Form.Item>
        </Form>
      </OverlayLoader>
    </Modal>
  );
};

export default BuyQuestionsDialog;
