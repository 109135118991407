import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, Menu, Button } from 'antd';
import React, { FC, MouseEventHandler, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ChangePassword } from './ChangePassword/ChangePassword';
import { UpdateEmail } from './UpdateEmail/UpdateEmail';
import './ProfileIcon.less';
import { useB2CClient, ViewPermission } from '@job-commander/shared';
type Props = {
  openLoginDialog?: MouseEventHandler<HTMLElement>;
};

const ProfileIcon: FC<Props> = props => {
  const [ showChangePasswordDialog, setChangePasswordDialog ] = useState(false);
  const [ showUpdateEmailDialog, setUpdateEmailDialog ] = useState(false);
  const history = useHistory();

  const b2cClient = useB2CClient();

  const handleLogout = async () => {
    await b2cClient.logout();
    history.push('/');
  };

  const renderContent = () => {
    if (b2cClient.isAuthorized) {
      const menu = (
        <Menu>
          <Menu.Item key="0">
            <Link to="/a/me">My Profile</Link>
          </Menu.Item>
         
          <Menu.Item key="3">
            <a onClick={handleLogout}>Logout</a>
          </Menu.Item>
        </Menu>
      );

      return (
        <>
          <ViewPermission isVisible={showChangePasswordDialog}>
            <ChangePassword handleClose={() => setChangePasswordDialog(false)} />
          </ViewPermission>
          <ViewPermission isVisible={showUpdateEmailDialog}>
            <UpdateEmail handleClose={() => setUpdateEmailDialog(false)} />
          </ViewPermission>
          <Dropdown overlay={menu} trigger={[ 'click' ]} placement="bottomRight">
            <FontAwesomeIcon icon={faUser} />
          </Dropdown>
        </>
      );
    }

    return (
      <Button type="text" onClick={props.openLoginDialog}>
        <FontAwesomeIcon icon={faUser} />
        <span>&nbsp;Login</span>
      </Button>
    );
  };

  return <div className="profile-icon">{renderContent()}</div>;
};

export { ProfileIcon };
