import { APIResponse, HttpClient, useAccessToken, useAlert } from '@job-commander/shared';
import { generalMessage } from 'src/components/Messages';
// eslint-disable-next-line max-len
import { ICheckout, ICheckoutActivateJob, ICheckoutArchivedJobsPayment, ICheckoutCardUpdateWithPayment, ICheckoutExtendJob, ICheckoutOneTimeExtendJob, ICheckoutResponse } from 'src/interfaces';
import { baseAPI } from '../config';
import { apiUrls } from './apiUrls';

export interface IPaymentService {
  paymentCheckout: (request: ICheckout) => Promise<APIResponse<ICheckoutResponse>>;
  paymentCheckoutJobs: (request: ICheckout) => Promise<APIResponse<ICheckoutResponse>>;
  confirmPayment: (transactionId: number) => Promise<APIResponse<any>>;
  extendJobPosting: (request: ICheckoutExtendJob) => Promise<APIResponse<any>>;
  activeJobPosting: (request: ICheckoutActivateJob) => Promise<APIResponse<any>>;
  oneTimeExtendJob: (request: ICheckoutOneTimeExtendJob) => Promise<APIResponse<any>>;
  upgradePremiumPlan: (request: ICheckoutOneTimeExtendJob) => Promise<APIResponse<any>>;
  archivedJobsPayment: (request: ICheckoutArchivedJobsPayment) => Promise<APIResponse<any>>;
  cardUpdate: (stripeToken: string) => Promise<APIResponse<any>>;
  // eslint-disable-next-line max-len
  cardUpdateWithPayment: (request: ICheckoutCardUpdateWithPayment) => Promise<APIResponse<ICheckoutCardUpdateWithPayment>>;
}

export const usePaymentService = (): IPaymentService => {
  const alert = useAlert();
  const accessToken = useAccessToken();

  // Checkout For Product
  const paymentCheckout = async (data: ICheckout): Promise<APIResponse<ICheckoutResponse>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.post(apiUrls.paymentCheckout, data as any);
    if (response.hasError) {
      alert.error(generalMessage.somethingWentWrong, response.statusText);
      return;
    }
    return response;
  };

  // Checkout For Product
  const paymentCheckoutJobs = async (data: ICheckout): Promise<APIResponse<ICheckoutResponse>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.post(apiUrls.paymentCheckoutJobs, data as any);
    if (response.hasError) {
      alert.error(generalMessage.somethingWentWrong, response.statusText);
      return response;
    }
    return response;
  };

  // Confirm Product Payment
  const confirmPayment = async (transactionId: number): Promise<APIResponse<any>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.get(`${apiUrls.paymentConfirm}/${transactionId}`, null);
    if (response.hasError) {
      alert.error(generalMessage.somethingWentWrong, response.statusText);
      return response;
    }
    return response;
  };

  const extendJobPosting = async (data: ICheckoutExtendJob) => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.post(apiUrls.extendJobPosting, data as any);
    if (response.hasError) {
      alert.error(generalMessage.somethingWentWrong, response.statusText);
      return response;
    }
    return response;
  };

  const activeJobPosting = async (data: ICheckoutActivateJob) => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.post(apiUrls.activeJobPosting, data as any);
    if (response.hasError) {
      alert.error(generalMessage.somethingWentWrong, response.statusText);
      return response;
    }
    return response;
  };

  const oneTimeExtendJob = async (data: ICheckoutOneTimeExtendJob) => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.post(apiUrls.oneTimeExtendJob, data as any);
    if (response.hasError) {
      alert.error(generalMessage.somethingWentWrong, response.statusText);
      return response;
    }
    return response;
  };

  const cardUpdate = async (stripeToken: string): Promise<APIResponse<any>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    console.log(stripeToken,'stripe')
    const response = await http.post(`${apiUrls.cardUpdate}/${stripeToken}`, null);
    if (response.hasError) {
      alert.error(generalMessage.somethingWentWrong, response.statusText);
      return response;
    }
    return response;
  };

  const cardUpdateWithPayment = async (data: ICheckoutCardUpdateWithPayment) => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.post(apiUrls.cardUpdateWithPayment, data as any);
    if (response.hasError) {
      alert.error(generalMessage.somethingWentWrong, response.statusText);
      return response;
    }
    return response;
  };

  const upgradePremiumPlan = async (data: ICheckoutOneTimeExtendJob) => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.post(apiUrls.upgradePremiumPlan, data as any);
    if (response.hasError) {
      alert.error(generalMessage.somethingWentWrong, response.statusText);
      return response;
    }
    return response;
  };

  const archivedJobsPayment = async (data: ICheckoutArchivedJobsPayment) => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.post(apiUrls.archivedJobsPayment, data as any);
    if (response.hasError) {
      alert.error(generalMessage.somethingWentWrong, response.statusText);
      return response;
    }
    return response;
  };

  return {
    paymentCheckout,
    confirmPayment,
    paymentCheckoutJobs,
    extendJobPosting,
    activeJobPosting,
    oneTimeExtendJob,
    upgradePremiumPlan,
    archivedJobsPayment,
    cardUpdate,
    cardUpdateWithPayment,
  };
};
