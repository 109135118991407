import { maxChar, OverlayLoader, useQueryParams, ViewPermission } from '@job-commander/shared';
import { Button, Form, Input, InputNumber, Select, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import React, { useRef, useState, useEffect, FC } from 'react';
import '../AddNewJobs/AddNewJobs.less';
import { useLoader } from '@surinderlohat/react-hooks';
import { useDataService } from 'src/api';

enum websiteInfo {
  SAME_WEBSITE = 1,
  OTHER_WEBSITE = 2,
}

const JobFilledQuestions: FC = () => {
  const [ whereCandidateHired, setWhereCandidateHired ] = useState(null);
  const [ candidateOptions, setCandidateOptions ] = useState([]);
  const jobFormRef = useRef(null);
  const [ from ] = Form.useForm();
  const { Title } = Typography;
  const [ reasonOptions, setReasonOptions ] = useState([]);
  const loader = useLoader();
  const dataService = useDataService();
  const queryParams = useQueryParams();
  const history = useHistory();
  const [ selectedCandidate, setSelectedCandidate ] = useState(null);

  useEffect(() => {
    getCandidateNames();
    loadRemoveJobQuestions();
  }, []);

  // load current logged in user profile
  const loadRemoveJobQuestions = async () => {
    loader.showLoader();
    const response = await dataService.removeJobQuestions();
    if (response.hasError) {
      return;
    }
    const reasonOptions = response[0].options.map(reason => ({
      value: reason.id,
      label: reason.name,
    }));
    setReasonOptions(reasonOptions);
    loader.hideLoader();
  };

  const onSubmit = async () => {
    await jobFormRef?.current?.validateFields();
    const values = jobFormRef?.current?.getFieldsValue();
    const request = {
      employerJobId: queryParams.employerJobId,
      jobDeletionReasonId: websiteInfo.SAME_WEBSITE,
      jobDeletionReasonOptionId: values?.foundCandidateReason,
      candidateId: values?.candidateId === -1 ? null : values?.candidateId,
      salaryOffered: values?.salaryOffered,
      website: values?.website,
      candidateNameOther: values?.candidateId === -1 ? values?.candidateNameOther : null,
    }
    const response = await dataService.deleteJobFromEmail(request);
    if (response.hasError) {
      return;
    }
    history.push('/a/matches');
  };

  const onValueChanges = values => {
    if (values.jobDeletedReasonChoiceNo === 2) {
      from.setFieldsValue({ foundCandidateReason: null });
      setWhereCandidateHired([]);
      return;
    }
    if (values.foundCandidateReason === 2) {
      from.setFieldsValue({ salaryOffered: null, candidateId: null });
      setWhereCandidateHired(null);
    }
  };
  const otherOption = { value: -1, label: 'Other' };
  const getCandidateNames = async () => {
    loader.showLoader()
    const result = await dataService.getCandidateNames(queryParams.employerJobId);
    loader.hideLoader()
    if (result.hasError) {
      return;
    }
    setCandidateOptions([ ...result.data, otherOption ]);
  };
  return (
    <OverlayLoader isLoading={loader.isLoading}>
      <div id="do-not-extend-job">
        <Form ref={jobFormRef} form={from} onValuesChange={onValueChanges}>
          <Title level={4}>Job Filled Questions</Title>

          <Form.Item
            name="foundCandidateReason"
            label="Where did you find the candidate?"
            rules={[{ required: true }]}
            hasFeedback
          >
            <Select onChange={value => setWhereCandidateHired(value)} options={reasonOptions} placeholder="Select..." />
          </Form.Item>
          <ViewPermission isVisible={whereCandidateHired === websiteInfo.SAME_WEBSITE && candidateOptions.length >= 1}>
            <Form.Item label="Who have you hired?" rules={[{ required: true }]} name="candidateId">
              <Select options={candidateOptions} placeholder="Select Candidate From List"  onChange={value => {
                setSelectedCandidate(value);
              }} />
            </Form.Item>

            <Form.Item name="salaryOffered" label="What was the salary you offered?" rules={[{ required: true }]}>
              <InputNumber
                min={0}
                style={{ width: '100%' }}
                placeholder="Salary Offered"
                step={500}
                formatter={value => `$ ${value}`.replace(/\/\\B\(\?=\(\\d\{3\}\)\+\(\?!\\d\)\)\/g/, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '') as any}
              />
            </Form.Item>
          </ViewPermission>
          <ViewPermission isVisible={whereCandidateHired === websiteInfo.SAME_WEBSITE && selectedCandidate === -1}>
            <Form.Item
              name="candidateNameOther"
              label="What was the name of the candidate?"
              required
              hasFeedback
              rules={[{ required: true }, maxChar(100) ]}
            >
              <Input type="text" placeholder="Full Name" />
            </Form.Item>
          </ViewPermission>
          <ViewPermission isVisible={whereCandidateHired === websiteInfo.SAME_WEBSITE && !candidateOptions.length}>
            <Title level={5}>It looks like you have not hired any candidate on Job Commander.</Title>
          </ViewPermission>
          <ViewPermission isVisible={whereCandidateHired === websiteInfo.OTHER_WEBSITE}>
            <Form.Item
              name="website"
              label="What website or other source did you use to find your hire"
              rules={[{ required: true }]}
              hasFeedback
            >
              <Input type="text" placeholder="https://www.company.com" />
            </Form.Item>
          </ViewPermission>

          <div className="submit-button">
            <Button
              type="primary"
              htmlType="submit"
              onClick={onSubmit}
              disabled={whereCandidateHired === websiteInfo.SAME_WEBSITE && !candidateOptions.length}
            >
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </OverlayLoader>
  );
};

export default JobFilledQuestions;
