import { IScaffold } from '../../interfaces';
import React, { FC, ReactElement } from 'react';
import { SortableContainer, SortableContainerProps, SortableElement, SortableElementProps } from 'react-sortable-hoc';

interface Props extends SortableContainerProps {
  isSortable?: boolean;
  listItems: IScaffold[];
  renderItem: (item: any, index: number) => ReactElement;
}

interface ItemProps extends SortableElementProps {
  children: any;
}

const SortableItem = SortableElement<ItemProps>(({ children }) => <>{children}</>);

const SortableList: FC<Props> = ({ isSortable, listItems, renderItem }) => {
  if (isSortable) {
    return (
      <div style={{ width: '100%' }}>
        {listItems.map((item, index) => (
          <SortableItem key={item?.title} index={index}>
            {renderItem(item, index)}
          </SortableItem>
        ))}
      </div>
    );
  }

  return <> {listItems?.map((item, idx) => renderItem(item, idx))}</>;
};

export default SortableContainer<Props>(SortableList);
