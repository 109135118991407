import { benefitsBits } from '../assets/data';

import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

export class Utilities {
  /**
   * Create random id for builder elements
   */
  public static createId(): string {
    return uuidv4();
  }

  // map values from object B to A
  public static mapResponse<T, P>(to: T, from: P): T {
    if (!to) {
      return null;
    }
    const temp = { ...to };
    Object.keys(to).forEach(key => {
      if (from[key] != null) {
        temp[key] = from[key];
      }
    });
    return temp;
  }

  // Update item in array
  public static updateArrayItem(array: any[], updatedItem: any, matchKey: string): any[] {
    if (!Array.isArray(array)) {
      console.error('Provided item is not an array');
      return [];
    }
    return array.map(item => (updatedItem[matchKey] === item[matchKey] ? updatedItem : item));
  }

  // Update Array with match Key
  public static updateArrayItemV3<T>(array: T[], updatedItem: T, matchKey: string, addItem?: boolean): T[] {
    if (!Array.isArray(array) || !updatedItem) {
      console.error('Provided item is not an array');
      return [];
    }

    const arrayCopy = [ ...array ];
    const index = arrayCopy.findIndex(x => x[matchKey] === updatedItem[matchKey]);

    if (index === -1) {
      // If Item Not Found then Add
      if (addItem) {
        arrayCopy.push(updatedItem);
        return arrayCopy;
      }

      console.info('item not found nothing updated');
      return array;
    }
    arrayCopy.splice(index, 1, updatedItem);
    return arrayCopy;
  }

  // Move array item from one index to another
  public static arrayMoveMutable(array: any[], fromIndex: number, toIndex: number) {
    const newArray = [ ...array ];
    const startIndex = fromIndex < 0 ? newArray.length + fromIndex : fromIndex;
    if (startIndex >= 0 && startIndex < newArray.length) {
      const endIndex = toIndex < 0 ? newArray.length + toIndex : toIndex;
      const [ item ] = newArray.splice(fromIndex, 1);
      newArray.splice(endIndex, 0, item);
    }
    return newArray;
  }

  // Get New id from the Array Items
  public static getNewId = (array, key) => {
    return (
      array.reduce((acc, curr) => {
        if (parseInt(curr[key]) > acc) {
          return curr[key];
        }
        return acc;
      }, 0) + 1
    );
  };

  static isEqual(valueA: any, valueB: any): boolean {
    if (!valueA || !valueB) {
      return false;
    }
    if (typeof valueA === 'string' && typeof valueB === 'string') {
      return valueA.toLowerCase().trim() === valueB.toLowerCase().trim();
    }
    if (typeof valueA === 'number' && typeof valueB === 'number') {
      return valueA === valueB;
    }
    return valueA === valueB;
  }

  // Convert any number to it's binary form
  static convertToBinary = (number: number) => {
    let num = number;
    let binary = (num % 2).toString();
    while (num > 1 ) {
      // NO not change
      num = parseInt(`${num / 2}`);
      binary = (num % 2) + binary;
    }
    return binary;
  };

  static parseStringToHtml = (value: string) => {
    return value?.replace(/\n/g, '<br />');
  };

  // return total bit power of the selected benefits options
  static getBenefitBits = (selectedBenefits: number[]) => {
    if (!selectedBenefits || !Array.isArray(selectedBenefits)) {
      return null;
    }
    const total = selectedBenefits.reduce((total, curr) => {
      total = total + benefitsBits.find(x => x.value === curr)?.bitPower;
      return total;
    }, 0);
    return total;
  };

  // return total bit power of the selected benefits options
  static getBenefitOptions = (jobBenefitBits: number) => {
    const binary = this.convertToBinary(jobBenefitBits);
    const selectedOptions = Array.from(binary)
      .reverse()
      .reduce((total, curr, idx) => {
        if (parseInt(curr)) {
          total.push(benefitsBits[idx].value);
        }
        return total;
      }, []);
    return selectedOptions;
  };

  static getFormattedDate(date: string, format?: string): string {
    if (!date) {
      return null;
    }
    const displayFormat = format ?? 'DD-MMM-YYYY';
    const momentDate: moment.Moment = moment.utc(date, 'YYYY-MM-DDTHH:mm:ss');
    return momentDate.isValid() ? momentDate.format(displayFormat) : '';
  }

  static getMomentDate(date: string, format?: string): moment.Moment {
    if (!date) {
      return null;
    }
    return moment.utc(date, format || 'YYYY-MM-DDTHH:mm:ss');
  }

  static shallowClone(source, newProps) {
    return Object.defineProperties(source, Object.getOwnPropertyDescriptors(newProps));
  }

  static buildParamString(params: { [key: string]: any }): string {
    const paramsArray: string[] = [];
    for (const key in params) {
      if (this.isEqual(key, 'specifiedFields') && Array.isArray(params[key])) {
        params[key].forEach(element => paramsArray.push(`specifiedFields=${element}`));
      }

      if (params.hasOwnProperty(key) && !this.isEqual(key, 'specifiedFields')) {
        const value = typeof params[key] === 'number' && isFinite(params[key]) ? params[key] : params[key] || '';
        paramsArray.push(`${key}=${encodeURIComponent(value)}`);
      }
    }
    return paramsArray.join('&');
  }

  // trim scaffold lines to the 60 characters
  static trimCharacters = (value: string, length: number) => {
    return value?.length > length ? `${value?.substring(0, length - 3)}...` : value;
  };
}
