/* eslint-disable max-len */
import React, {  useState, useEffect, FC } from 'react';
import { useHistory } from 'react-router-dom';
import AddJobDetails from './Steps/AddJobDetails';
import './AddNewJobs.less';
import MakePayment from './Steps/MakePayment';
import { BuyEmployerPackage } from '../../../../components';
import { useDialog } from '@surinderlohat/react-dialog';
import { useLoader } from '@surinderlohat/react-hooks';
import { OverlayLoader, StickyLayoutButtons, useAlert } from '@job-commander/shared';
import { useAppStores } from 'src/store';
import { useDataService } from 'src/api';
import { EmployerModel } from 'src/models/Employer.model';
import { Modal } from 'antd';
import Step3 from 'src/pages/CompleteSignUpPage/Employer/steps/Step3';

const steps = [
  { title: 'Plans', description: 'Pick a Plan' },
  { title: 'Add Jobs', description: 'Add new Jobs' },
  { title: 'Let\'s Get Started' , description: '' },
];

const premiumSteps = [
  { title: 'Add Jobs', description: 'Add new Jobs' },
  { title: 'Plans', description: 'Pick a Pricing Plan' },
];

// Employer User will add new Jobs from add job description
const AddNewJobs: FC = () => {
  const [ currentStep, setCurrentStep ] = useState(0);

  const loader = useLoader();
  const alert = useAlert();
  const history = useHistory();
  const dialog = useDialog();
  const { userStore } = useAppStores();
  const [ allowFreeJobPost, setAllowFreeJobPost ] = useState(false);
  const [ isPaymentDone, setPaymentStatus ] = useState(false);
  const [ profileData, setProfileData ] = useState<EmployerModel>();
  const dataService = useDataService();
  const [ packageType, setPackageType ] = useState(null);
  const [ isLoading, setIsLoading ] = useState(true);

  useEffect(() => {
    userStore.clearEmployerJobs();
    userStore.setSelectedPlan(null)
  }, []);

  useEffect(() => {
    // Fetch profile data
    const getProfile = async () => {
      try {
        const response = await dataService.getProfile(userStore.isCandidate);
        const profile = response.data as EmployerModel;
        setProfileData(profile);
        setAllowFreeJobPost( sessionStorage.getItem('isSelectedPremium') === 'true' ? false : !profile.isFirstHiringDone
        );
      } catch (error) {
        alert.error('something went wrong!')
      } finally {
        setIsLoading(false);
      }
    };

    getProfile(); // Fetch profile data when component mounts
  }, []);

  const checkFreeJobPost = () => {
    setAllowFreeJobPost(!profileData.isFirstHiringDone && profileData?.totalFreeJobs < 2 
      && profileData?.totalFreeJobs + userStore.employerJobs.length <= 2 && !profileData.isPremiumEmployer 
      && sessionStorage.getItem('isSelectedPremium') === 'false' );
  }

  const askUserToPurchasePackage = () => {
    // already paid
    if (isPaymentDone) {
      saveChanges();
      return;
    }
    const totalNumberOfJobDiscription = userStore.employerJobs.length ;
    dialog.openDialog(
      <BuyEmployerPackage
        totalNumberOfJobDiscription={totalNumberOfJobDiscription}
        selectedPlan={userStore?.selectedPlan}
        allowFreeJobPost={allowFreeJobPost}
        jobs={userStore.employerJobs}
        onBuySuccess={() => {
          setPaymentStatus(true);
          dialog.closeDialog();
          saveChanges();
        }}
        onCancel={() => dialog.closeDialog()}
      />
    );
  };

  //**Save Changes to user profile */
  const saveChanges = async () => {
    alert.success('Thank You!');
    setTimeout(() => history.replace('/a/my-jobs'), 500);
  };
  const onNextButtonClick = () => {
    if (currentStep === 1 && packageType === 'free' && profileData?.totalFreeJobs + userStore.employerJobs.length > 2 && sessionStorage.getItem('isSelectedPremium') === 'false') {
      Modal.confirm({
        title: 'Limited Job Posting in free package',
        content: 'Because you have inputted more than 2 job descriptions, you cannot proceed with the free version of JobCommander. Do you want to upgrade to JobCommander Premium? If not, you must delete job descriptions with a maximum of 2.',
        okText: 'Upgrade to Premium',
        cancelText: 'Cancel',
        onOk: () => {setCurrentStep(currentStep + 1), setPackageType('premium'), sessionStorage.setItem('isSelectedPremium', 'true')},
        onCancel: () => dialog.closeDialog() ,
      })
      checkFreeJobPost()
      return;
    }

    if (profileData?.isPremiumEmployer) {
      if (currentStep === 0) {
        checkFreeJobPost()
        setCurrentStep(currentStep + 1);
        return;
      }
    } else {
      if (currentStep === 1) {
        checkFreeJobPost();
        setCurrentStep(currentStep + 1);
        return;
      }
    }
    // save Questions and Ask For Payment
    askUserToPurchasePackage();
  };

  const onBackButtonClick = () => {
    if (currentStep <= 0) {
      checkFreeJobPost();
      return;
    }
    setCurrentStep(currentStep - 1);
  };

  // disable step
  const isStepValid = (step: number): boolean => {
    if(packageType === 'free'){
      return step === 1 ? !(userStore.employerJobs.length) : !(userStore.selectedPlan?.id);
    }
    else if (profileData?.isPremiumEmployer) {
      return step === 0 ? !(userStore.employerJobs.length) : !(userStore.selectedPlan?.id)
    }
    else{
      return step === 1 ? !(userStore.employerJobs.length) : !(userStore.selectedPlan?.id)
    }
  };

  const handlePackageSelect = (type) => {
    setPackageType(type);
    setCurrentStep(currentStep + 1);
  };

  return (
    <div id="add-job-wrapper">
      <OverlayLoader
        isLoading={isLoading}
        showChildren={!isLoading}
        loadingMessage="Loading, please wait ..."
      >
        {profileData?.isPremiumEmployer ? 
          <StickyLayoutButtons
            title="Add New Jobs"
            nextButtonTitle={currentStep === 0 ? 'Next' : 'Submit'}
            steps={premiumSteps}
            currentStep={currentStep}
            onNextButtonClick={onNextButtonClick}
            onBackButtonClick={onBackButtonClick}
            disableNext={isStepValid}
            isLoading={loader.isLoading}
          >
            <AddJobDetails jobs={userStore.employerJobs} totalFreeJobs={profileData?.totalFreeJobs} packageType = {packageType} />
            <MakePayment onCanMoveNext={() => {}} allowFreeJobPost={allowFreeJobPost} isPaymentDone={isPaymentDone} />
          </StickyLayoutButtons>
          : 
          <StickyLayoutButtons
            title="Add New Jobs"
            nextButtonTitle={currentStep === 2 ? 'Submit' : 'Next'}
            steps={steps}
            currentStep={currentStep}
            onNextButtonClick={onNextButtonClick}
            onBackButtonClick={onBackButtonClick}
            disableNext={isStepValid}
            isLoading={loader.isLoading}
          >
            <Step3 handlePackageSelect={handlePackageSelect}/>
            <AddJobDetails jobs={userStore.employerJobs} totalFreeJobs={profileData?.totalFreeJobs} packageType = {packageType} />
            <MakePayment onCanMoveNext={() => {}} allowFreeJobPost={allowFreeJobPost} isPaymentDone={isPaymentDone} />
          </StickyLayoutButtons>
        }
      </OverlayLoader>
    </div>
  );
};

export default AddNewJobs;
