import { CoreModel } from '../models';
import { IAPIQuestions } from '../interfaces';
import { AnswerModel } from './Answer.model';

export class QuestionModel extends CoreModel {
  question: string = '';
  answers: AnswerModel[] = [];

  constructor(data?: Partial<QuestionModel>) {
    super(data);
    Object.assign(this, data);
    this.answers = data?.answers?.map(x => new AnswerModel(x)) || [];
  }

  /**
   * MAP DATA FROM API to UI MODEL
   */
  public static deserialize(data: IAPIQuestions): QuestionModel {
    return new QuestionModel({ ...data, answers: AnswerModel.deserializeList(data?.answers) });
  }

  public static deserializeList(data: IAPIQuestions[]): QuestionModel[] {
    if (!data || !Array.isArray(data)) {
      console.warn('Parsing item is not an array SelectOptionModel', data);
      return;
    }
    return data.map(x => this.deserialize(x));
  }
}
