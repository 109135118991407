import React, { useState, useRef, useEffect, FC } from 'react';
import { Input, Row, Col, Button } from 'antd';
import './VerifyCode.less';
import { useTimer } from '../../../hooks';

type Props = {
  resendCode: Function;
  onCodeComplete?: (code: string) => void;
  codeLength?: number;
};

const VerifyCode: FC<Props> = ({ codeLength, resendCode, ...otherProps }) => {
  const timer = useTimer(60);
  const [ code, setCode ] = useState({});
  const inputContainer = useRef<any>();

  useEffect(() => {
    const _code = Object.keys(code).reduce((total, curr) => (total + code[curr]), '');
    if (_code.length >= codeLength) {
      otherProps.onCodeComplete(_code);
    }
  }, [ code ]);

  const isValidNumberKey = key => Array.from(Array(10).keys()).includes(parseInt(key));

  const onKeyUp = (e, digit) => {
    if (e.key === 'Delete' || e.key === 'Backspace') {
      focusInput(digit - 1);
      return;
    }

    // If it's numeric key then goto next
    if (isValidNumberKey(e.key)) {
      focusInput(digit + 1);
    }
  };

  // focus on input based on index
  const focusInput = (index: number) => {
    // return
    if (index < 1 && index >= codeLength) {
      return;
    }
    inputContainer.current.childNodes[index]?.firstChild.focus();
  };

  return (
    <div className="verify-code-wrapper">
      <div className="verify-code">
        <Row gutter={6} ref={inputContainer}>
          {Array.from(Array(4)).map((_, index) => (
            <Col span={4} key={_}>
              <Input
                type="number"
                min={0}
                size="large"
                tabIndex={index}
                autoFocus={index == 0}
                onChange={e => setCode(_code => ({ ..._code, [index]: e.target.value }))}
                onKeyUp={e => onKeyUp(e, index)}
                onKeyDown={(e: any) => {
                  if (e.target.value && ![ 'Delete', 'Backspace' ].includes(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </Col>
          ))}
        </Row>
        <div className="resend-code">
          {timer.allowResend ? (
            <Button type="link" onClick={resendCode as any}>
              Resend code
            </Button>
          ) : (
            <Button type="text" style={{ fontSize: '12px' }}>
              <span style={{ fontWeight: 'bold' }}> Code Received? &nbsp;</span>
              <span>Resending code in {timer.time}</span>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

VerifyCode.defaultProps = {
  codeLength: 6,
  onCodeComplete: (updatedCode: string) => {},
};

export { VerifyCode };
