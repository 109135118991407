import React from 'react';
import { EditorItem } from './EditorItem';
import { SortableList } from '../SortableList';
import { IScaffold } from '../../interfaces';
import './ListEditor.less';
import { SortEnd, SortEvent } from 'react-sortable-hoc';

const isScaffoldRequired = (scaffold, items = []) => {
  if (scaffold == null || scaffold.data == null) {
    return false;
  }

  const data = scaffold.data || {};
  if (data.type == null) {
    if (items.length > 0) return false;
    return scaffold.required || false;
  } else {
    if (items.length == 0) return scaffold.required || false;
    return !items.some(item => item.data.type === data.type);
  }
};

interface Props {
  items: IScaffold[];
  scaffolds?: IScaffold[];
  readOnly?: boolean;
  onItemClick?: Function;
  onRemoveClick?: Function;
  disableRemove?: boolean;
  onSortEnd?: (sort: SortEnd, event: SortEvent) => void;
  isEmployer?: boolean;
  isHalfFlex?: boolean;
  isSortable?: boolean;
}

// scaffolds : Are the dummy place holders used to add new items in list
const ListEditorV2 = ({
  items,
  scaffolds,
  readOnly,
  onItemClick,
  onRemoveClick,
  disableRemove,
  isEmployer,
  isHalfFlex,
  isSortable,
  onSortEnd,
}: Props) => {
  return (
    <div className={`list-editor-container ${isHalfFlex ? 'half-flex' : ''}`}>
      {!readOnly &&
        scaffolds.map((item,index) => (
          <EditorItem
            key={index}
            item={{ ...item, scaffold: true, removable: false, required: isScaffoldRequired(item, items) }}
            readOnly={readOnly}
            onClick={onItemClick}
          />
        ))}
      <SortableList
        isSortable={isSortable}
        listItems={items}
        onSortEnd={onSortEnd}
        helperClass="sortable-helper"
        renderItem={(item, index) => (
          <EditorItem
            key={index}
            item={item}
            readOnly={readOnly}
            onClick={onItemClick}
            isDeleteButtonRemove={item?.isDeleted || item?.isExpired}
            isJobInFreeTrial= {item?.isJobInFreeTrialPeriod}
            onRemoveClick={onRemoveClick}
            disableRemove={disableRemove}
            isEmployer={isEmployer}
          />
        )}
      />
    </div>
  );
};
ListEditorV2.defaultProps = {
  disableRemove: false,
  required: true,
  readOnly: false,
  scaffolds: [],
  items: [],
  isEmployer: false,
  isHalfFlex: false,
  isSortable: false,
};
export default ListEditorV2;
