import { EDITOR_TYPES, SelectOptionModel } from '@job-commander/shared';
import moment from 'moment';
import { ICollegeRequest, ICollegeResponse } from 'src/interfaces';
import { ScaffoldModel } from './Scaffold.model';

export class CollegeModel extends ScaffoldModel {
  relevantWork: string;
  graduationYear: moment.Moment = moment('2020');
  gpa: SelectOptionModel;
  college: SelectOptionModel;
  collegeNameOther: string;
  educationLevel: SelectOptionModel;
  majorDegrees: SelectOptionModel[];
  majorOther: string;
  minorDegree: SelectOptionModel;
  minorOther: string;

  constructor(data?: Partial<CollegeModel>) {
    super(data);
    Object.assign(this, data);
    this.lines = [ `Graduation Year: ${this.graduationYear?.year()}` ];
    this.editorType = EDITOR_TYPES.COLLEGE;
    this.title = this.collegeNameOther || this.college?.label || '';
    this.graduationYear = moment(data?.graduationYear, 'YYYY') || null;
    this.gpa = data?.gpa || new SelectOptionModel();
    this.college = data?.college || new SelectOptionModel();
    this.educationLevel = data?.educationLevel || new SelectOptionModel();
    this.majorDegrees = data?.majorDegrees || [];
    this.minorDegree = data?.minorDegree || new SelectOptionModel();
  }

  public static deserialize(response: ICollegeResponse): CollegeModel {
    return new CollegeModel({
      ...response,
      graduationYear: moment(response?.graduationYear, 'YYYY'),
      gpa: SelectOptionModel.deserialize(response?.gpa),
      college: SelectOptionModel.deserialize(response?.college),
      educationLevel: SelectOptionModel.deserialize(response?.educationLevel),
      majorDegrees: SelectOptionModel.deserializeList(response?.majorDegrees),
      minorDegree: SelectOptionModel.deserialize(response?.minorDegree),
    });
  }

  public static deserializeList(data: ICollegeResponse[]): CollegeModel[] {
    if (!data || !Array.isArray(data)) {
      return [];
    }
    return data.map(x => CollegeModel.deserialize(x));
  }

  public serialize(): ICollegeRequest {
    return {
      id: this.id || 0,
      relevantWork: this.relevantWork,
      graduationYear: this.graduationYear?.year()?.toString(),
      gpaId: this.gpa?.value || null,
      collegeId: this.college?.value,
      collegeNameOther: this.collegeNameOther || '',
      educationLevelId: this.educationLevel?.value,
      majorDegrees: this.majorDegrees.map(major => parseInt(`${major.value}`)),
      majorOther: this.majorDegrees?.map(major => parseInt(`${major?.value}`))?.includes(1) ? this.majorOther : null,
      minorDegree: parseInt(`${this.minorDegree.value}`),
      minorOther: parseInt(`${this.minorDegree?.value}`) === 1 ? this.minorOther: null,
    };
  }
}
