import { ICriteria } from 'src/interfaces';


export class CriteriaModel  {
  supermatch: boolean;
  gpa: boolean;
  benefits: boolean;
  relocation: boolean;
  workWeek: boolean;
  industryAndJobType: boolean;
  yearsExperience: boolean;
  workTravel: boolean;
  jobVenue: boolean;
  educationLevel: boolean;
  slamProgram: boolean;
  degreePreference: boolean;
  constructor(data?: Partial<CriteriaModel>) {
    Object.assign(this, data);
    
  }

  public static deserialize(response: ICriteria): CriteriaModel {
    return new CriteriaModel({
      ...response,
        
    });
  }

  
  
}
