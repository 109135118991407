import { Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { OverlayLoader, useAlert } from '@job-commander/shared';
import { useLoader } from '@surinderlohat/react-hooks';
import { useDataService } from 'src/api';
import BuyQuestionsDialog from './BuyQuestionsDialog/BuyQuestionsDialog';
import AnswerPsyQuestions from './AnswerPsyQuestions/AnswerPsyQuestions';
import { useAppStores } from 'src/store';

export interface IValidateBuyQuestionsProps {
  availableQuestionsCount?: number;
  allowRedirect?: boolean; // allow redirect to more questions page
  isSuperMatch: boolean;
  employerJobId?: number;
  onCancel: Function;
  onSuccess: Function;
  onError?: Function;
  onBuySuccess?: Function;
  pendingQuestionCount?: number;
  onReloadResults: () => void;
}

// If user has already buy some questions then ask him to provide the answers for that
const ValidateBuyQuestions = (props: IValidateBuyQuestionsProps) => {
  const [ questionsResult, setQuestionsResult ] = useState({
    allowBuyQuestion: false,
    hasPendingAnswers: false,
    answerQuestions: false, // Answer Newly buy questions or pending questions
  });
  const [ pendingQuestionCount, setPendingQuestionCount ] = useState();
  const dataService = useDataService();
  const alert = useAlert();
  const loader = useLoader(false);
  const history = useHistory();
  const { userStore } = useAppStores();
  // validate if user can buy more questions
  useEffect(() => {
    validateQuestions();
  }, []);

  // validate if user can buy more questions
  const validateQuestions = async () => {
    if (props.isSuperMatch) {
      setQuestionsResult(x => ({ ...x, allowBuyMoreQuestions: true }));
      return;
    }
    // Validate if system has more questions to buy
    loader.showLoader();
    const countResult = await dataService.getQuestionCount(props.employerJobId);
    loader.hideLoader();
    if (countResult.hasError) {
      props.onCancel();
      return;
    }
    setPendingQuestionCount(countResult?.pendingQuestionCount);
    setQuestionsResult({
      ...countResult,
      allowBuyQuestion: countResult.pendingQuestionCount > 9 && !countResult.hasPendingAnswers,
    });

    // if user can't buy more questions then return and close dialog
    if (countResult.pendingQuestionCount < 10) {
      alert.error(
        // eslint-disable-next-line max-len
        'Sorry! It looks like you have answered all of our JobCommander Questions. Please check back in periodically as we are always adding additional questions.'
      );
      props.onCancel();
    }
  };

  // If user Has Pending Answers from Last buy
  if (questionsResult.answerQuestions) {
    return (
      <AnswerPsyQuestions
        employerJobId={props.employerJobId}
        allowDirectSave={true}
        onDialogClose={success => {
          if (success) {
            props.onReloadResults();
            props.onCancel();
          }
          props.onCancel();
        }}
      />
    );
  }

  // if user allowed to buy more questions
  if (questionsResult.allowBuyQuestion) {
    return (
      <BuyQuestionsDialog
        {...props}
        onSuccess={() => {
          setQuestionsResult(x => ({ ...x, answerQuestions: true }));
        }}
        pendingQuestionCount={pendingQuestionCount}
      />
    );
  }
  return (
    <>
      {props.isSuperMatch ? (
        <BuyQuestionsDialog
          {...props}
          onSuccess={async () => {
            await userStore.syncCredits();
            setQuestionsResult(x => ({ ...x, answerQuestions: true }));
            props.onBuySuccess?.();
          }}
        />
      ) : (
        <Modal
          visible={true}
          destroyOnClose={true}
          maskClosable={true}
          closable={true}
          onOk={() => {
            if (props.allowRedirect) {
              props.onCancel();
              if (userStore.isCandidate) {
                history.push('/a/more-questions');
              } else {
                history.push(`/a/more-questions/${props.employerJobId}`);
              }
              return;
            }
            setQuestionsResult(x => ({ ...x, answerQuestions: true }));
          }}
          onCancel={props.onCancel as any}
          okButtonProps={{ disabled: loader.isLoading }}
          cancelButtonProps={{ disabled: loader.isLoading }}
          okText="Yes"
        >
          <OverlayLoader isLoading={loader.isLoading}>
            {loader.isLoading
              ? 'Loading questions...'
              : // eslint-disable-next-line max-len
              'You already have some questions pending to answer. You cannot purchase additional questions until you\'ve answered all previously purchased questions. Click Yes if you want to answer them now!'}
          </OverlayLoader>
        </Modal>
      )}
    </>
  );
};

export default ValidateBuyQuestions;
