/* eslint-disable max-len */
import { Collapse } from 'antd';
import React, { FC } from 'react';
const { Panel } = Collapse;

const SuperMatch: FC = () => {
  return (
    <Collapse>
      <Panel key={0} header="What is a SuperMatch?" showArrow={false}>
        <p>
          When using a SuperMatch on a specific job posting, you will be asked a short series of questions. There is no
          right or wrong answers. Just answer truthfully. If your answers align with how the Employer answered their
          question, you will be allowed to apply for that position. JobCommander believes that those who are only a few
          percentage points away from the Minimum Compatibility Score should still have an opportunity to apply. You may
          only apply one SuperMatch per one singular job description. Each “SuperMatch” you purchase is $9.99.
        </p>
      </Panel>
    </Collapse>
  );
};

export const matchItemMessages = {
  alreadyInvitedMessage: isCandidate => ({
    content: isCandidate
      ? 'It looks like you have already applied for this position! Please refer to your Messages tab to see where you stand in the application process!'
      : 'It looks like you have already invited this user to apply. Please refer to your Messages tab to see where this Candidate is in your hiring process.',
    icon: '',
  }),

  inviteConfirmMessage: isEmployer =>
    `Are you sure you want ${isEmployer ? 'invite this candidate to apply for this job?' : 'to apply for this job?'}`,

  inviteSuccessMessage: (isEmployer, item) =>
    isEmployer
      ? `You have invited ${item.firstName} to apply for the job ${item.title}!`
      : `Your application for the ${item.title} job at ${item.companyName} has been submitted successfully!`,

  alreadyEligibleForAppyOrInvite: isCandidate => {
    const message = isCandidate
      ? 'You already meet the Minimum Compatibility Score set by this Employer.'
      : 'This candidate already meets your Minimum Compatibility Score for this Job Description';
    return `${message} You do not need to use a SuperMatch. If you would like to apply, please click the Apply button to submit your application!`;
  },
  alreadyAppliedSuperMatchMessage: isCandidate => {
    const message = isCandidate ? 'job' : 'candidate';
    return `It looks like you have already used a SuperMatch on this ${message}. You can only use one SuperMatch per ${message}.`;
  },
  userNotHavingSuperMatchMessage: (
    <div>
      <p>
        You do not have any SuperMatches in your bank. Would you like to purchase SuperMatches to potentially boost your
        score?
      </p>
      <SuperMatch />
    </div>
  ),
  failedSuperMatchQuestions: isCandidate => {
    return isCandidate
      ? 'Unfortunately, your answer choices did not match. Don’t worry though! We have plenty of other positions that you more closely match!'
      : 'Unfortunately, your answer choices did not match with the Candidate. Don’t worry though! We have plenty of other Candidates that your job description more closely matches! Please note that you did not lose a SuperMatch credit. We only use a SuperMatch credit when your SuperMatch results match.';
  },
  passedSuperMatchQuestions: isCandidate => {
    return isCandidate
      ? 'Congratulations! Your answers closely match what the Employer is looking for. Now you can apply for this position! Please click Apply to send your application.'
      : 'Congratulations! Your answers closely match what the Candidate is looking for. Now you can invite the Candidate to apply. Please click Invite to Apply to start your application process with this candidate. Please note that we will charge you a SuperMatch credit. We only use a SuperMatch credit when your SuperMatch results match.';
  },
  notEligibleForSuperMatch: isCandidate => {
    return isCandidate
      ? 'You are not within 5 points or less of meeting the Minimum Compatibility Score set for this position. Answering additional questions or changing your preferences may help you meet the Minimum Compatibility Score.'
      : 'This Candidate falls more than 5 points away from your Minimum Compatibility Score that you set. If you\'d like to use a SuperMatch, please move your Minimum Compatibility Score for this position to within 5 points or less.';
  },
  userHavingSuperMatchMessage: isEmployer => (
    <div>
      <p>
        Are you sure you want to apply a SuperMatch to this {isEmployer ? 'candidate' : 'job'}? Note that this CANNOT be
        undone.
      </p>
      <SuperMatch />
    </div>
  ),
  buyQuestionConfirmMessage:
    'Would you like to purchase additional JobCommander Compatibility Questions to potentially increase your scores?',
};
