import { SelectOptionModel } from '@job-commander/shared';
import { useLoader } from '@surinderlohat/react-hooks';
import { useState } from 'react';
import { useDataService } from 'src/api';
import { OptionsModel } from 'src/models/Options.model';

export interface IOptionsStore {
  options: OptionsModel;
  isLoadingOptions: boolean;
  isLoading: boolean;
  isLoadingCollages: boolean;
  isSearchingIndustries: boolean;
  colleges: SelectOptionModel[];
  clearColleges: () => void;
  loadEducationOptions: () => void;
  loadJobOptions: () => void;
  searchCollages: (search: string) => Promise<SelectOptionModel[]>;
  searchIndustries: (search: string) => Promise<SelectOptionModel[]>;
}

// User State Model
export function useOptionsStore(): IOptionsStore {
  const [ options, setOptions ] = useState(new OptionsModel());
  const [ colleges, setColleges ] = useState([]);

  const optionsLoader = useLoader();
  const jobOptionsLoader = useLoader();
  const searchingCollagesLoader = useLoader();
  const searchingIndustriesLoader = useLoader();

  const apiService = useDataService();

  // Load Options Related to The Education
  const loadEducationOptions = (): void => {
    if (!options.educationLevels?.length) {
      optionsLoader.showLoader();
      apiService.loadEducationOptions().then(_educationOptions => {
        optionsLoader.hideLoader();
        setOptions(
          options =>
            new OptionsModel({
              ...options,
              educationLevels: _educationOptions.educationLevels,
              degrees: _educationOptions.degrees,
              doctorate: _educationOptions.doctorate,
              master: _educationOptions.master,
              gpAs: _educationOptions.gpAs,
            })
        );
      });
    }
  };

  // Search College by Name
  const loadJobOptions = (): void => {
    if (options.yearsExperience?.length) {
      return;
    }

    jobOptionsLoader.showLoader();
    apiService.loadJobOptions().then(_jobOptions => {
      jobOptionsLoader.hideLoader();
      setOptions(
        options =>
          new OptionsModel({
            ...options,
            yearsExperience: _jobOptions.yearsExperience,
            industries: _jobOptions.industries,
            numberOfEmployees: _jobOptions.numberOfEmployees,
            experienceInMonths: _jobOptions.experienceInMonths,
            experienceLevels: _jobOptions.experienceLevels,
            benefits: _jobOptions.benefits,
            willingToTravels: _jobOptions.willingToTravels,
            jobVenues: _jobOptions.jobVenues,
            payTypes: _jobOptions.payTypes,
            workWeeks: _jobOptions.workWeeks,
            debts: _jobOptions.debts,
            interestRates: _jobOptions.interestRates,
            loanServicers: _jobOptions.loanServicers,
            creditScores: _jobOptions.creditScores,
          })
      );
    })
  };

  // Search College by Name
  const searchCollages = async (search: string): Promise<SelectOptionModel[]> => {
    if (!search) {
      setColleges([]);
      return;
    }
    searchingCollagesLoader.showLoader();
    const _collages = await apiService.searchCollages(search);
    searchingCollagesLoader.hideLoader();
    setColleges(_collages);
    return _collages;
  };

  // Search College by Name
  const searchIndustries = async (search: string): Promise<SelectOptionModel[]> => {
    if (!search) {
      setColleges([]);
      return;
    }
    searchingIndustriesLoader.showLoader();
    const _industries = await apiService.searchIndustries(search);
    searchingIndustriesLoader.hideLoader();
    setColleges(_industries);
    return _industries;
  };

  return {
    colleges,
    clearColleges: () => setColleges([]),
    isLoading: optionsLoader.isLoading || jobOptionsLoader.isLoading,
    isLoadingOptions: optionsLoader.isLoading,
    isLoadingCollages: searchingCollagesLoader.isLoading,
    isSearchingIndustries: searchingIndustriesLoader.isLoading,
    options,
    loadEducationOptions,
    loadJobOptions,
    searchCollages,
    searchIndustries,
  };
}
