import { Button, Typography } from 'antd';
import React, { FC } from 'react';
import Countdown from 'react-countdown';

type Props = {
    releaseDate:string
    
  };

export const CountDown: FC<Props> = ({ releaseDate }) => {
    
  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '10px' }}>
          <Button type="primary" onClick={() => window.location.reload()}>
            Refresh Page
          </Button>
        </div>
      );
    }
    return (
      <div>
        <Typography.Title level={4} style={{ textAlign: 'center' }}>
          We are excited that you have joined our JobCommander platform. You will soon be able to see your most
          compatible matches!
        </Typography.Title>
        <div
          style={{
            fontWeight: '500',
            fontSize: '20px',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          {days} : {hours} : {minutes} : {seconds}
        </div>
        <div
          style={{
            fontWeight: '500',
            fontSize: '14px',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          {' '}
          DD : HH : MM : SS
        </div>
      </div>
    );
  };
  return  <Countdown date={releaseDate} renderer={renderer} />
}
export default CountDown;