import { ScaffoldModel } from './Scaffold.model';
import { EDITOR_TYPES, SelectOptionModel, Utilities } from '@job-commander/shared';
import { APIHighSchool } from 'src/interfaces/API-Candidate.interface';
import moment from 'moment';

export class HighSchoolModel extends ScaffoldModel {
  awards: string = '';
  graduationYear: moment.Moment = moment();
  gpa: SelectOptionModel = null;

  constructor(data?: Partial<HighSchoolModel>) {
    super(data);
    Object.assign(this, data);
    this.editorType = EDITOR_TYPES.HIGH_SCHOOL;
    this.title = Utilities.trimCharacters(this.name, 29) || '';
    this.lines = [ `Graduation Year: ${this.graduationYear?.year()}` ];
  }

  /**
   * MAP DATA FROM API to UI MODEL
   */
  public static deserialize(data: APIHighSchool): HighSchoolModel {
    return new HighSchoolModel({
      ...data,
      graduationYear: moment(data?.graduationYear, 'YYYY'),
      gpa: SelectOptionModel.deserialize(data?.gpa),
    });
  }

  public static deserializeList(data: APIHighSchool[]): HighSchoolModel[] {
    if (!data || !Array.isArray(data)) {
      return [];
    }
    return data.map(hs => HighSchoolModel.deserialize(hs));
  }

  public serialize(): APIHighSchool {
    return {
      id: this.id,
      gpaId: this.gpa?.value,
      name: this.name,
      awards: this.awards,
      graduationYear: moment(this.graduationYear).year().toString(),
    };
  }
}
