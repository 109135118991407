import { CoreModel, SelectOptionModel } from '@job-commander/shared';
import { IDetail } from 'src/interfaces';

export class DetailModel extends CoreModel {
  coreValues: string;
  numberOfEmployees: SelectOptionModel = null;
  founders: string;
  industry: (string | number)[];
  missionStatement: string;
  website: string;

  constructor(data?: Partial<DetailModel>) {
    super(data);
    Object.assign(this, data);
    this.numberOfEmployees = data?.numberOfEmployees
    this.industry = data?.industry;
  }

  public static deserialize(response: IDetail) {
    return new DetailModel({
      ...response,
      numberOfEmployees: SelectOptionModel.deserialize(response?.numberOfEmployees),
      industry: response?.industry ? [ response?.industry?.id?.toString(), response?.industryJob.id ] : [],
    });
  }

  public serialize?(): IDetail {
    return {
      id: this.id,
      coreValues: this.coreValues,
      numberOfEmployeeId: this.numberOfEmployees?.value,
      founders: this.founders,
      industryId: parseInt(this.industry[0] as string), // Need This conversion
      industryJobId: this.industry[1],
      missionStatement: this.missionStatement,
      website: this.website,
    };
  }
}
