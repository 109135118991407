import { useAlert } from '@job-commander/shared';
import { BuySubscription } from '../../../../../components';
import React, { FC, useEffect, useState } from 'react';
import { useAppStores } from 'src/store';
import { ProductModel } from 'src/models';

type Props = {
  onCanMoveNext: Function;
  allowFreeJobPost?: boolean;
  isPaymentDone?: boolean;
};

const MakePayment: FC<Props> = ({ onCanMoveNext, allowFreeJobPost, isPaymentDone }) => {
  const [ planLength, setPlanLength ] = useState(30);
  const alert = useAlert();
  const { userStore } = useAppStores();
  useEffect(() => {
    if (userStore.selectedPlan?.id) {
      onCanMoveNext(true);
    }
  }, []);

  // On Selection of the Plan
  const onPlanSelectUnSelect = (plan: ProductModel) => {
    if (isPaymentDone) {
      alert.success('Can\'t change package after payment');
      return;
    }
    // on plan Unselect
    if (userStore.selectedPlan?.id === plan.id) {
      onCanMoveNext(false);
      return;
    }
    userStore.setSelectedPlan(plan);
    onCanMoveNext(true);
  };

  // When user change the month type from 30/60
  const onMonthTermsChange = value => {
    setPlanLength(value);
  };

  return (
    <BuySubscription
      isPaymentDone={isPaymentDone}
      jobs={userStore.employerJobs}
      planLength={planLength}
      selectedPlan={userStore.selectedPlan}
      onChangePlanType={onMonthTermsChange}
      onChangePlan={onPlanSelectUnSelect}
      allowFreeJobPost={allowFreeJobPost}
    />
  );
};

export default MakePayment;
