import React, { FC, useMemo, useState } from 'react';
import { Select } from 'antd';
import { ISelectOption } from '../../../interfaces';

type Props = {
  dropdownName?: string;
  multiple?: boolean;
  maxSelections?: number;
  initialValue?: any;
  onSelect?: any; // Change it with function
  placeholder?: string;
  disabled?: boolean;
  [key: string]: any;
  options?: ISelectOption[];
  value?: any;
  isLoading?: boolean;
};

// AsyncSelect()
const AsyncSelect: FC<Props> = ({
  dropdownName,
  multiple,
  maxSelections,
  initialValue,
  onSelect,
  options,
  isLoading,
  ...rest
}) => {
  const [ selected, setSelected ] = useState(initialValue || []);

  // check initial values refresh select options if initial value changed
  useMemo(() => {
    setSelected(initialValue || []);
  }, [ initialValue ]);

  const handleOnSelect = value => {
    if (!multiple) {
      return;
    }

    if (maxSelections < 0 || selected?.length < maxSelections) {
      setSelected([ ...selected, value ]);
    }

    if (typeof onSelect === 'function') {
      onSelect(value);
    }
  };

  const handleOnDeselect = option => {
    if (multiple) {
      setSelected(_selected => _selected.filter(i => i.value !== option.value));
      return;
    }
    setSelected(option);
  };

  // getOptions()
  const getOptions = () => {
    if (multiple) {
      // If user can select more options then no need to disable in case of multi select
      return options.map((currItem: any) => {
        const disabled =
          maxSelections && selected.length < maxSelections
            ? false
            : !selected.map(x => x.value).includes(currItem.value);

        // Create new Model of same item
        const isCallable = typeof currItem.constructor === 'function';
        return isCallable ? new currItem.constructor({ ...currItem, disabled }) : { ...currItem, disabled };
      });
    }

    return Array.isArray(options) ? options : [];
  };

  return (
    <Select
      {...rest}
      loading={isLoading}
      mode={multiple ? 'multiple' : null}
      options={getOptions()}
      onDeselect={handleOnDeselect}
      onSelect={handleOnSelect}
      onClear={() => setSelected(multiple ? [] : null)}
    />
  );
};

AsyncSelect.defaultProps = {
  multiple: false,
  maxSelections: 0,
};

export default AsyncSelect;
