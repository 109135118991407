import { CandidateModel } from './Candidate.model';
import { CoreModel, IProfileTokenClaims, IUserClaims } from '@job-commander/shared';
import { EmployerModel } from './Employer.model';
import { TokenClaims } from '@azure/msal-common';

export class UserModel extends CoreModel implements TokenClaims {
  public isCandidate: boolean = false;
  public isEmployer: boolean = false;
  public isSignupCompleted: boolean = false;
  public isPhoneNumberVerified: boolean = false;
  public isTutorialCompleted: boolean = false;

  candidateProfile: CandidateModel;
  employerProfile: EmployerModel;

  constructor(data?: Partial<UserModel>) {
    super(data);
    Object.assign(this, data);
    this.candidateProfile = data?.candidateProfile || new CandidateModel();
    this.employerProfile = data?.employerProfile || new EmployerModel();
  }

  /**
   * Return Profile Based on the user Type
   */
  public get profile(): CandidateModel | EmployerModel {
    return this.isCandidate ? this.candidateProfile : this.employerProfile;
  }

  // Save User Data In Session
  public serialize(): IUserClaims {
    return {
      isSignUpCompleted: this.isSignupCompleted,
      isPhoneNumberVerified: this.isPhoneNumberVerified,
      isTutorialCompleted:this.isTutorialCompleted,
      userTypeId: this.isCandidate ? 1 : 2,
    };
  }

  /**
   * deserialize
   */
  public static deserializeUser(data?: Partial<IUserClaims>) {
    return new UserModel({
      isTutorialCompleted:data?.isTutorialCompleted,
      isPhoneNumberVerified: data?.isPhoneNumberVerified,
      isSignupCompleted: data?.isSignUpCompleted,
      isCandidate: data?.userTypeId === 1, // 1 Candidate
      isEmployer: data?.userTypeId === 2, // 2 Employer
    });
  }

  /**
   * deserialize Deprecated Will be removed soon
   */
  public static deserialize(data?: Partial<IProfileTokenClaims>) {
    return new UserModel({
      isSignupCompleted: data.extension_IsSignUpComplete || false,
      isCandidate: data.extension_UserTypeId === 1, // 1 Candidate
      isEmployer: data.extension_UserTypeId === 2, // 2 Employer
    });
  }
}
