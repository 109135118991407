import { maxChar, OverlayLoader, useQueryParams, ViewPermission } from '@job-commander/shared';
import { Button, Form, Input, InputNumber, Select, Typography } from 'antd';
import React, { useRef, useState, useEffect, FC } from 'react';
import '../AddNewJobs/AddNewJobs.less';
import { useLoader } from '@surinderlohat/react-hooks';
import { useDataService } from 'src/api';
import { useHistory } from 'react-router-dom';

enum websiteInfo {
  SAME_WEBSITE = 1,
  OTHER_WEBSITE = 2,
}

const DoNotExtendJobs: FC = () => {
  const [ otherQues, setOtherQues ] = useState(false);
  const [ whereCandidateHired, setWhereCandidateHired ] = useState(null);
  const [ candidateOptions, setCandidateOptions ] = useState([]);
  const jobFormRef = useRef(null);
  const [ from ] = Form.useForm();
  const { Title } = Typography;
  const [ jobDeletedReason, setJobDeletionReason ] = useState([]);
  const [ reasonOptions, setReasonOptions ] = useState([]);
  const loader = useLoader();
  const queryParams = useQueryParams();
  const dataService = useDataService();
  const [ selectedCandidate, setSelectedCandidate ] = useState(null);
  const history = useHistory();

  useEffect(() => {
    getCandidateNames();
    loadRemoveJobQuestions();
  }, []);

  const otherOption = { value: -1, label: 'Other' };
  const getCandidateNames = async () => {
    loader.showLoader()
    const result = await dataService.getCandidateNames(queryParams.employerJobId);
    loader.hideLoader()
    if (result.hasError) {
      return;
    }
    setCandidateOptions([ ...result.data, otherOption ]);
  };

  // load current logged in user profile
  const loadRemoveJobQuestions = async () => {
    loader.showLoader();
    const response = await dataService.removeJobQuestions();
    if (response.hasError) {
      return;
    }
    const jobDeletionReason = response.map(reason => ({
      value: reason.id,
      label: reason.name,
    }));
    setJobDeletionReason(jobDeletionReason);
    const reasonOptions = response[0].options.map(reason => ({
      value: reason.id,
      label: reason.name,
    }));
    setReasonOptions(reasonOptions);
    loader.hideLoader();
  };

  const onSubmit = async () => {
    await jobFormRef?.current?.validateFields();
    const values = jobFormRef?.current?.getFieldsValue();
    const request ={
      employerJobId: queryParams?.employerJobId,
      jobDeletionReasonId: values?.jobDeletedReason,
      jobDeletionReasonOptionId: values?.foundCandidateReason,
      candidateId: values?.candidateId === -1 ? null : values?.candidateId,
      salaryOffered: values?.salaryOffered,
      website: values?.website,
      candidateNameOther: values?.candidateId === -1 ? values?.candidateNameOther : null,
    }
    const response = await dataService.deleteJobFromEmail(request);
    if (response.hasError) {
      return;
    }
    history.push('/a/matches');
  };

  const onValueChanges = values => {
    if (values.jobDeletedReason === 2) {
      from.setFieldsValue({ foundCandidateReason: [] });
      setWhereCandidateHired(null);
      return;
    }
    if (values.foundCandidateReason === 1) {
      from.setFieldsValue({ website: null });
      return;
    }
    if (values.foundCandidateReason === 2) {
      from.setFieldsValue({ salaryOffered: null, candidateId: null });
      setWhereCandidateHired(null);
    }
  };
  return (
    <OverlayLoader isLoading={loader.isLoading}>
      <div id="do-not-extend-job">
        <Form ref={jobFormRef} form={from} onValuesChange={onValueChanges}>
          <Title level={4}>Do not Extend Job Questions</Title>
          <Form.Item
            name="jobDeletedReason"
            label="Why are you not extending this job?"
            rules={[{ required: true }]}
            hasFeedback
          >
            <Select
              options={jobDeletedReason}
              placeholder="Select..."
              onChange={value => {
                value === 1 ? setOtherQues(true) : setOtherQues(false);
              }}
            />
          </Form.Item>

          <ViewPermission isVisible={otherQues}>
            <Form.Item
              name="foundCandidateReason"
              label="Where did you find the candidate?"
              rules={[{ required: true }]}
              hasFeedback
            >
              <Select
                onChange={value => setWhereCandidateHired(value)}
                options={reasonOptions}
                placeholder="Select..."
              />
            </Form.Item>
            <ViewPermission
              isVisible={whereCandidateHired === websiteInfo.SAME_WEBSITE && candidateOptions.length >= 1}
            >
              <Form.Item label="Who have you hired?" name="candidateId" rules={[{ required: true }]}>
                <Select options={candidateOptions} placeholder="Select Candidate From List"  onChange={value => {
                  setSelectedCandidate(value);
                }} />
              </Form.Item>
              <Form.Item name="salaryOffered" label="What was the salary you offered?" rules={[{ required: true }]}>
                <InputNumber
                  min={0}
                  style={{ width: '100%' }}
                  placeholder="Salary Offered"
                  step={500}
                  formatter={value => `$ ${value}`.replace(/\/\\B\(\?=\(\\d\{3\}\)\+\(\?!\\d\)\)\/g/, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '') as any}
                />
              </Form.Item>
            </ViewPermission>
            <ViewPermission isVisible={whereCandidateHired === websiteInfo.SAME_WEBSITE && selectedCandidate === -1}>
              <Form.Item
                name="candidateNameOther"
                label="What was the name of the candidate?"
                required
                hasFeedback
                rules={[{ required: true }, maxChar(100) ]}
              >
                <Input type="text" placeholder="Full Name" />
              </Form.Item>
            </ViewPermission>
            <ViewPermission
              isVisible={whereCandidateHired === websiteInfo.SAME_WEBSITE && !candidateOptions.length && otherQues}
            >
              <Title level={5}>It looks like you have not hired any candidate on Job Commander.</Title>
            </ViewPermission>
            <ViewPermission isVisible={whereCandidateHired === websiteInfo.OTHER_WEBSITE && otherQues}>
              <Form.Item
                name="website"
                label="What website or other source did you use to find your hire"
                rules={[{ required: true }]}
                hasFeedback
              >
                <Input type="text" placeholder="https://www.company.com" />
              </Form.Item>
            </ViewPermission>
          </ViewPermission>

          <div className="submit-button">
            <Button
              type="primary"
              htmlType="submit"
              onClick={onSubmit}
              disabled={
                (whereCandidateHired === websiteInfo.SAME_WEBSITE && !candidateOptions.length && otherQues) ||
                loader.isLoading
              }
            >
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </OverlayLoader>
  );
};

export default DoNotExtendJobs;
