import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { baseAPI } from 'src/config';

class HubConnectionStore {
  readonly timeout: number = 60000;
  public connection: HubConnection;

  public enable(accessToken: string): Promise<void> {
    this.connection = new HubConnectionBuilder()
      .withUrl(`${baseAPI.jobCommander}/events`, { accessTokenFactory: () => accessToken,  withCredentials: false })
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    this.connection.serverTimeoutInMilliseconds = this.timeout;
    this.connection.onclose(error => console.log('HubConnection has been closed', error));
    return this.connection.start();
  }

  public disable(): void {
    if (this.connection) {
      this.connection
        .stop()
        .then(() => (this.connection = null))
        .catch(err => {
          console.error('An error occurred while disabling hub connection.', err);
        });
    }
  }

  // Join Chat with chat Id from
  public joinChatGroup(accessToken: string, chatId: string, callback: (...args: any[]) => any): void {
    // Make Connection
    this.enable(accessToken)
      .then(() => {
        this.connection.on('ReceiveMessage', callback);
        this.connection
          .invoke('JoinGroup', chatId)
          .then(() => console.log('Successfully joined group', chatId))
          .catch(err => console.error(err));
      })
      .catch(err => console.error(err));
  }

  public unsubscribeToEvents(chatId: string): void {
    if (this.connection) {
      this.connection.off(chatId);
    }
  }
}

export default new HubConnectionStore();
