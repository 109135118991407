import React, { FC, useState } from 'react';
import { Modal } from 'antd';
import ReactPlayer from 'react-player';

type Props = {
  title: string;
  url: string;
  onOk: Function;
  onCancel: Function;
  isLocalEnv?: boolean;
};

const ReactPlayerDialog: FC<Props> = ({ title, url, onOk, onCancel, isLocalEnv }) => {
  const [ disableOkyButton, setDisableOkyButton ] = useState(!isLocalEnv);
  return (
    <Modal
      title={title}
      visible={true}
      width={690}
      maskClosable={false}
      onCancel={() => onCancel()}
      onOk={() => onOk()}
      okText={isLocalEnv ? 'Skip' : 'Begin'}
      okButtonProps={{ disabled: disableOkyButton }}
    >
      <ReactPlayer url={url} onEnded={() => setDisableOkyButton(false)} controls={true} />
    </Modal>
  );
};

export default ReactPlayerDialog;
