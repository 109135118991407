import { alphabetsWithSpaces, maxChar, ViewPermission } from '@job-commander/shared';
import { Form, FormInstance, Input, Select } from 'antd';
import React, { FC, RefObject, useState } from 'react';
import { CandidateSLAMModel, OptionsModel } from 'src/models';
import './CandidateTellUsMore.less';

type Props = {
  options: OptionsModel;
  value?: CandidateSLAMModel;
  onSlamChange?: Function;
  isLoading?: boolean;
  onCancel?: Function;
  onOk?: Function;
  formRef?: RefObject<FormInstance>;
};

const { TextArea } = Input;

const CandidateSlamDetails: FC<Props> = ({ options, value, onSlamChange, formRef }) => {
  const [ form ] = Form.useForm();
  const [ loanServicer, setLoanServicer ] = useState(value?.loanServicer?.value);
  return (
    <Form
      id="tell-us-more-form"
      name="tellUsMoreForm"
      size="middle"
      layout="vertical"
      className="editor-form"
      form={form}
      ref={formRef}
      initialValues={value}
      onValuesChange={(slam: CandidateSLAMModel) => {
        if (form.getFieldValue('loanServicer')?.value !== 1) {
          form.setFieldsValue({ loanServicerOther: '' });
        }
        onSlamChange(slam);
      }}
    >
      <Form.Item>
        <Form.Item
          name="debt"
          label="Approx. how much is your student debt?"
          hasFeedback
          required
          rules={[{ required: true }]}
          style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: 0 }}
        >
          <Select options={options?.debts} placeholder="Select..." labelInValue />
        </Form.Item>

        <Form.Item
          name="interestRate"
          label="Approx. how much is your interest rate?"
          hasFeedback
          required
          rules={[{ required: true }]}
          style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 0 0 16px' }}
        >
          <Select options={options?.interestRates} placeholder="Select..." labelInValue />
        </Form.Item>
      </Form.Item>
      <Form.Item>
        <Form.Item
          name="loanServicer"
          label="Who is your Loan Servicer?"
          hasFeedback
          rules={[{ required: true }]}
          style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: 0 }}
        >
          <Select
            options={options?.loanServicers}
            placeholder="Select..."
            onSelect={loan => setLoanServicer(loan.value)}
            labelInValue
          />
        </Form.Item>

        <Form.Item
          name="creditScore"
          label="What is your approximate Credit Score?"
          hasFeedback
          rules={[{ required: true }]}
          style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 0 0 16px' }}
        >
          <Select options={options?.creditScores} placeholder="Select..." labelInValue />
        </Form.Item>

        {/* If loanServicer is other */}
        <ViewPermission isVisible={form.getFieldValue('loanServicer')?.value === 1 || loanServicer === 1}>
          <Form.Item
            name="loanServicerOther"
            label="Enter name of Loan Servicer"
            hasFeedback
            rules={[{ required: true }, maxChar(500), alphabetsWithSpaces() ]}
            style={{ margin: 0, marginTop: '16px' }}
          >
            <TextArea placeholder="loan Servicer" rows={1} />
          </Form.Item>
        </ViewPermission>
      </Form.Item>
    </Form>
  );
};

CandidateSlamDetails.defaultProps = {
  onSlamChange: () => null,
  onCancel: () => null,
  onOk: () => null,
};

export { CandidateSlamDetails };
