import moment from 'moment';
import { IChatByIdResponse, IChats } from 'src/interfaces';

export const getSessionTitle = (session: IChats, isEmployer: boolean) =>
  isEmployer
    ? session.candidate?.firstName.concat(' ', `${session.candidate?.lastName?.charAt(0)}.`)
    : `${session.job?.title} (${session.job?.employer?.companyName})`;

export const getSessionHeader = (session: IChats, isEmployer: boolean) =>
  isEmployer
    ? session.candidate?.firstName.concat(' ', `${session.candidate?.lastName?.charAt(0)}.`)
    : ` ${session.job?.employer?.companyName} for ${session.job?.title}`;

export const formatTime = time => moment.utc(time).fromNow();

export const getLastMessageTime = session => {
  const { blurbs } = session;
  const lastItem = blurbs[blurbs.length - 1];

  return formatTime(lastItem.dateTimeSent);
};

export const getDate = session => {
  const { blurbs } = session;
  const lastItem = blurbs[blurbs.length - 1];
  return moment.utc(lastItem.dateTimeSent).format();
};

export const getDotColor = (chat: IChatByIdResponse, isEmployer: boolean) => {
  if (
    (isEmployer && chat.userToShowMessage?.label === 'Employer') ||
    (!isEmployer && chat.userToShowMessage?.label === 'Candidate')
  ) {
    return 'rgba(41, 74, 147, 100)';
  }

  return 'green';
};

export const getPosition = (chat: IChatByIdResponse, isEmployer: boolean) => {
  if (
    (isEmployer && chat.userToShowMessage?.label === 'Employer') ||
    (!isEmployer && chat.userToShowMessage?.label === 'Candidate')
  ) {
    return 'left';
  }

  return 'right';
};

// allowForceEdit : if employer user wants to edit actions
export const getBlurbClass = (chat: IChatByIdResponse, isEmployer: boolean, allowForceEdit = false) => {
  if (
    (isEmployer && chat.userToShowMessage?.label === 'Employer') ||
    (!isEmployer && chat.userToShowMessage?.label === 'Candidate') ||
    allowForceEdit
  ) {
    return 'mine';
  }

  return 'theirs';
};

export const getBlurbBody = (chat: IChatByIdResponse) => {
  const body = chat.displayMessage;
  return body;
};
