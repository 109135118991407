import { IAPIBase } from '../interfaces';
import { CoreModel } from './Core.model';

export class SelectOptionModel extends CoreModel {
  value: number;
  label: string;
  isActive?: boolean;

  constructor(p?: any) {
    super(p);
    Object.assign(this, p);
    this.label = p?.name || p?.label;
    this.value = p?.id || p?.value;
  }

  /**
   * MAP DATA FROM API to UI MODEL
   */
  public static deserialize(data: IAPIBase): SelectOptionModel {
    return new SelectOptionModel({
      ...data,
      label: data?.name,
      value: data?.id,
    });
  }

  public static deserializeList(data: IAPIBase[]): SelectOptionModel[] {
    if (!data || !Array.isArray(data)) {
      console.warn('Parsing item is not an array SelectOptionModel', data);
      return [];
    }
    return data.map(x => this.deserialize(x));
  }
}
