import { faFacebookF, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';
import { useUserContext } from '../../context';
import process from 'process';
import React, { FC } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useGoogleLogin } from 'react-google-login';
import { useHistory } from 'react-router-dom';
import './SocialLogin.less';
import { useAlert } from '@job-commander/shared';
import { generalMessage } from '../Messages';
import { useLoader } from '@surinderlohat/react-hooks';
type Props = {
  isLoginScreen?: boolean;
  onSocialSignup?: Function; // if user needs to signup
};

const SocialLogin: FC<Props> = props => {
  const history = useHistory();
  const { user, httpClient } = useUserContext();

  const alert = useAlert();
  const loader = useLoader();

  const { signIn } = useGoogleLogin({
    cookiePolicy: 'single_host_origin',
    clientId: process.env.REACT_APP_GOOGLE_ROOT,
    onSuccess: result => {onLoginResponse(result, true)},
    onFailure: e => console.log('ERROR', e),
  });

  /**
   * @param {*} response response from google or facebook
   * @param {*} isGoogle either it's google or facebook
   * @returns empty
   */
  const onLoginResponse = async (response, isGoogle) => {
    if (response.status === 'unknown') {
      return;
    }
    loader.showLoader();
    const apiResponse = await httpClient.googleLogin({
      userAuthValue: response?.accessToken,
      userAuthSourceType: isGoogle ? 'googleAt' : 'facebookAt',
    });
    const { data } = apiResponse;
    loader.hideLoader();
    if (
      apiResponse.statusCode === 'AccountNotRegistered' ||
      apiResponse.statusCode === 'AccountNotFound' ||
      apiResponse.statusCode === 'NotAuthorized'
    ) {
      if (props.isLoginScreen) {
        alert.error(generalMessage.accountNotRegistered);
        return;
      }
      props.onSocialSignup(response, isGoogle);
      return;
    }
    alert.success('Login Success!', data.jsonOk?.trMsg);

    setTimeout(() => {
      // on login success
      user.copyFrom({ ...data?.candState, results: data?.jsonOk, type: 'candidate' });
      if (user.signUpDone === false) {
        history.push('/complete-signup');
      } else {
        history.push('/a/matches');
      }
    }, 1000);
  };

  return (
    <div id="social-wrapper">
      <FacebookLogin
        appId={process.env.REACT_APP_FACEBOOK_APP}
        autoLoad={false}
        fields="name,email,picture"
        callback={result => onLoginResponse(result, false)}
        render={renderProps => (
          <Button
            id="facebook"
            size="middle"
            icon={<FontAwesomeIcon icon={faFacebookF} />}
            onClick={renderProps.onClick}
          >
            Facebook
          </Button>
        )}
      />
      <Button id="google" size="middle" onClick={signIn} icon={<FontAwesomeIcon icon={faGoogle} />}>
        Google
      </Button>
    </div>
  );
};

SocialLogin.defaultProps = {
  isLoginScreen: false,
  onSocialSignup: resp => null,
};

export { SocialLogin };
