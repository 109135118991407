import { CandidateSLAMModel } from './CandidateSLAM.model';
import { SelectOptionModel, CoreModel } from '@job-commander/shared';
import { IJobPreferences } from 'src/interfaces/API-Candidate.interface';

export class JobPreferencesModel extends CoreModel {
  willingToTravel: SelectOptionModel = null;
  jobVenue: SelectOptionModel = null;
  workWeek: SelectOptionModel = null;
  industry: (string | number)[] = [];
  // industryJob: SelectOptionModel;
  isSlamSelected: boolean = false;
  isWillingToRelocate: boolean = false;
  slam: CandidateSLAMModel;
  benefits: number[] = [];

  constructor(data?: Partial<JobPreferencesModel>) {
    super(data);
    Object.assign(this, data);
    this.willingToTravel = data?.willingToTravel; //|| new SelectOptionModel();
    this.jobVenue = data?.jobVenue; //|| new SelectOptionModel();
    this.workWeek = data?.workWeek; //|| new SelectOptionModel();
    this.industry = data?.industry || [];
    this.slam = data?.slam || new CandidateSLAMModel();
    this.benefits = data?.benefits || [];
  }

  public static deserialize(response: IJobPreferences): JobPreferencesModel {
    return new JobPreferencesModel({
      ...response,
      willingToTravel: SelectOptionModel.deserialize(response?.willingToTravel),
      jobVenue: SelectOptionModel.deserialize(response?.jobVenue),
      workWeek: SelectOptionModel.deserialize(response?.workWeek),
      industry: response?.industry ? [ response?.industry?.id?.toString(), response?.industryJob.id ] : [],
      slam: CandidateSLAMModel.deserialize(response?.slam || response?.candidateSLAM),
      benefits: response?.benefits?.map(x => x.id) || [],
    });
  }

  public serialize(): IJobPreferences {
    return {
      id: this.id,
      benefits: this.benefits.map(benefitId => benefitId),
      isWillingToRelocate: this.isWillingToRelocate,
      willingToTravelId: this.willingToTravel?.value,
      jobVenueId: this.jobVenue?.value,
      workWeekId: this.workWeek?.value,
      industryId: parseInt(this.industry[0] as string), // Need This conversion
      industryJobId: this.industry[1],
      isSlamSelected: this.isSlamSelected,
      slam: this.isSlamSelected ? this.slam.serialize() : null,
    };
  }
}
